import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  BulkUpdateManufacturingUnitsListPayload,
  CustomOrderedMaterial,
  GroupManufacturingItemPayload,
  GroupedUnitInProgress,
  ManufacturingGroup,
  ManufacturingItemCount,
  ManufacturingUnit,
  ManufacturingUnitGroup,
  ManufacturingUnitGroupDetails,
  ManufacturingUnitListViewDetails,
  ManufacturingUnitListViewItem,
  SetManufacturingUnitsAsFinished,
} from "./models";
import { manufacturingUnitsKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const getManufacturingUnitsGroups = (
  search: string = "",
): ApiFetcher<ManufacturingUnitGroup[]> => ({
  key: manufacturingUnitsKeys.manufacturingUnitGroup.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/groups" + search,
    }),
});

const getManufacturingUnitsCustomOrderedMaterials = (
  search: string = "",
): ApiFetcher<CustomOrderedMaterial[]> => ({
  key: manufacturingUnitsKeys.customOrderedMaterials.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/custom-ordered-materials" + search,
    }),
});

const getPrioritizedListUnits = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingUnitListViewItem>> => ({
  key: manufacturingUnitsKeys.listView.prioritizedUnits(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/board-list" + search,
    }),
});

const getNormalListUnits = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingUnitListViewItem>> => ({
  key: manufacturingUnitsKeys.listView.normalUnits(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/board-list" + search,
    }),
});

const getListUnit = (unitId: UUID): ApiFetcher<ManufacturingUnitListViewDetails> => ({
  key: manufacturingUnitsKeys.listView.listUnitDetails(unitId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/board-list/" + unitId,
    }),
});

const getManufacturingUnitsGroupsDetails = (
  id: UUID,
): ApiFetcher<ManufacturingUnitGroupDetails> => ({
  key: manufacturingUnitsKeys.manufacturingUnitGroup.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/groups/" + id,
    }),
});

const getInProgressGroupedUnits = (
  search: string,
): ApiFetcher<Pagination<GroupedUnitInProgress>> => ({
  key: manufacturingUnitsKeys.inProgressGroupedUnits.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/grouped-items" + search,
    }),
});

const getTodoManufacturingUnits = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingUnit>> => ({
  key: manufacturingUnitsKeys.todoManufacturingUnit.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/items" + search,
    }),
});

const getTodoManufacturingUnit = (unitId: UUID): ApiFetcher<ManufacturingUnit> => ({
  key: manufacturingUnitsKeys.todoManufacturingUnit.details(unitId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/items/" + unitId,
    }),
});

const getManufacturingItemsCount = ({
  stageId,
  search,
}: {
  stageId: UUID;
  search: string;
}): ApiFetcher<ManufacturingItemCount> => ({
  key: manufacturingUnitsKeys.manufacturingItem.count(stageId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/units/stats/${stageId}` + search,
    }),
});

const patchTodoManufacturingUnit = (data: PartialOf<ManufacturingUnit>, id: UUID) =>
  queryFetch<ManufacturingUnit>({
    method: "PATCH",
    url: "/manufacturing/units/items/" + id,
    data: parsePatchData(data),
  });

const patchListUnit = (data: PartialOf<ManufacturingUnitListViewDetails>, id: UUID) =>
  queryFetch<ManufacturingUnitListViewDetails>({
    method: "PATCH",
    url: "/manufacturing/units/board-list/" + id,
    data: parsePatchData(data),
  });

const getReadyManufacturingUnits = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingUnit>> => ({
  key: manufacturingUnitsKeys.readyManufacturingUnit.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/units/items" + search,
    }),
});

const getManufacturingGroups = ({
  id,
  search,
}: {
  id: UUID;
  search: string;
}): ApiFetcher<ManufacturingGroup[]> => ({
  key: manufacturingUnitsKeys.manufacturingGroup.list(`${search}&id=$${id}`),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/units/stage-board/${id}` + search,
    }),
});

const patchBulkManufacturingUnits = (data: Assign<PartialOf<ManufacturingUnit>, { ids: UUID[] }>) =>
  queryFetch<void>({
    method: "PATCH",
    url: "manufacturing/units/items/bulk-update",
    data,
  });

const postGroupManufacturingUnits = (data: GroupManufacturingItemPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/units/groups",
    data,
  });

const postManufacturingUnitCancel = (data: { manufacturingItemsIds: UUID[] }) =>
  queryFetch<void>({
    method: "POST",
    url: `/manufacturing/manufacturing-items/cancel-entries`,
    data,
  });

const postSetManufacturingUnitsAsFinished = (data: Partial<SetManufacturingUnitsAsFinished>) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/units/set-as-finished",
    data,
  });

const postBulkManufacturingUnitsList = (
  data: Assign<
    PartialOf<BulkUpdateManufacturingUnitsListPayload>,
    { manufacturingWorkingUnitsIds: UUID[] }
  >,
) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/units/bulk-update",
    data,
  });

export const manufacturingUnitsApi = {
  getInProgressGroupedUnits,
  getListUnit,
  getManufacturingUnitsCustomOrderedMaterials,
  getManufacturingGroups,
  getManufacturingItemsCount,
  getManufacturingUnitsGroups,
  getManufacturingUnitsGroupsDetails,
  getNormalListUnits,
  getPrioritizedListUnits,
  getReadyManufacturingUnits,
  getTodoManufacturingUnits,
  getTodoManufacturingUnit,
  patchBulkManufacturingUnits,
  patchListUnit,
  postBulkManufacturingUnitsList,
  patchTodoManufacturingUnit,
  postGroupManufacturingUnits,
  postManufacturingUnitCancel,
  postSetManufacturingUnitsAsFinished,
};
