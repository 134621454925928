import { TradingDocument } from "api/trading-documents/models";
import { UUID } from "api/types";
import { User } from "api/users/models";
import { tradingDocumentConstants } from "constants/tradingDocuments";

const getTradingDocumentLink = (
  id: UUID,
  type: TradingDocument["type"],
  invoiceType: TradingDocument["invoiceType"],
): string => {
  if (type === "RECEIPT") return `/finances/receipts/list/all?panelId=${id}`;
  return `/finances/${tradingDocumentConstants.tradingDocumentTypeToTabDict[invoiceType]}/list/all?panelId=${id}`;
};

const getTradingDocumentType = (
  type: TradingDocument["type"],
  invoiceType: TradingDocument["invoiceType"],
): string => {
  if (type === "RECEIPT") return "paragon";
  return tradingDocumentConstants.invoiceTypeDict[invoiceType];
};

const getDetailsBasedOnInvoiceType = (
  invoiceType: TradingDocument["invoiceType"],
): { fileNamePrefix: string } => {
  switch (invoiceType) {
    case "ADVANCE":
      return { fileNamePrefix: "Faktura-zaliczkowa" };
    case "CORRECTION":
      return { fileNamePrefix: "Korekta" };
    case "FINAL":
      return { fileNamePrefix: "Faktura-końcowa" };
    case "PROFORMA":
      return { fileNamePrefix: "Proforma" };
    case "PURCHASE":
      return { fileNamePrefix: "Faktura-zakupowa" };
    case "RECEIPT":
      return { fileNamePrefix: "Paragon" };
    case "RECEIPT_BASED_ON_INVOICE":
      return { fileNamePrefix: "Paragon" };
    case "SALES":
      return { fileNamePrefix: "Faktura-sprzedażowa" };
    default:
      const exhaustiveCheck = invoiceType;
      console.error(`Unhandled invoice type: ${exhaustiveCheck}`);
      return { fileNamePrefix: "Dokument-handlowy" };
  }
};

const isTradingDocumentConfirmationDisabled = ({
  tradingDocument,
  userType,
}: {
  tradingDocument: TradingDocument;
  userType: User["type"];
}) => {
  return (
    (tradingDocument.status === "CONFIRMED" && userType !== "developer") ||
    (tradingDocument.type === "RECEIPT" && tradingDocument.invoiceType !== "RECEIPT")
  );
};

export const tradingDocumentUtils = {
  getDetailsBasedOnInvoiceType,
  getTradingDocumentLink,
  getTradingDocumentType,
  isTradingDocumentConfirmationDisabled,
};
