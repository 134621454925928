import { InventoryCheckItem } from "api/wh-inventory-check/models";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { InventoryCheckStatus } from "./components/InventoryCheckStatus";
import { InventoryCheckType } from "./components/InventoryCheckType";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { warehouseConstants } from "../../../constants/warehouse";
import { formatSubUnitsToDecimal } from "utilities";

export const useInventoryChecksColumns = () => {
  return useCreateTableColumns<InventoryCheckItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 200,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.finished, {
        header: "status",
        size: 90,
        cell: info => {
          const finished = info.getValue();
          return <InventoryCheckStatus finished={finished} />;
        },
      }),
      columnHelper.accessor(row => row.type, {
        header: "typ",
        size: 90,
        cell: info => {
          const type = info.getValue();
          return <InventoryCheckType type={type} />;
        },
      }),
      columnHelper.date(row => row.created, {
        header: "data rozpoczęcia",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.date(row => row.finished, {
        header: "data zakończenia",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.warehouse, {
        header: "magazyn",
        size: 100,
        cell: info => {
          const warehouse = info.getValue();
          if (!warehouse) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-2">
              <IconRenderer
                color="neutralBlack100"
                icon={warehouseConstants.getWarehouseIcons(warehouse.icon)}
                size={18}
              />
              <Typography fontSize="14" fontWeight="600">
                {warehouse.code || warehouse.name}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.amount(row => (row.difference ? formatSubUnitsToDecimal(row.difference) : ""), {
        header: "różnica",
        size: 135,
        textAlign: "right",
        amountDisplayProps: {
          currency: () => "PLN",
          integer: {
            fontSize: "14",
            fontWeight: "800",
          },
        },
      }),
      columnHelper.accessor(row => row.user, {
        header: "utworzono przez",
        size: 150,
        cell: info => {
          const user = info.getValue();
          if (!user) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Avatar size="sm" user={user} />
              <Typography fontSize="12" fontWeight="600">
                {user.firstName} {user.lastName}
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};
