import { shippingActions } from "api/shipping/actions";
import { Shipment } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiSend } from "components/miloDesignSystem/atoms/icons/MdiSend";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  shipment: Shipment;
}

export const AdvanceShipmentNotification = ({ shipment }: Props) => {
  const isShipmentNotifiedMutation = shippingActions.usePostAdvanceShipmentNotification();

  if (shipment.isShipmentNotified)
    return (
      <InfoLabel title="ASN">
        <Tag label="Wysłano ASN" variant="success" />
      </InfoLabel>
    );

  return (
    <InfoLabel title="ASN">
      <div className="d-flex align-items-center gap-2">
        <Tag label="Nie wysłano" variant="warning" />
        <Button
          className="text-uppercase"
          isLoading={isShipmentNotifiedMutation.isLoading}
          onClick={() =>
            isShipmentNotifiedMutation.mutate({
              shipmentsIds: [shipment.id],
            })
          }
          size="small"
          startIcon={MdiSend}
          variant="gray"
        >
          Wyślij ASN
        </Button>
      </div>
    </InfoLabel>
  );
};
