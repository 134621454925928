import { Pagination, UUID } from "api/types";
import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { PartialOf } from "typeUtilities";
import { queryString } from "utilities";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { tradingDocumentsKeys } from "./keys";
import {
  AddCustomerInPurchaseInvoiceImport,
  AddPurchaseInvoicePosition,
  AssignedWhEntry,
  AssigneWhEntryPreview,
  AssignPackageToLinkLinePreview,
  AssignPackageToWhEntryAggregate,
  AssignWhEntry,
  CreateTradingDocumentPayment,
  CurrentlyPrintedReceipt,
  FinancesAmountSummary,
  InvoiceAdmission,
  InvoiceAdmissionLinkLine,
  InvoiceReviewerStatus,
  InvoiceType,
  ModifyInvoiceAdmission,
  ModifyInvoiceAdmissionPackage,
  ModifyTradingDocument,
  MyPurchaseInvoiceToReview,
  PatchItemSummaryAmountPayload,
  PatchTradingDocumentSummary,
  PreviewTradingDocument,
  PurchaseInvoiceToReview,
  SendNotificationToRecipient,
  SummaryPerInPackage,
  SummaryPerOutPackage,
  TradingDocument,
  TradingDocumentType,
  UpdateStatusPayload,
  ViesStatusPayload,
  WarehousePackagesInOrOutSummary,
  WarehouseTotalValueSummary,
  WhEntryAggregate,
  WhiteListStatusPayload,
} from "./models";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { fileFactoryUtils } from "utilities/fileFactory";
import { TradingDocumentB2B } from "./b2b/models";
import { tradingDocumentUtils } from "utilities/tradingDocuments";

const api = tokenRefreshMiddleware(apiFetch);

export const getTradingDocuments = (
  search: string = "",
): ApiFetcher<Pagination<TradingDocument>> => ({
  key: tradingDocumentsKeys.tradingDocument.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/items" + search,
    }),
});

export const getMyInvoicesToReview = (
  search: string = "",
): ApiFetcher<Pagination<MyPurchaseInvoiceToReview>> => ({
  key: tradingDocumentsKeys.purchaseInvoicesToReview.myInvoices(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/reviewer-items" + search,
    }),
});

export const getPurchaseInvoicesToReview = (
  search: string = "",
): ApiFetcher<Pagination<PurchaseInvoiceToReview>> => ({
  key: tradingDocumentsKeys.purchaseInvoicesToReview.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/purchase-reviews" + search,
    }),
});

export const getInvoiceAdmissionLinkLines = (
  search: string = "",
): ApiFetcher<Pagination<InvoiceAdmissionLinkLine>> => ({
  key: tradingDocumentsKeys.invoiceAdmission.linkLines(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/invoice-admissions/link-line/items" + search,
    }),
});

export const getWhEntriesAggregates = (
  search: string = "",
): ApiFetcher<Pagination<WhEntryAggregate>> => ({
  key: tradingDocumentsKeys.invoiceAdmission.whEntryAggregates(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/invoice-admissions/wh-entry-aggregates/items" + search,
    }),
});

export const deleteTradingDocument = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/trading-documents/items/" + id,
  });

export const getCurrentlyPrintedReceipts = (
  search: string = "",
): ApiFetcher<Pagination<CurrentlyPrintedReceipt>> => ({
  key: tradingDocumentsKeys.printedReceipts.currentlyPrinted(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/printing-queue" + search,
    }),
});

export const getLastPrintedReceipts = (
  search: string = "",
): ApiFetcher<Pagination<CurrentlyPrintedReceipt>> => ({
  key: tradingDocumentsKeys.printedReceipts.lastPrinted(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/latest-prints" + search,
    }),
});

export const getFinancesAmountSummary = (
  search: string = "",
): ApiFetcher<FinancesAmountSummary> => ({
  key: tradingDocumentsKeys.financesAmountSummary.details(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/summaries/amount-summary" + search,
    }),
});

export const getWarehouseTotalValueSummary = (
  search: string = "",
): ApiFetcher<WarehouseTotalValueSummary> => ({
  key: tradingDocumentsKeys.warehouseFinancialState.warehouseTotalValueSummary(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents-receipts/wh-total-value-summary" + search,
    }),
});

export const getWarehousePackagesInOrOutSummary = (
  search: string = "",
): ApiFetcher<Pagination<WarehousePackagesInOrOutSummary>> => ({
  key: tradingDocumentsKeys.warehouseFinancialState.packagesInOrOutSummary(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents-receipts/wh-in-out-value-summary" + search,
    }),
});

export const getAssignedWhEntries = (
  search: string = "",
): ApiFetcher<Pagination<AssignedWhEntry>> => ({
  key: tradingDocumentsKeys.invoiceAdmission.assignedWhEntries(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/invoice-admissions/links/assigned-wh-entries" + search,
      // invoice_admission_link jako parametr tutaj + reszta paramsów
    }),
});

export const getWhEntriesAggregatesForSearchBar = (search = "", abortToken?: string) =>
  api<Pagination<WhEntryAggregate>>({
    method: "GET",
    url: "/invoice-admissions/wh-entry-aggregates/items" + search,
    abortToken,
  });

export const postWhEntryAggregates = (data: AssignWhEntry[]) =>
  queryFetch<any>({
    method: "POST",
    url: "/invoice-admissions/links/assigned-wh-entries",
    data,
  });

export const postWhEntryAggregate = (data: AssignWhEntry) =>
  queryFetch<AssigneWhEntryPreview>({
    method: "POST",
    url: "/invoice-admissions/links/assigned-wh-entries",
    data,
  });

export const deleteWhEntryAggregate = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/invoice-admissions/links/assigned-wh-entries/" + id,
  });

export const updateViesStatus = ({ id }: { id: UUID }) =>
  queryFetch<ViesStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/update-vies/" + id,
  });

export const patchInvoiceAdmissionPackage = (
  data: Assign<Partial<ModifyInvoiceAdmissionPackage>, { id: UUID }>,
) =>
  queryFetch<AssignPackageToLinkLinePreview>({
    method: "PATCH",
    url: "/invoice-admissions/packages/items/" + data.id,
    data: parsePatchData(data),
  });

export const postInvoiceAdmissionPackage = (data: AssignPackageToWhEntryAggregate) =>
  queryFetch<AssignPackageToLinkLinePreview>({
    method: "POST",
    url: "/invoice-admissions/packages/items",
    data,
  });

export const postPurchaseInvoicePosition = (data: AddPurchaseInvoicePosition) =>
  queryFetch<AddPurchaseInvoicePosition & { id: UUID }>({
    method: "POST",
    url: "/finances/trading-documents-items/purchase-items",
    data,
  });

export const deleteInvoiceAdmissionPackage = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/invoice-admissions/packages/items/" + id,
  });

export const updateWhiteListStatus = ({ id }: { id: UUID }) =>
  queryFetch<WhiteListStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/update-white-list/" + id,
  });

export const getTradingDocument = ({ id }: { id: UUID }): ApiFetcher<TradingDocument> => ({
  key: tradingDocumentsKeys.tradingDocument.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents/items/" + id,
    }),
});

export const getSummaryPerInPackage = ({
  id,
}: {
  id: number | string;
}): ApiFetcher<SummaryPerInPackage> => ({
  key: tradingDocumentsKeys.warehouseFinancialState.summaryPerInPackage(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents-receipts/wh-summary-per-package-in/" + id,
    }),
});

export const getSummaryPerOutPackage = ({
  id,
}: {
  id: number | string;
}): ApiFetcher<SummaryPerOutPackage> => ({
  key: tradingDocumentsKeys.warehouseFinancialState.summaryPerOutPackage(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/finances/trading-documents-receipts/wh-summary-per-package-out/" + id,
    }),
});

export const getInvoiceAdmission = ({ id }: { id: UUID }): ApiFetcher<InvoiceAdmission> => ({
  key: tradingDocumentsKeys.invoiceAdmission.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/invoice-admissions/links/items/" + id,
    }),
});

export const patchInvoiceAdmission = (data: PartialOf<ModifyInvoiceAdmission>, id: UUID) =>
  queryFetch<InvoiceAdmission>({
    method: "PATCH",
    url: "/invoice-admissions/links/items/" + id,
    data,
  });

export const patchTradingDocumentStatus = (data: { tradingDocument: UUID }) =>
  queryFetch<UpdateStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/confirm-sales-invoice",
    data,
  });

export const patchInvoiceAdmissionStatus = (data: { invoiceAdmissionLink: UUID }) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/invoice-admissions/links/confirm",
    data,
  });

export const patchPurchaseInvoiceStatus = (data: { tradingDocument: UUID }) =>
  queryFetch<UpdateStatusPayload>({
    method: "PATCH",
    url: "/finances/trading-documents/confirm-purchase-invoice",
    data,
  });

export const deleteTradingDocumentItem = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/trading-documents-items/items/" + id,
  });

export const patchItemSummaryAmounts = (
  data: Assign<PatchItemSummaryAmountPayload, { id: UUID }>,
) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: "/finances/trading-documents-items/items/" + data.id,
    data: parsePatchData(data),
  });

export const patchTradingDocumentSummary = (data: PatchTradingDocumentSummary) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: "/finances/trading-documents/items/" + data.id,
    data: data,
  });

export const restoreTradingDocumentSummaryAmount = (id: UUID) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: `finances/trading-documents/items/${id}/regenerate-amount-summary`,
  });

export const postTradingDocumentForMultipleOrders = (data: any) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/create-trading-document",
    data,
  });

export const patchTradingDocument = (data: Assign<Partial<ModifyTradingDocument>, { id: UUID }>) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: "/finances/trading-documents/items/" + data.id,
    data: parsePatchData(data),
  });

export const patchPurchaseInvoicePosition = (
  data: Assign<Partial<AddPurchaseInvoicePosition>, { id: UUID }>,
) =>
  queryFetch<AddPurchaseInvoicePosition>({
    method: "PATCH",
    url: "/finances/trading-documents-items/purchase-items/" + data.id,
    data: parsePatchData(data),
  });

export const patchReviewStatus = (data: {
  tradingDocument: UUID;
  status: InvoiceReviewerStatus | string;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/finances/trading-documents/review-status",
    data,
  });

export const deletePurchaseInvoicePosition = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/trading-documents-items/purchase-items/" + id,
  });

export const deleteReviewer = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/finances/trading-documents/reviewers/" + id,
  });

export const postTradingDocumentPayment = (data: CreateTradingDocumentPayment) =>
  queryFetch<any>({
    method: "POST",
    url: "/finances/payments/items",
    data,
  });

export const postNotificationToRecipient = (data: SendNotificationToRecipient) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/send-to-recipient",
    data,
  });

export const postInvoiceNotificationForRoute = (data: { routeId: string | number }) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/send-to-recipient-for-route",
    data,
  });

export const postInvoiceForOrder = (data: { orders: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "/finances/trading-documents/create-document-from-orders",
    data,
  });

export const postPreviewTradingDocument = (data: { orders: string[] | number[] }) =>
  queryFetch<PreviewTradingDocument>({
    method: "POST",
    url: "/finances/trading-documents/preview-trading-document",
    data,
  });

export const patchTradingDocumentCustomer = (
  data: Assign<Partial<AddCustomerInPurchaseInvoiceImport>, { id: UUID }>,
) =>
  queryFetch<TradingDocument>({
    method: "PATCH",
    url: "/finances/trading-documents/items/" + data.id,
    data: parsePatchData(data),
  });

export const postCsvFromAllegro = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/payments/imports/allegro",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postCsvFromEbay = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/payments/imports/ebay",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postCsvFromPaypal = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/payments/imports/paypal",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postCsvFromKaufland = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/payments/imports/kaufland",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postTxtFromAmazon = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/payments/imports/amazon",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.xlsm"',
    },
    onUploadProgress,
  });

export const postPurchaseInvoicePDF = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/finances/pdfs/items/bulk",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.pdf"',
    },
    onUploadProgress,
  });

export const postPurchaseInvoicePdf = (
  data: FormData,
  onUploadProgress: (progressEvent: ProgressEvent) => void,
) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/finances/pdfs/upload-trading-documents",
    data,
    headers: {
      "Content-Disposition": 'attachment; filename="filename.pdf"',
    },
    onUploadProgress,
  });

export const refreshImportInvoicePdf = ({ id }: { id: UUID }) => {
  return queryFetch({
    method: "PATCH",
    url: `/finances/pdfs/reprocess/${id}`,
  });
};

export const getInvoiceLabel = (invoiceType: InvoiceType, type: TradingDocumentType) => {
  if (type === "RECEIPT") return "receipts";
  return tradingDocumentConstants.tradingDocumentTypeToTabDict[invoiceType];
};

export const tradingDocumentFileFactory = (() => {
  return {
    wdtPdf: (tradingDocument: Pick<TradingDocument, "id" | "signature">) => ({
      url: `/finances/trading-documents/wdt-pdf/${tradingDocument.id}`,
      name: `Faktura-${tradingDocument.signature}-${fileFactoryUtils.getDate()}`,
    }),
    specification: (tradingDocument: Pick<TradingDocument, "id" | "signature">) => ({
      url: `/finances/trading-documents/specification-pdf/${tradingDocument.id}`,
      name: `Faktura-${tradingDocument.signature}-${fileFactoryUtils.getDate()}`,
    }),
    tradingDocumentPdf: (
      tradingDocument: TradingDocument | TradingDocumentB2B,
      language: string,
    ) => ({
      url: `/finances/trading-documents/${
        tradingDocument.invoiceType === "CORRECTION"
          ? "correction"
          : tradingDocument.invoiceType === "ADVANCE"
          ? "advance"
          : "sales"
      }-pdfs-download/${tradingDocument.signature.replace(/\//g, "_")}?tradingDocumentsIds=${
        tradingDocument.id
      }&language=${language}`,
      name: `${
        tradingDocumentUtils.getDetailsBasedOnInvoiceType(tradingDocument.invoiceType)
          .fileNamePrefix
      }-${tradingDocument.signature}-${fileFactoryUtils.getDate()}`,
    }),
    salesInvoiceDuplicatePdf: (salesInvoice: Pick<TradingDocument, "id" | "signature">) => ({
      url: `/finances/trading-documents/sales-duplicate-pdfs-download/${salesInvoice.signature.replace(
        /\//g,
        "_",
      )}?tradingDocumentsIds=${salesInvoice.id}`,
      name: `Faktura-sprzedażowa-duplikat-${salesInvoice.signature}-${fileFactoryUtils.getDate()}`,
    }),
    salesInvoicesFinancialReportCsv: (search: string) => ({
      url: `/finances/reports/financial${search}`,
      name: `zestawienie_dokumentów_handlowych_${fileFactoryUtils.getDate()}`,
    }),
    bulkSalesInvoicesPdf: (salesInvoicesIds: UUID[], language?: string) => ({
      url:
        language && Boolean(language.length)
          ? `/finances/trading-documents/sales-pdfs-download/pdf-grupy-faktur?language=${language}&tradingDocumentsIds=${encodeURIComponent(
              salesInvoicesIds.join(","),
            )}`
          : `/finances/trading-documents/sales-pdfs-download/pdf-grupy-faktur?tradingDocumentsIds=${encodeURIComponent(
              salesInvoicesIds.join(","),
            )}`,
      name: `Grupa-faktur-sprzedażowych-${fileFactoryUtils.getDate()}`,
    }),
    bulkCorrectionInvoicesPdf: (correctionInvoicesIds: UUID[], language?: string) => ({
      url: Boolean(language)
        ? `/finances/trading-documents/correction-pdfs-download/pdf-grupy-faktur?language=${language}&tradingDocumentsIds=${encodeURIComponent(
            correctionInvoicesIds.join(","),
          )}`
        : `/finances/trading-documents/correction-pdfs-download/pdf-grupy-faktur?tradingDocumentsIds=${encodeURIComponent(
            correctionInvoicesIds.join(","),
          )}`,
      name: `Grupa-korekt-${fileFactoryUtils.getDate()}`,
    }),
    salesInvoiceOptimaTradeModuleXml: (tradingDocuments: UUID[]) => ({
      url: `/finances/trading-documents/xml-export-optima-sales-trade-module?tradingDocumentsIds=${encodeURIComponent(
        tradingDocuments.join(","),
      )}`,
      name: `Moduł-handel-optima-${fileFactoryUtils.getDate()}`,
    }),
    whFinancesCsv: (search: string, name: string) => ({
      url: "/finances/trading-documents-receipts/wh-finances-csv" + search,
      name,
    }),
    optimaXml: (
      tradingDocument: Pick<TradingDocument, "invoiceType" | "type">,
      ids: UUID[],
      name: string,
    ) => ({
      url: `/finances/trading-documents/xml-export-optima-${getInvoiceLabel(
        tradingDocument.invoiceType,
        tradingDocument.type,
      )}?tradingDocumentsIds=${encodeURIComponent(ids.join(","))}`,
      name,
    }),
    revisorPdf: (tradingDocumentIds: TradingDocument["id"][]) => ({
      url: `/finances/exports/epp/bromarkt${queryString.stringify({ tradingDocumentIds })}`,
      name: `Revisor-${fileFactoryUtils.getDate()}`,
    }),
    fakirXml: (tradingDocumentIds: TradingDocument["id"][]) => ({
      url: `/finances/trading-documents/xml-export-fakir-sales${queryString.stringify({
        tradingDocumentIds,
      })}`,
      name: `Wapro-fakir-${fileFactoryUtils.getDate()}`,
    }),
  };
})();
