import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { EmptySummarySection } from "pages/finances/shared/components/emptySummarySection/EmptySummarySection";
import warehouseValueIcon from "assets/images/warehouseValue.svg";
import { financesUtils } from "pages/finances/shared/utils/financesUtils";
import { WarehouseStateTab } from "pages/finances/shared/constants/financesConstants";
import { useWarehousesFinancialStateSummaryColumns } from "./useWarehousesFinancialStateSummaryColumns";

interface Props {
  tab: WarehouseStateTab;
  filters: FinancialWarehouseStateSummaryFilters;
  setFilter: (key: keyof FinancialWarehouseStateSummaryFilters, value: number) => void;
}

export interface FinancialWarehouseStateSummaryFilters {
  page: number;
  pageSize: number;
  warehouseId?: string;
}

export const WarehousesFinancialStateSummary = ({ tab, filters, setFilter }: Props) => {
  const search = financesUtils.getWarehouseStateSearch({ query: {}, tab, filters });
  const {
    data: summaryPeriods,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financialWarehouseStateActions.useFinancialWarehouseStateGeneral(search);
  const columns = useWarehousesFinancialStateSummaryColumns(tab);
  const tableProps = useTableFeatureConnector({
    rows: summaryPeriods,
  });

  return (
    <div className="d-flex flex-column overflow-hidden flex-1">
      <Table<FinancialWarehouseStateSummaryPerPeriod>
        columns={columns}
        isLoading={isLoading || isPreviousData}
        error={error}
        pagination={pagination}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        uiSchema={{
          cell: { height: "36" },
          header: { backgroundColor: "neutralWhite100" },
        }}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          noResultComponent: (
            <EmptySummarySection
              icon={warehouseValueIcon}
              title="Nieznany stan wartości magazynu w ostatnich miesiącach"
            />
          ),
        })}
      />
    </div>
  );
};
