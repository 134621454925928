import { createPaginatedKey } from "api/keys";

export const productsKeys = {
  attributesPerProduct: (productId: string) => ["product", "attributes", productId],
  products: {
    details: (productId: string) => ["product", productId],
    list: createPaginatedKey("products"),
  },
  productsWithDeleted: {
    list: createPaginatedKey("productsWithDeleted"),
  },
  productAttributes: {
    list: createPaginatedKey("productAttributes"),
  },
  manufacturers: createPaginatedKey("manufacturers"),
  categories: {
    list: createPaginatedKey("productCategories"),
  },
  productIndexesPrice: createPaginatedKey("productIndexesPrice"),
  productIndexes: createPaginatedKey("productIndexes"),
  productEntity: (id: string) => ["productEntity", id],
  productAttributesFilter: {
    list: createPaginatedKey("productAttributesFilter"),
  },
};
