import darkPlusIcon from "assets/images/darkPlus.svg";
import { useQuery, useToggle } from "hooks";
import { useParams } from "react-router";
import { inventoryChecksUtils } from "./utils/inventoryChecksUtils";
import { inventoryChecksActions } from "api/wh-inventory-check/actions";
import { useInventoryChecksColumns } from "./useInventoryChecksColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { inventoryChecks } from "components/common/moduleNavigation/moduleConfig/finances/routes/inventoryChecks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { InventoryCheckItem } from "api/wh-inventory-check/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { CreateInventoryCheckModal } from "./components/createInventoryCheckModal/CreateInventoryCheckModal";
import { useNavigate } from "hooks/useNavigate";
import { InventoryCheckTab } from "./constants/inventoryChecksConstants";

export const InventoryChecks = () => {
  const { tab } = useParams<{ tab: InventoryCheckTab }>();
  const { query } = useQuery();
  const search = inventoryChecksUtils.getInventoryChecksSearch({
    query,
    tab,
  });
  const {
    data: inventoryChecks,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = inventoryChecksActions.useInventoryChecks(search);
  const columns = useInventoryChecksColumns();
  const tableProps = useTableFeatureConnector({
    rows: inventoryChecks,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });
  const navigate = useNavigate();

  const onRowClick = (rowId: string) => {
    navigate(`/finances/inventory-checks/${rowId}`);
  };

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<InventoryCheckItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          onRowClick={inventoryCheck => onRowClick(inventoryCheck.id.toString())}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  const createInventoryCheckModal = useToggle();

  return (
    <>
      <PageHeader
        createButton={{
          alt: "utwórz",
          disabled: false,
          img: darkPlusIcon,
          label: "Utwórz inwentaryzację",
          onClick: createInventoryCheckModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: inventoryChecksUtils.getInventoryChecksTabs(),
          routesRoot: `finances/${inventoryChecks.url}`,
          urlSpan: "list",
        }}
        viewLabel="INVENTORY_CHECKS"
      />
      {createInventoryCheckModal.isOpen && (
        <CreateInventoryCheckModal close={createInventoryCheckModal.close} />
      )}
    </>
  );
};
