import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { financialWarehouseStateApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";

const useFinancialWarehouseStateGeneral = createPaginatedApiQuery(
  financialWarehouseStateApi.getFinancialWarehouseStateGeneralSummary,
);

const useFinancialWarehouseStateForToday = createApiQuery(
  financialWarehouseStateApi.getFinancialWarehouseStateForToday,
);
const useFinancialWarehouseStateDetails = createPaginatedApiQuery(
  financialWarehouseStateApi.getFinancialWarehouseStateDetails,
);

export const financialWarehouseStateActions = {
  useFinancialWarehouseStateDetails,
  useFinancialWarehouseStateForToday,
  useFinancialWarehouseStateGeneral,
};
