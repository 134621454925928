import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { useDrawer } from "hooks/useDrawer";
import { useQuery } from "hooks";
import { productsActions } from "api/products/actions";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiChairAlt } from "components/miloDesignSystem/atoms/icons/MdiChairAlt";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { queryString } from "utilities";
import { MdiAddVariant } from "components/miloDesignSystem/atoms/icons/MdiAddVariant";

export const PanelHeader = () => {
  const { close } = useDrawer("products");
  const { isTriggerElementHidden } = useScrollableContext();
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({ showDeleted: true });
  const { data: product } = productsActions.useProduct({ id: panelId, search });
  assertIsDefined(product);

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="nowrap">{product.name}</SlidingHeaderWrapper>
        ) : (
          <div />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiChairAlt}
            variant="gray"
          >
            Warianty produktu
          </Button>
          <Tooltip title="Dodaj wariant">
            <IconButton icon={MdiAddVariant} variant="transparent" />
          </Tooltip>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
    </>
  );
};
