import { useFilterContext } from "components/utils/withFilters";
import { useQuery, useSelector } from "hooks";
import { cx } from "utilities";
import styles from "./FilterBar.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DEFAULT_ICON_SIZE, Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import {
  AvailableFilters,
  FilterAsyncSearchType,
  FilterType,
} from "components/common/filters/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import {
  NormalizedData,
  normalizeData,
} from "components/common/filters/filterComponents/filterSearch/FilterSearch";
import { Seller } from "api/sellers/models";
import {
  normalizeCarData,
  normalizeCarrierData,
  normalizeUserData,
} from "components/common/filters/filterComponents/filterSelectUser/FilterSelectUser";
import { ApiFetcher, createApiQuery } from "hooks/createApiQuery";
import { Pagination } from "api/types";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { getRoute } from "api/routes/calls";
import { outsideClickIgnoreClassFilterDrawer } from "components/common/moduleNavigation/components/filtersSection/FilterDrawerRenderer";
import React from "react";

export const FilterBar = () => {
  const filterContext = useFilterContext();
  if (!filterContext) return null;

  if (!filterContext.appliedFilters.length) return null;

  return (
    <div
      className={cx(
        "d-flex py-2 px-3 gap-2 flex-1",
        styles.wrapper,
        {
          "filter-drawer-opened-margin": filterContext?.isFilterDrawerOpen,
        },
        outsideClickIgnoreClassFilterDrawer,
      )}
    >
      {filterContext.appliedFilters.map((filter, index) => (
        <React.Fragment key={index}>
          <AppliedFilterItem filter={filter} />
          {filterContext.appliedFilters.length !== 1 &&
            index !== filterContext.appliedFilters.length - 1 && <div className={styles.border} />}
        </React.Fragment>
      ))}
    </div>
  );
};

const AppliedFilterItem = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>({
  filter,
}: {
  filter: AvailableFilters<FF, T>;
}) => {
  const values = useFilterRenderer({ filter });

  return (
    <div className={styles.appliedFilterItem}>
      <Typography
        fontSize="10"
        fontWeight="700"
        color="neutralWhite64"
        className="mb-1 uppercase"
        noWrap
      >
        {filter.label}
      </Typography>
      <div className="d-flex align-items-center gap-1">
        {values.map(({ label, value, handleRemoveFilter }) => (
          <Tag
            theme="dark"
            key={label + value}
            type="filled"
            label={label}
            variant="quaternary"
            endIcon={
              <IconButton
                theme="dark"
                className={styles.deleteButton}
                variant="transparent"
                size="small"
                onClick={handleRemoveFilter}
                icon={<MdiClose color="neutralWhite24" size={String(DEFAULT_ICON_SIZE)} />}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export interface FilterBarValue {
  value: string;
  label: string;
  handleRemoveFilter: () => void;
}

const useFilterRenderer = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<any>> | ApiMiddlewareResult<Pagination<any>>,
  T extends string
>({
  filter,
}: {
  filter: AvailableFilters<FF, T>;
}): FilterBarValue[] => {
  assertIsDefined(filter);
  const queryUtils = useQuery();
  const { query, updateQuery } = useQuery();
  const queryValue = query[filter.name as string];
  const partials = useSelector(state => state.partials);
  const useDetailsFetch = createApiQuery(
    (filter as FilterAsyncSearchType<FF, T>)?.fetchDetailsFrom || getRoute,
  );

  const filterAsyncSearchQuery = useDetailsFetch(query[filter.name as string], {
    enabled: Boolean(query[filter.name as string] && filter.type === FilterType.AsyncSearch),
  });

  switch (filter.type) {
    case FilterType.Search: {
      const options = partials[filter.searchBy];
      //REMOVE ME! WHEN: Filter to MDS PR
      const filteredOptions =
        filter.searchBy === "businessEntities" &&
        filter.overrides?.businessEntitiesKind === "INTERNAL"
          ? (options as Seller[]).filter(option => option.kind === "INTERNAL")
          : filter.searchBy === "businessEntities" &&
            filter.overrides?.businessEntitiesKind === "EXTERNAL"
          ? ((options as unknown) as Seller[]).filter(option => option.kind === "EXTERNAL")
          : options;

      const selectedItems = (() => {
        const selectedIds = query[filter.name as string]?.split(",");
        if (!selectedIds?.length) return [];
        //@ts-ignore
        return normalizeData(filteredOptions).reduce<NormalizedData[]>((acc, el) => {
          if (selectedIds?.includes(String(el.id))) {
            acc.push(el);
          }
          return acc;
        }, []);
      })();

      return selectedItems.map(({ id, name }) => ({
        label: name,
        value: String(id),
        handleRemoveFilter: () => {
          updateQuery({
            [filter.name]: queryValue
              .split(",")
              .filter(queryVal => queryVal !== id)
              .toString(),
          });
        },
      }));
    }

    case FilterType.AsyncSearch: {
      return [
        {
          label: filterAsyncSearchQuery.data?.[filter.display] || "",
          value: queryValue,
          handleRemoveFilter: () => {
            updateQuery({ [filter.name]: "" });
          },
        },
      ];
    }

    case FilterType.Select: {
      return queryValue.split(",").map(value => {
        const option = filter.options.find(option => option.value === value);
        assertIsDefined(option);
        return {
          label: option?.label,
          value,
          handleRemoveFilter: () => {
            // updateQuery({ [filter.name]: "" });
            updateQuery({
              [filter.name]: queryValue
                .split(",")
                .filter(queryVal => queryVal !== value)
                .toString(),
            });
          },
        };
      });
    }

    case FilterType.SelectUser: {
      const normalizedData: NormalizedData[] = {
        user: () => normalizeUserData(partials[filter.searchBy]),
        car: () => normalizeCarData(partials.cars),
        carrier: () => normalizeCarrierData(partials.carriers),
      }[filter.kind]();

      const selectedItems = (() => {
        const selectedIds = query[filter.name as string]?.split(",");
        if (!selectedIds?.length) return [];
        return normalizedData.reduce<NormalizedData[]>((acc, user) => {
          if (selectedIds?.includes(String(user.id))) acc.push(user);
          return acc;
        }, []);
      })();
      return selectedItems.map(({ id, name }) => ({
        label: name,
        value: String(id),
        handleRemoveFilter: () => {
          updateQuery({ [name]: "" });
        },
      }));
    }

    case FilterType.DateRange:
    case FilterType.DateRangeWithOptions: {
      const [startDateName, endDateName] = filter.name;
      const startDate = query[startDateName];
      const endDate = query[endDateName];
      let values = [];
      if (startDate) {
        values.push({
          label: `od: ${startDate}`,
          value: startDate,
          handleRemoveFilter: () => {
            updateQuery({ [startDateName]: "" });
          },
        });
      }

      if (endDate) {
        values.push({
          label: `do: ${endDate}`,
          value: endDate,
          handleRemoveFilter: () => {
            updateQuery({ [endDateName]: "" });
          },
        });
      }
      return values;
    }

    case FilterType.Date:
    case FilterType.Text:
    case FilterType.Number: {
      return [
        {
          label: queryValue,
          value: queryValue,
          handleRemoveFilter: () => {
            updateQuery({ [filter.name]: "" });
          },
        },
      ];
    }

    case FilterType.Custom: {
      return filter.filterBarValueRenderer(queryUtils);
    }

    default:
      const exhaustiveCheck: never = filter;
      throw new Error(`Unhandled color case: ${exhaustiveCheck}`);
  }
};
