import { inventoryChecksActions } from "api/wh-inventory-check/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  close: () => void;
}

export const CreateInventoryCheckModal = ({ close }: Props) => {
  const createInventoryCheckMutation = inventoryChecksActions.useCreateInventoryCheck();

  return (
    <Modal
      close={close}
      isOpen
      width={850}
      title={
        <Typography fontSize="20" fontWeight="700">
          Potwierdzenie rozpoczęcia inwentaryzacji
        </Typography>
      }
    >
      <div className="p-3">
        <Typography fontSize="16" fontWeight="700">
          Potwierdzenie rozpoczęcia inwentaryzacji zablokuje możliwość wykonywania wszystkich innych
          czynności w magazynie
        </Typography>
        <div className="d-flex align-items-center gap-2 pt-4 pb-2">
          <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
            Zamknij
          </Button>
          <Button
            className="text-uppercase"
            onClick={() => createInventoryCheckMutation.mutate({})}
            size="medium"
            variant="deepPurple"
          >
            Rozpocznij inwentaryzację
          </Button>
        </div>
      </div>
    </Modal>
  );
};
