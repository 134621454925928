import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { dateFns, dateUtils } from "utilities";
import { WarehouseWorkerDetails } from "api/wms/models";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { MdiExpandView } from "components/miloDesignSystem/atoms/icons/MdiExpandView";
import { useToggle } from "hooks";
import { DailySummaryReceivedAndReleased } from "../dailySummaryReceivedAndReleased/DailySummaryReceivedAndReleased";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import styles from "./PanelHeader.module.css";
import { DatePicker } from "components/utils/datePicker";
import { useState } from "react";
import { ClickOutsideHandler, StatusHandler } from "components/utils";
import { MdiDownloadCsv } from "components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { wmsWarehousemanActions } from "api/wms/warehouseman/actions";

interface Props {
  close: () => void;
  warehouseWorker: WarehouseWorkerDetails;
}

const initialDateFrom = dateUtils.formatDateToIso(dateFns.subMonths(new Date(), 1));
const initialDateTo = dateUtils.formatDateToIso(new Date());

export const PanelHeader = ({ close, warehouseWorker }: Props) => {
  const modalController = useToggle();
  const [dateFrom, setDateFrom] = useState(initialDateFrom);
  const [dateTo, setDateTo] = useState(initialDateTo);
  const { isTriggerElementHidden } = useScrollableContext();
  const handleDownloadCsv = wmsWarehousemanActions.useDownloadWarehousemanCsv();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">
            {warehouseWorker.firstName} {warehouseWorker.lastName}
          </SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Button
            className="text-uppercase"
            endIcon={MdiExpandView}
            onClick={modalController.open}
            size="small"
            variant="gray"
          >
            Zestawienie dzienne
          </Button>
          <Popover
            hideOnClickOutside={false}
            variant="DARK"
            content={({ hide }) => (
              <ClickOutsideHandler
                onClickOutside={() => {
                  hide();
                  setDateFrom(initialDateFrom);
                  setDateTo(initialDateTo);
                }}
                outsideClickIgnoreClass={ignoreClickOutsideClassName}
              >
                <div>
                  <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                    Wskaż zakres dat:
                  </Typography>
                  <div className="d-flex">
                    <div className="position-relative mr-1">
                      <span className={styles.label}>Od:</span>
                      <DatePicker
                        calendarClassName={ignoreClickOutsideClassName}
                        overwrites={{
                          container: { className: ignoreClickOutsideClassName },
                          popup: { className: ignoreClickOutsideClassName },
                        }}
                        placeholder="Wybierz"
                        className={styles.date}
                        value={dateFrom}
                        selectsStart
                        removeDate={false}
                        tabIndex={-1}
                        startDate={dateFrom ? new Date(dateFrom) : null}
                        endDate={dateFrom ? new Date(dateFrom) : null}
                        maxDate={dateTo ? new Date(dateTo) : null}
                        onChange={date => {
                          if (!date) return;
                          setDateFrom(dateUtils.formatDateToIso(date));
                        }}
                      />
                    </div>
                    <div className="position-relative">
                      <span className={styles.label}>Do:</span>
                      <DatePicker
                        placeholder="Wybierz"
                        overwrites={{
                          container: { className: ignoreClickOutsideClassName },
                          popup: { className: ignoreClickOutsideClassName },
                        }}
                        calendarClassName={ignoreClickOutsideClassName}
                        tabIndex={-1}
                        className={styles.date}
                        removeDate={false}
                        value={dateTo}
                        selectsEnd
                        startDate={dateFrom ? new Date(dateFrom) : null}
                        endDate={dateTo ? new Date(dateTo) : null}
                        minDate={dateFrom ? new Date(dateFrom) : null}
                        onChange={date => {
                          if (!date) return;
                          setDateTo(dateUtils.formatDateToIso(date));
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-1 justify-content-end mt-3">
                    <StatusHandler>
                      {helpers => (
                        <Button
                          className="text-uppercase"
                          tabIndex={2}
                          size="small"
                          variant="deepPurple"
                          onClick={() =>
                            handleDownloadCsv(helpers, warehouseWorker, dateFrom, dateTo)
                          }
                          isLoading={helpers.isFetching}
                        >
                          Pobierz CSV
                        </Button>
                      )}
                    </StatusHandler>
                  </div>
                </div>
              </ClickOutsideHandler>
            )}
          >
            <IconButton icon={MdiDownloadCsv} variant="transparent" />
          </Popover>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {modalController.isOpen && (
        <DailySummaryReceivedAndReleased
          close={modalController.close}
          warehouseWorker={warehouseWorker}
        />
      )}
    </>
  );
};

const ignoreClickOutsideClassName = "warehouseman-popover-ignore-class-name";
