import {
  ColumnHelperArgs,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { warehouseConstants } from "constants/warehouse";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { formatSubUnitsToDecimal } from "utilities";
import { BaseWhEntry } from "api/wh-entries/models";
import { WhEntryStatus } from "../../components/whEntryStatus/WhEntryStatus";
import { ColumnDef } from "@tanstack/react-table";

export const whEntriesColumnFactory = (columnHelper: ColumnHelperArgs<BaseWhEntry>) => {
  return {
    created: () =>
      columnHelper.date(row => row.created, {
        header: "data utworzenia",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }) as ColumnDef<BaseWhEntry>,
    closed: () =>
      columnHelper.date(row => row.closed, {
        header: "data zamknięcia",
        size: 100,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }) as ColumnDef<BaseWhEntry>,
    signature: () =>
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 140,
        typographyProps: {
          fontSize: "14",
        },
      }) as ColumnDef<BaseWhEntry>,
    status: () =>
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 90,
        cell: info => {
          const status = info.getValue();
          return <WhEntryStatus status={status} />;
        },
      }) as ColumnDef<BaseWhEntry>,
    warehouse: () =>
      columnHelper.accessor(row => row.warehouse, {
        header: "magazyn",
        size: 100,
        cell: info => {
          const warehouse = info.getValue();
          if (!warehouse) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <IconRenderer
                color="neutralBlack100"
                icon={warehouseConstants.getWarehouseIcons(warehouse.icon)}
                size={18}
              />
              <Typography fontSize="14" fontWeight="600" noWrap>
                {warehouse.code || warehouse.name}
              </Typography>
            </div>
          );
        },
      }) as ColumnDef<BaseWhEntry>,
    totalAmount: () =>
      columnHelper.accessor(row => row.totalAmount, {
        id: "totalAmount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wartość towarów
          </Typography>
        ),
        size: 145,
        cell: info => {
          const totalAmount = info.getValue();
          return (
            <div className="d-flex justify-content-end w-100">
              {totalAmount === null ? (
                <EmptyValue fontSize="14" />
              ) : (
                <AmountDisplay
                  amount={formatSubUnitsToDecimal(totalAmount)}
                  currency="PLN"
                  decimalTypographyProps={{
                    fontSize: "12",
                    fontWeight: "600",
                  }}
                  integerTypographyProps={{
                    fontSize: "14",
                    fontWeight: "800",
                  }}
                  noSeparator
                />
              )}
            </div>
          );
        },
      }) as ColumnDef<BaseWhEntry>,
  };
};
