import { BankAccount } from "api/bank-accounts/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiVerticalAlignBottom } from "components/miloDesignSystem/atoms/icons/MdiVerticalAlignBottom";
import { useToggle } from "hooks";
import { DownloadTransactionsModal } from "./DownloadTransactionsModal";

interface Props {
  bankAccount: BankAccount;
}

export const DownloadTransactionsButton = ({ bankAccount }: Props) => {
  const downloadTransactionModal = useToggle();

  return (
    <>
      <Button
        className="text-uppercase"
        onClick={downloadTransactionModal.open}
        size="small"
        startIcon={MdiVerticalAlignBottom}
        variant="gray"
      >
        Pobierz transakcje
      </Button>
      {downloadTransactionModal.isOpen && (
        <DownloadTransactionsModal
          bankAccount={bankAccount}
          close={downloadTransactionModal.close}
        />
      )}
    </>
  );
};
