import { Route } from "components/utils";
import { WarehouseFinancialState } from "pages/finances/warehousesFinancialState/WarehouseFinancialState";
import { NotFound } from "pages/notFound/NotFound";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";

export const WarehouseFinancialStateRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/monthly`} />}
      />
      <Route
        component={WarehouseFinancialState}
        exact
        path={`${match.path}/:tab`}
        title="Milo - finansowy stan magazynu"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
