import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiReceptions = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      d="M20 19H18V10H6H4V8.35L12 5.15L20 8.35V19ZM22 21V7L12 3L2 7V12H8H16V21H22ZM15 21V19H13V21H15ZM13 18V16H11V18H13ZM11 21V19H9V21H11Z"
      fillOpacity="0.88"
    />
    <path d="M6 21L4.6 19.6L6.175 18H2V16H6.175L4.575 14.4L6 13L10 17L6 21Z" fillOpacity="0.88" />
  </SvgWrapper>
);
