export enum WhEntryStatus {
  CLOSED = "CLOSED",
  OPENED = "OPENED",
}

export enum WhEntryCategory {
  EXPENDITURE = "EXPENDITURE",
  RECEPTION = "RECEPTION",
  RELEASE = "RELEASE",
  RETURN = "RETURN",
}

export enum WhEntryReturnReason {
  CANCELATION = "CANCELATION",
  COMPLAINT = "COMPLAINT",
  NO_COLLECTION = "NO_COLLECTION",
  OTHER = "OTHER",
}

export enum WhEntryExpenditureReason {
  LOSS = "LOSS",
  OWN_PURCHASES = "OWN_PURCHASES",
}

export enum LineItemKind {
  PRODUCT = "PRODUCT",
  PACKAGE = "PACKAGE",
}

export enum OrderCollectionSource {
  ROUTE = "ROUTE",
  ORDER_GROUP = "ORDER_GROUP",
}
