import { Row as RowType } from "@tanstack/react-table";
import { PropsWithChildren } from "react";
import { cx } from "utilities";
import { Assign } from "utility-types";
import { TABLE_PADDING_HORIZONTAL } from "../../../../constants";
import { BodyProps } from "../../Body";
import styles from "./Row.module.css";

type Props<T> = PropsWithChildren<Assign<Omit<BodyProps<T>, "uiSchema">, { row: RowType<T> }>>;

export const Row = <T,>({ children, table, onRowClick, overrides, row }: Props<T>) => {
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;

  const isRowHoverable = Boolean(onRowClick);
  const isSubRow = row.depth;
  const hasSubRows = row.subRows.length;

  if (isSubRow)
    return (
      <SubRow row={row} onRowClick={onRowClick} overrides={overrides} table={table}>
        {children}
      </SubRow>
    );

  return (
    <div
      className={cx(
        styles.row,
        "d-flex align-items-center",
        {
          [styles.hoverable]: isRowHoverable,
          "border-bottom-0": hasSubRows && !isSubRow,
        },
        overrides?.().row?.(row.original).className,
      )}
      style={{ width, maxWidth: width, minWidth: width }}
      onMouseDownCapture={event => {
        if (event.shiftKey) {
          event.preventDefault();
        }
      }}
      onClick={event => {
        let selection = window.getSelection();

        if (!selection || selection.toString().length <= 0) {
          onRowClick?.(row.original, event);
        }
      }}
    >
      {children}
    </div>
  );
};

const SubRow = <T,>({ children, overrides, table, onRowClick, row }: Props<T>) => {
  const isLastSubRow =
    row.getParentRow()?.subRows[(row.getParentRow()?.subRows.length || 0) - 1]?.id === row.id;
  const width = table.getTotalSize() + TABLE_PADDING_HORIZONTAL;
  const isRowHoverable = Boolean(onRowClick);

  return (
    <div
      className={cx(
        overrides?.().row?.(row.original).className,
        styles.row,
        "d-flex align-items-center",
        {
          [styles.hoverable]: isRowHoverable,
          [styles.nested]: !isLastSubRow,
        },
      )}
      onClick={event => {
        let selection = window.getSelection();

        if (!selection || selection.toString().length <= 0) {
          onRowClick?.(row.original, event);
        }
      }}
      style={{ width, maxWidth: width, minWidth: width }}
    >
      {children}
    </div>
  );
};
