import { languages } from "CONSTANTS";
import { TradingDocumentB2B } from "api/trading-documents/b2b/models";
import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { Formik } from "formik";
import { cx, noop } from "utilities";

interface Props {
  tradingDocument: TradingDocument | TradingDocumentB2B;
}

interface Values {
  language: string;
}

export const DownloadTradingDocumentPdf = ({ tradingDocument }: Props) => {
  const initialValues: Values = {
    language: tradingDocument.countryCode || "",
  };

  const getTooltipSuffix = () => {
    switch (tradingDocument.invoiceType) {
      case "ADVANCE":
        return "faktury zaliczkowej";
      case "CORRECTION":
        return "korekty";
      case "FINAL":
        return "faktury końcowej";
      case "PROFORMA":
        return "proformy";
      case "PURCHASE":
        return "faktury zakupowej";
      case "SALES":
        return "faktury sprzedażowej";
      case "RECEIPT":
      case "RECEIPT_BASED_ON_INVOICE":
        return "paragonu";
      default:
        const exhaustiveCheck: never = tradingDocument.invoiceType;
        console.error(`Unhandled invoice type: ${exhaustiveCheck}`);
        return "dokumentu handlowego";
    }
  };

  return (
    <Popover
      hideOnClickOutside={false}
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={hide}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Formik initialValues={initialValues} onSubmit={noop}>
              {({ handleSubmit, isValid, values }) => (
                <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
                  <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
                    Wybierz język {tradingDocument.type === "RECEIPT" ? "paragonu" : "faktury"}
                  </Typography>
                  <Select.Form<Values>
                    items={languages.map(language => ({
                      value: language.value,
                      text: language.language,
                      type: MenuItemType.TEXT,
                    }))}
                    label="Wybierz język"
                    name="language"
                  />
                  <div className="d-flex flex-1 justify-content-end mt-3">
                    <FileDownloadHandler
                      factoryFn={() =>
                        tradingDocumentFileFactory.tradingDocumentPdf(
                          tradingDocument,
                          values.language,
                        )
                      }
                      type="pdf"
                    >
                      {({ download, isLoading }) => (
                        <Button
                          className="text-uppercase w-100"
                          isLoading={isLoading}
                          onClick={async () => {
                            await download();
                            hide();
                          }}
                          size="small"
                          variant="deepPurple"
                        >
                          Pobierz PDF
                        </Button>
                      )}
                    </FileDownloadHandler>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </ClickOutsideHandler>
      )}
      variant="DARK"
    >
      <div>
        <Tooltip
          title={`Pobierz PDF z danymi ${
            tradingDocument.type === "RECEIPT" ? "paragonu" : getTooltipSuffix()
          }`}
        >
          <IconButton icon={<MdiDownloadPdf size="18" />} variant="transparent" />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "trading-documents-pdf-popover-ignore-class-name";
