import { Typography } from "components/miloDesignSystem/atoms/typography";
import styled from "@emotion/styled";
import tooltipStyles from "../../atoms/tooltip/Tooltip.module.css";
import { cx } from "utilities";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

export const ChartComponents = () => {};

const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div className={cx(tooltipStyles.tooltip, tooltipStyles.light)}>
        {payload[0].payload.tooltipBodyRenderer}
      </div>
    );
  }

  return null;
};

const CustomLegendContent = ({
  data,
}: {
  data: { color: ColorPalette; legendText: string; kind: "bar" | "line" }[];
}) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {data.map(legend => (
        <div className="d-flex align-items-center gap-1" key={legend.legendText}>
          {legend.kind === "bar" ? (
            <StyledLegendBar color={legend.color || "deepPurple400"} />
          ) : (
            <StyledLegendLine color={legend.color || "deepPurple400"} />
          )}

          <Typography fontSize="12" fontWeight="600">
            {legend.legendText}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const StyledLegendBar = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ color }: { color: ColorPalette }) => colorPalette[color]};
  border-radius: 2px;
`;

const StyledLegendLine = styled.div`
  width: 16px;
  height: 3px;
  background-color: ${({ color }: { color: ColorPalette }) => colorPalette[color]};
  border-radius: 2px;
`;

const CustomXTick = ({ x, y, payload }: any) => {
  const displayValue = chartUtils.getReadableValue(payload.value);

  return (
    <g transform={`translate(${x},${y})`} className="border">
      <text
        width="100%"
        fontFamily="Sofia Sans"
        fontSize="12"
        fontWeight="600"
        x={0}
        y={8}
        textAnchor="middle"
        className="border"
      >
        {displayValue}
      </text>
    </g>
  );
};

const CustomYTick = ({ x, y, payload }: any) => {
  const displayValue = chartUtils.getReadableValue(payload.value);

  return (
    <g transform={`translate(${x},${y})`}>
      <text fontFamily="Sofia Sans" fontSize="12" fontWeight="600" x={0} y={0} textAnchor="end">
        {displayValue}
      </text>
    </g>
  );
};

ChartComponents.CustomTooltip = CustomTooltip;
ChartComponents.CustomLegendContent = CustomLegendContent;
ChartComponents.CustomXTick = CustomXTick;
ChartComponents.CustomYTick = CustomYTick;

const chartUtils = {
  getReadableValue: (value: number) => {
    if (value > 1000000) {
      return `${(value / 1000000).toFixed(1)} mln`;
    }
    if (value > 1000) {
      return `${(value / 1000).toFixed(1)} tys`;
    }
    return value;
  },
};

export default chartUtils;
