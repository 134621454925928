import { ProductKind } from "api/products/models";
import { TabProps } from "api/types";
import { dictToList } from "utilities";

export enum ProductsTab {
  ALL = "all",
  CANCELLED = "cancelled",
  EXTERNAL_PRODUCED = "external-produced",
  SELF_PRODUCED = "self-produced",
}

const productsTabDict: Record<ProductsTab, string> = {
  all: "Wszystkie",
  "self-produced": "Produkcja własna",
  "external-produced": "Produkcja zewnętrzna",
  cancelled: "Wycofane ze sprzedaży",
};

const productsKindDict: Record<ProductKind, string> = {
  upholstery: "tapicerowany",
  box: "skrzyniowy",
  other: "akcesoria",
  service: "usługa",
  set: "zestaw",
};

const getProductsTabs = (): TabProps => {
  return dictToList(productsTabDict).map(({ key, value }) => ({
    label: value as string,
    name: key,
  }));
};

export const productsConstants = {
  getProductsTabs,
  productsKindDict,
  productsTabDict,
};
