import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { RouteComponentProps } from "react-router";
import { TradingDocument, TradingDocumentTab } from "api/trading-documents/models";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery } from "hooks";
import { getSearch } from "../shared/utils/getSearch";
import { useTradingDocuments } from "api/trading-documents/hooks";
import { useProformaColumns } from "./useProformaColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";
import { proformas } from "components/common/moduleNavigation/moduleConfig/finances/routes/proformas";
import { getTabs } from "../shared/utils/getTabs";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { RightPanel } from "./rightPanel/RightPanel";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { LedgerAccountSelector } from "../shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "../shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { TradingDocumentPdf } from "../shared/tableToolbar/tradingDocumentPdf/TradingDocumentPdf";
import { DownloadRevisor } from "../shared/tableToolbar/downloadRevisor/DownloadRevisor";
import { ChangeIssueOrDeliveryInvoiceDate } from "../shared/tableToolbar/changeIssueOrDeliveryInvoiceDate/ChangeIssueOrDeliveryInvoiceDate";

export const Proformas = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ invoiceType: "PROFORMA", query, tab, type: "INVOICE" });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useTradingDocuments(search);
    const columns = useProformaColumns();
    const tableMultiSelect = useTableMultiSelect({ rows: tradingDocuments });
    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "proformaInvoices",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<TradingDocument>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <ChangeIssueOrDeliveryInvoiceDate selectedRows={tableMultiSelect.selectedRows} />
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentPdf
              invoiceType="PROFORMA"
              selectedRows={tableMultiSelect.selectedRows}
            />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = () => {
  return (
    <PageHeader
      additionalListParams={{
        type: "INVOICE",
        invoiceType: "PROFORMA",
      }}
      searchInput={{
        label: "Szukaj...",
        tags: [
          {
            name: "issueDateFrom",
            label: "data wystawienia (od)",
            value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
          },
          {
            name: "issueDateTo",
            label: "data wystawienia (do)",
            value: dateUtils.formatDateToIso(new Date()),
          },
        ],
      }}
      tabs={{
        list: getTabs(),
        routesRoot: `finances/${proformas.url}`,
        urlSpan: "list",
      }}
      viewLabel="PROFORMAS"
    />
  );
};
