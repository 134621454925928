import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { chartKeys } from "./keys";
import { ReceptionChartValue, ExpenditureChartValue } from "./models";

const getReceptions = ({ search = "" }: { search: string }): ApiFetcher<ReceptionChartValue[]> => ({
  key: chartKeys.receptions(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/charts/receptions" + search,
    }),
});

const getExpenditures = ({
  search = "",
}: {
  search: string;
}): ApiFetcher<ExpenditureChartValue[]> => ({
  key: chartKeys.expenditures(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/charts/expenditures" + search,
    }),
});

export const financialPackagesChartsApi = {
  getReceptions,
  getExpenditures,
};
