import { countryCurrenciesFlags } from "CONSTANTS";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { SelectProps } from "components/miloDesignSystem/molecules/select/types";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const SelectBankAccount = ({ tradingDocument }: { tradingDocument: TradingDocument }) => {
  return (
    <InfoLabel title="konto bankowe">
      <Select.Async
        className="w-50"
        items={getBankSelectItems(tradingDocument)}
        mutationHook={usePatchTradingDocumentMutation}
        selected={getBankNumberAndName(
          tradingDocument.sellerBankName,
          tradingDocument.sellerBankAccountNumber,
        )}
        transformQueryData={value => ({
          id: tradingDocument.id,
          sellerBankName: value ? (value as string).split(";")[0] : "",
          sellerBankAccountNumber: value ? (value as string).split(";")[1] : "",
        })}
      />
    </InfoLabel>
  );
};

function getBankSelectItems(tradingDocument: TradingDocument) {
  const bankItems: SelectProps["items"] = [
    {
      type: MenuItemType.TEXT,
      text: EMPTY_VALUE,
      value: "",
    },
  ];
  if (tradingDocument.seller?.plnBankName) {
    bankItems.push({
      type: MenuItemType.ICON,
      icon: countryCurrenciesFlags["PLN"],
      text: tradingDocument.seller?.plnBankName,
      value: getBankNumberAndName(
        tradingDocument.seller?.plnBankName,
        tradingDocument.seller?.plnBankAccountNumber,
      ),
    } as SelectProps["items"][number]);
  }
  if (tradingDocument.seller?.plnAlternativeBankName) {
    bankItems.push({
      type: MenuItemType.ICON,
      icon: countryCurrenciesFlags["PLN"],
      text: tradingDocument.seller?.plnAlternativeBankName,
      value: getBankNumberAndName(
        tradingDocument.seller?.plnAlternativeBankName,
        tradingDocument.seller?.plnAlternativeBankAccountNumber,
      ),
    } as SelectProps["items"][number]);
  }
  if (tradingDocument.seller?.eurBankName) {
    bankItems.push({
      type: MenuItemType.ICON,
      icon: countryCurrenciesFlags["EUR"],
      text: tradingDocument.seller?.eurBankName,
      value: getBankNumberAndName(
        tradingDocument.seller?.eurBankName,
        tradingDocument.seller?.eurBankAccountNumber,
      ),
    } as SelectProps["items"][number]);
  }
  if (tradingDocument.seller?.eurAlternativeBankName) {
    bankItems.push({
      type: MenuItemType.ICON,
      icon: countryCurrenciesFlags["EUR"],
      text: tradingDocument.seller?.eurAlternativeBankName,
      value: getBankNumberAndName(
        tradingDocument.seller?.eurAlternativeBankName,
        tradingDocument.seller?.eurAlternativeBankAccountNumber,
      ),
    } as SelectProps["items"][number]);
  }
  if (tradingDocument.seller?.gbpBankName) {
    bankItems.push({
      type: MenuItemType.ICON,
      icon: countryCurrenciesFlags["GBP"],
      text: tradingDocument.seller?.gbpBankName,
      value: getBankNumberAndName(
        tradingDocument.seller?.gbpBankName,
        tradingDocument.seller?.gbpBankAccountNumber,
      ),
    } as SelectProps["items"][number]);
  }
  return bankItems;
}

function getBankNumberAndName(bankName: string, bankNumber: string) {
  if (!bankName || !bankNumber) {
    return "";
  }
  return `${bankName};${bankNumber}`;
}
