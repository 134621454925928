import { WhEntryStatus as WhEntryStatusType } from "api/wh-entries/enums";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  status: WhEntryStatusType;
}

export const WhEntryStatus = ({ status }: Props) => {
  switch (status) {
    case WhEntryStatusType.CLOSED:
      return <Tag label="zamknięte" variant="success" />;
    case WhEntryStatusType.OPENED:
      return <Tag startIcon={MdiPoint} label="w trakcie" variant="info" />;
    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled status: ${exhaustiveCheck}`);
      return null;
  }
};
