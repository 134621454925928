import { createPaginatedKey } from "api/keys";

export const financialWarehouseStateKeys = {
  general: {
    list: createPaginatedKey("financialWarehouseStateGeneral"),
  },
  details: {
    list: createPaginatedKey("financialWarehouseStateDetails"),
  },
  forToday: (search: string) => ["financialWarehouseStateForToday", search],
  chartWarehouseValue: createPaginatedKey("chartWarehouseValue"),
};
