import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { whEntriesSummaryColumnFactory } from "pages/finances/shared/utils/factories/whEntriesSummaryColumnFactory";
import { WarehouseStateTab } from "pages/finances/shared/constants/financesConstants";

export const useWarehousesReturnsSummaryColumns = (tab: WarehouseStateTab) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    const columns = whEntriesSummaryColumnFactory(columnHelper, "returns");
    return [columns.period(tab), columns.value({ header: "wartość zwrotów [PLN]", size: 150 })];
  });
};
