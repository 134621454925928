import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiElte = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path d="M15.818 7.26953L13.9941 8.63741L16.0785 16.6167L17.7558 15.1348L15.818 7.26953Z" />
    <path d="M8.40858 1L6.69873 2.48187L13.9941 8.63731L15.7853 7.26943L8.40858 1Z" />
    <path d="M2.82383 3.39378L1 4.76166L6.69948 2.48187L8.40933 1L2.82383 3.39378Z" />
    <path d="M12.4 17.3006L10.5762 18.6684L16.1617 16.6166L17.7575 15.1348L12.4 17.3006Z" />
    <path d="M2.82383 3.39551L1 4.76338L3.08438 12.7427L4.76166 11.2608L2.82383 3.39551Z" />
    <path d="M17.5291 6.01534L15.8193 4.64746L8.52393 10.5749L10.2338 12.0568L17.5291 6.01534Z" />
    <path d="M21.1761 16.3864L19.4662 15.0186L12.1709 20.946L13.8808 22.4279L21.1761 16.3864Z" />
    <path d="M10.2324 12.0551L8.52257 10.5732L6.69873 18.6665L8.40858 20.0344L10.2324 12.0551Z" />
    <path d="M13.8801 22.4316L12.1702 20.9497L6.69873 18.6699L8.40858 20.2658L13.8801 22.4316Z" />
    <path d="M23.0002 8.18114L21.2904 6.69928L15.8188 4.64746L17.5287 6.01534L23.0002 8.18114Z" />
    <path d="M23 8.18304L21.2901 6.70117L19.4663 15.0224L21.1762 16.3903L23 8.18304Z" />
    <path d="M4.7616 11.2588L3.05176 12.7407L10.6076 18.6681L12.3989 17.3002L4.7616 11.2588Z" />
  </SvgWrapper>
);
