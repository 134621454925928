import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { useFilters } from "hooks/useFilters";
import { LineItemTable } from "./lineItemTable/LineItemTable";

export interface Filters {
  showAwaiting: boolean;
  showConnectedWithAnotherWhEntry: boolean;
  page: number;
  pageSize: number;
  search: string;
}

export type DocumentType = "RECEPTION" | "RELEASE";

interface Props {
  documentType: DocumentType;
}

export const LineItemSection = ({ documentType }: Props) => {
  const { filters, setFilter } = useFilters<Filters>({
    showAwaiting: false,
    showConnectedWithAnotherWhEntry: false,
    page: 1,
    pageSize: 15,
    search: "",
  });

  return (
    <div>
      <div className="mx-3 my-2 d-flex align-items-center gap-3">
        <TextField
          containerClassName="flex-1"
          endIcon={<MdiSearch color="neutralBlack48" size="18" />}
          onChange={event => {
            setFilter("search", event.target.value);
            setFilter("page", 1);
          }}
          placeholder="Szukaj..."
          value={filters.search ?? ""}
        />
        <Checkbox
          checked={filters.showConnectedWithAnotherWhEntry}
          label={documentType === "RECEPTION" ? "Pokaż z innych PZ" : "Pokaż z innych WZ"}
          onChange={showConnectedWithAnotherWhEntry => {
            setFilter("showConnectedWithAnotherWhEntry", showConnectedWithAnotherWhEntry);
            setFilter("page", 1);
          }}
          size="sm"
        />
        <Checkbox
          checked={filters.showAwaiting}
          label={documentType === "RECEPTION" ? "Pokaż oczekiwane" : "Pokaż niewydane"}
          onChange={showAwaiting => {
            setFilter("showAwaiting", showAwaiting);
            setFilter("page", 1);
          }}
          size="sm"
        />
      </div>
      <LineItemTable documentType={documentType} filters={filters} setFilter={setFilter} />
    </div>
  );
};
