import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiReleases = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      d="M4 19H6V10H18H20V8.35L12 5.15L4 8.35V19ZM2 21V7L12 3L22 7V12H16H8V21H2ZM9 21V19H11V21H9ZM11 18V16H13V18H11ZM13 21V19H15V21H13Z"
      fillOpacity="0.88"
    />
    <path
      d="M18 21L16.6 19.6L18.175 18H14V16H18.175L16.575 14.4L18 13L22 17L18 21Z"
      fillOpacity="0.88"
    />
  </SvgWrapper>
);
