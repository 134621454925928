import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { formatSubUnitsToDecimal } from "utilities";
import {
  WarehouseStateTab,
  financesConstants,
} from "pages/finances/shared/constants/financesConstants";
import { financesUtils } from "pages/finances/shared/utils/financesUtils";

export const useWarehousesFinancialStateSummaryColumns = (tab: WarehouseStateTab) => {
  return useCreateTableColumns<FinancialWarehouseStateSummaryPerPeriod>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.periodId, {
        header: tab === WarehouseStateTab.MONTHLY ? "miesiąc" : "kwartał",
        size: 70,
        cell: info => {
          const period = info.getValue();
          return (
            <Typography fontSize="12" fontWeight="700">
              {financesUtils.formatMonthAbbreviation(period)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 210,
        cell: info => {
          const warehouseState = info.getValue();
          if (financesUtils.isCurrentPeriod(warehouseState.periodId))
            return (
              <Tag.WithCustomColor
                backgroundColor="deepPurple75"
                label="Aktualny"
                textColor="deepPurple600"
              />
            );
          return financesConstants.getWarehousePeriodSummaryStatusTag(warehouseState.status);
        },
      }),
      columnHelper.amount(row => formatSubUnitsToDecimal(row.baselineValue + row.totalValue), {
        header: "wartość [PLN]",
        size: 185,
        amountDisplayProps: {
          noSeparator: true,
        },
      }),
      columnHelper.amount(row => formatSubUnitsToDecimal(row.estimatedValue), {
        header: "kwota wycen prognozowanych [PLN]",
        size: 195,
        amountDisplayProps: {
          integer: {
            fontSize: "14",
            fontWeight: "500",
          },
          noSeparator: true,
        },
      }),
      columnHelper.accessor(row => Number(row.estimatedPercent.toFixed(2)) * 100, {
        id: "percentageEstimation",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            % wyceny
          </Typography>
        ),
        size: 65,
        cell: info => {
          const percentageEstimation = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="14" fontWeight="400">
              {percentageEstimation}%
            </Typography>
          );
        },
      }),
    ];
  });
};
