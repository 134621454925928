import { tradingDocumentsActions } from "api/trading-documents/actions";
import { useTradingDocument } from "api/trading-documents/hooks";
import {
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
} from "api/trading-documents/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useStateModal, useToggle } from "hooks";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { CreateCorrectionManuallyModal } from "pages/tradingDocuments/salesInvoicesLIst/rightPanel/components/createCorrectionManuallyModal/CreateCorrectionManuallyModal";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { useDrawer } from "hooks/useDrawer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import styles from "../../../shared/TradingDocumentsLists.module.css";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { TradingDocumentOptionsMenuFactory } from "pages/tradingDocuments/shared/optionsMenuFactory/TradingDocumentOptionsMenuFactory";
import { TransportInfo } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/OrderMaterialModal";
import { DownloadTradingDocumentPdf } from "pages/tradingDocuments/shared/components/downloadTradingDocumentPdf/DownloadTradingDocumentPdf";

export const PanelHeader = () => {
  const { close } = useDrawer("advanceInvoices");
  const { query } = useQuery();
  const { panelId } = query;
  const { data: tradingDocument } = useTradingDocument({ id: panelId });
  assertIsDefined(tradingDocument);
  const optionsMenu = TradingDocumentOptionsMenuFactory({ close, tradingDocument });
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const { isTriggerElementHidden } = useScrollableContext();
  const createCorrectionManuallyModal = useStateModal<CreateCorrectionModalState | null>();
  const previewCorrectionMutation = tradingDocumentsActions.useCorrectionPreview(
    createCorrectionManuallyModal.open,
  );
  const transportModalController = useToggle();

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />
            <div>Faktura zaliczkowa&nbsp;{tradingDocument.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <ConfirmTradingDocument tradingDocument={tradingDocument} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1">
          {tradingDocument.status === "CONFIRMED" && (
            <Button
              className="text-uppercase mr-1"
              isLoading={previewCorrectionMutation.isLoading}
              onClick={() =>
                previewCorrectionMutation.mutate({
                  id: tradingDocument.id,
                  expectedPaymentForm: tradingDocument.expectedPaymentForm,
                })
              }
              size="small"
              startIcon={MdiAdd}
              variant="gray"
            >
              Utwórz korektę
            </Button>
          )}
          <DownloadTradingDocumentPdf tradingDocument={tradingDocument} />
          <Menu
            className={styles.optionsMenu}
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              optionsMenu.downloadRevisor(),
              optionsMenu.downloadFakir(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.downloadDuplicatePDF(),
              { type: MenuItemType.DIVIDER },
              optionsMenu.editTransportDocuments(transportModalController.open),
              { type: MenuItemType.DIVIDER },
              optionsMenu.tradingDocumentHistory(panelId),
              { type: MenuItemType.DIVIDER },
              optionsMenu.deleteTradingDocument(),
            ]}
          />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      <CreateCorrectionManuallyModal
        close={createCorrectionManuallyModal.close}
        isOpen={createCorrectionManuallyModal.isOpen}
        state={createCorrectionManuallyModal.state}
        type="ADVANCE"
      />
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
      {transportModalController.isOpen && (
        <TransportInfo close={transportModalController.close} tradingDocument={tradingDocument} />
      )}
    </>
  );
};
