import { InventoryCheckItem } from "api/wh-inventory-check/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag";

interface Props {
  finished: InventoryCheckItem["finished"];
}

export const InventoryCheckStatus = ({ finished }: Props) => {
  if (finished) return <Tag label="zakończona" variant="success" />;
  return <Tag label="W trakcie" startIcon={MdiPoint} variant="warning" />;
};
