import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { RightPanelSection } from "components/utils/drawer";
import { countryToFlagDict } from "constants/countriesFlags";
import { dateUtils } from "utilities";
import styles from "../RightPanel.module.css";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { SelectBankAccount } from "pages/tradingDocuments/shared/components/rightPanel/SelectBankAccount";

interface Props {
  tradingDocument: TradingDocument;
}

export const AmountSection = ({ tradingDocument }: Props) => {
  const pitTaxObligationDateMutation = usePatchTradingDocumentMutation();
  const updateVatObligationDateMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        {Boolean(tradingDocument.countryCode.length) ? (
          <img alt="Flaga kraju" src={countryToFlagDict[tradingDocument.countryCode]} />
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="wartość netto">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={tradingDocument.amountSummary.totalWithoutTax}
        />
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {tradingDocument.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        <FinanceAmountFormat
          decimalClassName="body-14-600"
          integerClassName="body-14-600"
          value={tradingDocument.amountSummary.totalTax}
        />
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {tradingDocument.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel
        title={`razem do ${tradingDocument.amountSummary.totalWithTax > 0 ? "zapłaty" : "zwrotu"}`}
      >
        <FinanceAmountFormat
          decimalClassName="body-18"
          integerClassName="body-18"
          value={Math.abs(tradingDocument.amountSummary.totalWithTax)}
        />
        <Typography color="neutralBlack48" fontSize="16" fontWeight="700">
          {tradingDocument.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="waluta">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.currency || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="Kurs wymiany">
        {tradingDocument.lastValidExchangeRate ? (
          <Typography fontSize="14" fontWeight="500">
            {tradingDocument.lastValidExchangeRate}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="Data wymiany">
        {tradingDocument.lastValidExchangeRateDate ? (
          <Typography fontSize="14" fontWeight="500">
            {tradingDocument.lastValidExchangeRateDate}
          </Typography>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
      <InfoLabel title="rejestr VAT">
        <DatePicker
          showMonthYearPicker
          look="common"
          onChange={date => {
            if (!date) {
              return updateVatObligationDateMutation.mutate({
                id: tradingDocument.id,
                vatTaxObligationDate: null,
              });
            }
            return updateVatObligationDateMutation.mutate({
              id: tradingDocument.id,
              vatTaxObligationDate: dateUtils.formatDateToIso(date),
            });
          }}
          customDateFormat="MM/yyyy"
          value={tradingDocument.vatTaxObligationDate ?? ""}
        />
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        <DatePicker
          disabled={pitTaxObligationDateMutation.isLoading}
          look="common"
          onChange={date => {
            if (!date) {
              return pitTaxObligationDateMutation.mutate({
                id: tradingDocument.id,
                pitTaxObligationDate: null,
              });
            }
            return pitTaxObligationDateMutation.mutate({
              id: tradingDocument.id,
              pitTaxObligationDate: dateUtils.formatDateToIso(date),
            });
          }}
          overwrites={{
            popup: { className: styles.datePickerPopup },
            input: { className: styles.datePickerInput },
            container: { className: styles.datePickerInput },
          }}
          value={tradingDocument.pitTaxObligationDate ?? ""}
        />
      </InfoLabel>
      <SelectBankAccount tradingDocument={tradingDocument} />
    </RightPanelSection>
  );
};
