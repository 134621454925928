import { ProductLightWithDeleted } from "api/products/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useProductsColumns = () => {
  return useCreateTableColumns<ProductLightWithDeleted>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 500,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 120,
        typographyProps: {
          fontSize: "14",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row.isSelfProduced, {
        header: "produkcja",
        size: 120,
        cell: info => {
          const isSelfProduced = info.getValue();
          return (
            <Typography
              className="text-uppercase"
              color={isSelfProduced ? "deepPurple500" : "lime400"}
              fontSize="10"
              fontWeight="700"
            >
              {isSelfProduced ? "Własna" : "Prod. zewnętrzna"}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "producent",
        size: 160,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.customer?.name, {
        header: "kontrahent",
        size: 160,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
    ];
  });
};
