import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { PartialOf } from "typeUtilities";
import { manufacturingNewKeys } from "./keys";
import {
  AddPreviousStageToManufacturingSettings,
  AddPreviousStageToManufacturingSettingsPreview,
  ManufacturingPlan,
  ManufacturingPlanListItem,
  ManufacturingSchema,
  PatchManufacturingStageColumnSet,
  VisiblePreviousStage,
  Settings,
  SettingsEmployee,
  PostStageSchemaAssignment,
  StageSchemaAssignment,
  PostEmployee,
  ManufacturingEmployee,
  PostStageEmployeeAssingment,
  PostStageEmployeeAssignmentPreview,
  ModifyStageSchemaAssignment,
} from "./models";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { fileFactoryUtils } from "utilities/fileFactory";

export const getManufacturingSchemas = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingSchema>> => ({
  key: manufacturingNewKeys.manufacturingSchemas(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/entries" + search,
    }),
});

export const patchManufacturingSchema = ({
  data,
  id,
}: {
  data: Partial<ManufacturingSchema>;
  id: string;
}) =>
  queryFetch<ManufacturingSchema>({
    method: "PATCH",
    url: "/manufacturing/schemas/entries/" + id,
    data,
  });

export const getManufacturingSchema = (id: string): ApiFetcher<ManufacturingSchema> => ({
  key: manufacturingNewKeys.manufacturingSchema(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/entries/" + id,
    }),
});

export const getManufacturingStages = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingStage>> => ({
  key: manufacturingNewKeys.manufacturingStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/stages" + search,
    }),
});

export const getManufacturingStage = (id: string): ApiFetcher<ManufacturingStage> => ({
  key: manufacturingNewKeys.manufacturingStage(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/stages/" + id,
    }),
});

export const postEmployee = (data: PostEmployee) =>
  queryFetch<ManufacturingEmployee>({
    method: "POST",
    url: "manufacturing/employees/items",
    data,
  });

export const deleteManufacturingStageSchemaAssignment = (id: string) =>
  queryFetch({
    method: "DELETE",
    url: "/manufacturing/schemas/schema-and-stages/" + id,
  });

export const patchManufacturingStageSchemaAssignment = ({
  id,
  newOrdersPositions,
  data,
}: {
  id: string;
  newOrdersPositions: StageSchemaAssignment[];
  data: Partial<ModifyStageSchemaAssignment>;
}) =>
  queryFetch<StageSchemaAssignment>({
    method: "PATCH",
    url: "/manufacturing/schemas/schema-and-stages/" + id,
    data,
  });

export const postStageSchemaAssignment = (data: PostStageSchemaAssignment) =>
  queryFetch<ManufacturingStage>({
    method: "POST",
    url: "/manufacturing/schemas/schema-and-stages",
    data,
  });

export const patchManufacturingStage = ({
  data,
  id,
}: {
  data: PatchManufacturingStageColumnSet;
  id: UUID;
}) =>
  queryFetch<ManufacturingStage>({
    method: "PATCH",
    url: "/manufacturing/schemas/stages/" + id,
    data,
  });

export const postManufacturingSettingsPreviousStage = (
  data: AddPreviousStageToManufacturingSettings,
) =>
  queryFetch<AddPreviousStageToManufacturingSettingsPreview>({
    method: "POST",
    url: "/manufacturing/settings/previous-stages",
    data,
  });

export const deleteManufacturingSettingsPreviousStage = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/settings/previous-stages/" + id,
  });

export const patchManufacturingSettingsPreviousStage = (
  data: PartialOf<VisiblePreviousStage>,
  id: UUID,
) =>
  queryFetch<ManufacturingStage>({
    method: "PATCH",
    url: "manufacturing/settings/previous-stages/" + id,
    data,
  });

export const deleteManufacturingStageEmployee = ({ id }: { id: UUID }) =>
  queryFetch({
    method: "DELETE",
    url: "/manufacturing/settings/employees/" + id,
  });

export const postManufacturingStageEmployee = (data: PostStageEmployeeAssingment) =>
  queryFetch<PostStageEmployeeAssignmentPreview>({
    method: "POST",
    url: "/manufacturing/settings/employees",
    data,
  });

export const patchManufacturingStageEmployee = ({
  data,
  id,
}: {
  data: Partial<SettingsEmployee>;
  id: UUID;
}) =>
  queryFetch<ManufacturingPlan>({
    method: "PATCH",
    url: "/manufacturing/settings/employees/" + id,
    data,
  });

export const deleteManufacturingStage = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/schemas/stages/" + id,
  });

export const getManufacturingPlans = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingPlanListItem>> => ({
  key: manufacturingNewKeys.manufacturingPlans(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/stage-production-plans/entries" + search,
    }),
});

export const patchManufacturingPlanOnList = (
  data: Partial<ManufacturingPlanListItem>,
  id: string,
) =>
  queryFetch<ManufacturingPlanListItem>({
    method: "PATCH",
    url: "/manufacturing/stage-production-plans/entries/" + id,
    data,
  });

export const getManufacturingPlan = (id: UUID): ApiFetcher<ManufacturingPlan> => ({
  key: manufacturingNewKeys.manufacturingPlan(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stage-production-plans/plan/${id}`,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

export const patchManufacturingStageDefaultSettings = ({
  data,
  id,
}: {
  data: Partial<Settings>;
  id: string;
}) =>
  queryFetch<ManufacturingPlanListItem>({
    method: "PATCH",
    url: "/manufacturing/settings/entries/" + id,
    data,
  });

export const manufacturingFileFactory = (() => {
  return {
    manufacturingItemPdf: (manufacturingItemIds: UUID[], signature: string) => ({
      url: `/manufacturing/manufacturing-items/get-labels?ids=${encodeURIComponent(
        manufacturingItemIds.join(","),
      )}`,
      name: `${fileFactoryUtils.formatSignature(signature)}-${fileFactoryUtils.getDate()}`,
    }),
    manufacturingMaterialOrders: (materialOrderId: UUID, signature: string) => ({
      url: `/manufacturing/orders/material-orders-labels?materialOrderId=${materialOrderId}`,
      name: `${fileFactoryUtils.formatSignature(signature)}-${fileFactoryUtils.getDate()}`,
    }),
  };
})();
