import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import {
  LineChart as RechartsLineChart,
  BarChart as RechartsBarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ChartComponents } from "./ChartComponents";

export const Chart = () => {};

export interface SingleLineChartData {
  config: {
    color?: ColorPalette;
    legendText: string;
  };
  data: {
    xAxisLabel: string;
    tooltipBodyRenderer: React.ReactNode;
    value: number;
  }[];
}

interface LineChartProps {
  data: SingleLineChartData[];
  className?: string;
}

const LineChart = ({ data, className }: LineChartProps) => {
  const formattedData = data[0].data.map((item, i) => {
    const point: any = {
      name: item.xAxisLabel,
    };

    data.forEach((line, lineIndex) => {
      point[`value${lineIndex + 1}`] = line.data[i].value;
      point["tooltipBodyRenderer"] = line.data[i].tooltipBodyRenderer;
    });

    return point;
  });

  return (
    <ResponsiveContainer width="100%" height="100%" className={className}>
      <RechartsLineChart
        data={formattedData}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} stroke={colorPalette.neutralBlack7} />
        <XAxis
          padding={{ left: 30, right: 30 }}
          dataKey="name"
          tick={<ChartComponents.CustomXTick />}
          tickLine={{ stroke: colorPalette.neutralBlack7 }}
          axisLine={{ stroke: colorPalette.neutralBlack7 }}
        />

        <YAxis
          domain={([dataMin, dataMax]) => {
            return [0, getMaxDomainValue(dataMax)];
          }}
          tickCount={10}
          axisLine={false}
          tickLine={false}
          tick={<ChartComponents.CustomYTick />}
        />
        <Tooltip content={<ChartComponents.CustomTooltip />} />

        <Legend
          content={
            <ChartComponents.CustomLegendContent
              data={data.map(line => ({
                color: line.config?.color || "deepPurple400",
                legendText: line.config?.legendText,
                kind: "line",
              }))}
            />
          }
        />

        {data.map((line, index) => (
          <Line
            key={`value${index + 1}`}
            dataKey={`value${index + 1}`}
            stroke={colorPalette[line.config?.color || "deepPurple400"]}
          />
        ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};

interface SingleBarChartData extends SingleLineChartData {}
interface BarChartProps {
  data: SingleBarChartData[];
  className?: string;
}

const BarChart = ({ data, className }: BarChartProps) => {
  const formattedData = data[0].data.map((item, i) => {
    const point: any = {
      name: item.xAxisLabel,
    };

    data.forEach((line, lineIndex) => {
      point[`value${lineIndex + 1}`] = line.data[i].value;
      point["tooltipBodyRenderer"] = line.data[i].tooltipBodyRenderer;
    });

    return point;
  });

  return (
    <ResponsiveContainer width="100%" height="100%" className={className}>
      <RechartsBarChart
        data={formattedData}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid vertical={false} stroke={colorPalette.neutralBlack7} />
        <XAxis
          padding={{ left: 10, right: 10 }}
          dataKey="name"
          tick={<ChartComponents.CustomXTick />}
          tickLine={{ stroke: colorPalette.neutralBlack7 }}
          axisLine={{ stroke: colorPalette.neutralBlack7 }}
        />
        <YAxis
          domain={([dataMin, dataMax]) => {
            return [0, getMaxDomainValue(dataMax)];
          }}
          tickCount={10}
          axisLine={false}
          tickLine={false}
          tick={<ChartComponents.CustomYTick />}
        />
        <Tooltip content={<ChartComponents.CustomTooltip />} />

        <Legend
          content={
            <ChartComponents.CustomLegendContent
              data={data.map(line => ({
                color: line.config?.color || "deepPurple400",
                legendText: line.config?.legendText,
                kind: "bar",
              }))}
            />
          }
        />

        {data.map((line, index) => (
          <Bar
            key={`value${index + 1}`}
            barSize={24}
            radius={[2, 2, 0, 0]}
            dataKey={`value${index + 1}`}
            fill={colorPalette[line.config?.color || "deepPurple400"]}
          />
        ))}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

Chart.BarChart = BarChart;
Chart.LineChart = LineChart;

function getMaxDomainValue(maxValue: number) {
  return Math.round(Math.abs(maxValue) * 1.3);
}
