import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { MdiReceptions } from "components/miloDesignSystem/atoms/icons/MdiReceptions";
import { Paper } from "components/miloDesignSystem/atoms/paper";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useNavigate } from "hooks/useNavigate";
import { queryString } from "utilities";
import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { useMemo } from "react";
import { EmptySummarySection } from "../../emptySummarySection/EmptySummarySection";
import receptionsIcon from "assets/images/receptionsAlt.svg";
import { useSingleWarehouseReceptionsSummaryColumns } from "./useSingleWarehouseReceptionsSummaryColumns";
import { useWarehousesReceptionsSummaryColumns } from "./useWarehousesReceptionsSummaryColumns";
import { WarehouseStateTab } from "pages/finances/shared/constants/financesConstants";

interface Props {
  tab: WarehouseStateTab;
  warehouse?: string;
}

export const ReceptionsSummary = ({ tab, warehouse }: Props) => {
  const navigate = useNavigate();
  const search = queryString.stringify({
    pageSize: 6,
    warehouseId: warehouse ? warehouse : "",
  });
  const {
    data: periodsDetails,
    error,
    isLoading,
    isPreviousData,
  } = financialWarehouseStateActions.useFinancialWarehouseStateDetails(search);
  const normalizedPeriodDetails: NormalizedFinancialWarehouseStateDetails[] = useMemo(() => {
    return periodsDetails.map(period => ({ ...period, id: period.periodId }));
  }, [periodsDetails]);
  const columnsPerWarehouse = useSingleWarehouseReceptionsSummaryColumns(tab);
  const columns = useWarehousesReceptionsSummaryColumns(tab);
  const tableProps = useTableFeatureConnector({
    rows: normalizedPeriodDetails,
  });

  return (
    <Paper className="pt-2 pb-3 overflow-hidden flex-1">
      <div className="d-flex flex-column">
        <div className="pt-3 pb-2 px-3 gap-2 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <IconRenderer color="teal200" icon={MdiReceptions} size={16} />
            <Typography fontSize="16" fontWeight="600">
              Przyjęcia
            </Typography>
          </div>
          <Button
            className="text-uppercase"
            endIcon={MdiArrowForward}
            onClick={() => navigate("/finances/receptions/list/all")}
            size="small"
            variant="transparent"
          >
            Lista przyjęć
          </Button>
        </div>
        <Table<NormalizedFinancialWarehouseStateDetails>
          columns={warehouse ? columnsPerWarehouse : columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={{
            cell: { height: "36" },
            header: { backgroundColor: "neutralWhite100" },
          }}
          {...tableProps}
          overrides={() => ({
            ...tableProps?.overrides?.(),
            noResultComponent: (
              <EmptySummarySection icon={receptionsIcon} title="Brak danych dotyczących Przjęć" />
            ),
          })}
        />
      </div>
    </Paper>
  );
};
