import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  PatchProductAttributeResponse,
  Product,
  ProductLightWithDeleted,
  RightPanelProductAttribute,
} from "./models";
import { ProductEntity } from "./models";
import { Pagination } from "api/types";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";
import { productsKeys } from "./keys";

const getProductIndexesPrice = (search: string): ApiFetcher<Record<number, string>> => ({
  key: productsKeys.productIndexesPrice(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/get-product-indexes-price" + search,
    }),
});

const getProductIndexes = (search: string): ApiFetcher<Record<string, number>> => ({
  key: productsKeys.productIndexes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/get-product-indexes" + search,
    }),
});

export const getProductEntity = (id: number | string): ApiFetcher<ProductEntity> => ({
  key: productsKeys.productEntity(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/separated/" + id,
    }),
});

const getProductsLightWithDeleted = (
  search: string = "",
): ApiFetcher<Pagination<ProductLightWithDeleted>> => ({
  key: productsKeys.productsWithDeleted.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/light-with-deleted" + search,
    }),
});

const getProduct = ({ id, search }: { id: string; search?: string }): ApiFetcher<Product> => ({
  key: productsKeys.products.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/items/" + id + search,
    }),
});

const patchProduct = (data: Assign<Partial<Product>, { id: Product["id"] }>) =>
  queryFetch<Product>({
    method: "PATCH",
    url: "/products/items/" + data.id,
    data: parsePatchData(data),
  });

const getProductAttributes = (
  search: string = "",
): ApiFetcher<Pagination<RightPanelProductAttribute>> => ({
  key: productsKeys.productAttributes.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/products/product-attributes" + search,
    }),
});

const patchProductAttribute = (
  data: Assign<Partial<RightPanelProductAttribute>, { id: RightPanelProductAttribute["id"] }>,
) =>
  queryFetch<PatchProductAttributeResponse>({
    method: "PATCH",
    url: "/products/product-attributes/" + data.id,
    data: parsePatchData(data),
  });

export const productsApi = {
  getProduct,
  getProductAttributes,
  getProductIndexesPrice,
  getProductIndexes,
  getProductEntity,
  getProductsLightWithDeleted,
  patchProduct,
  patchProductAttribute,
};
