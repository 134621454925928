import { Route } from "components/utils";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import { InventoryChecks } from "pages/finances/inventoryChecks/InventoryChecks";
import { NotFound } from "pages/notFound";
import { InventoryCheckSummary } from "pages/finances/inventoryChecks/inventoryCheckSummary/InventoryCheckSummary";

export const InventoryChecksRouting = ({ match }: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={InventoryChecks}
        exact
        path={`${match.path}/list/:tab`}
        title="Milo - inwentaryzacje"
      />
      <Route
        component={InventoryCheckSummary}
        exact
        path={`${match.path}/:inventoryCheckId`}
        title="Milo - szczegóły inwentaryzacji"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
