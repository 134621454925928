import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { whEntriesSummaryColumnFactory } from "pages/finances/shared/utils/factories/whEntriesSummaryColumnFactory";
import { WarehouseStateTab } from "pages/finances/shared/constants/financesConstants";

export const useWarehousesReceptionsSummaryColumns = (tab: WarehouseStateTab) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    const columns = whEntriesSummaryColumnFactory(columnHelper, "receptions");
    return [
      columns.period(tab),
      columns.value({ header: "wartość przyjęć [PLN]", size: 150 }),
      columnHelper.stretchContent,
      columns.estimationValue({ size: 150 }),
      columns.percentageEstimation(),
    ];
  });
};
