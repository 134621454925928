import { useMutation } from "hooks/useMutation";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { stageProductionPlansApi } from "./api";
import { ManufacturingPlan } from "api/manufacturingNew/models";
import { getAnyErrorKey } from "utilities";

const useRemoveStageProductionPlan = ({
  close,
  stageProductionPlan,
}: {
  close: () => void;
  stageProductionPlan: ManufacturingPlan;
}) => {
  return withDeleteConfirmation(
    useMutation(stageProductionPlansApi.deleteManufacturingPlan, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto plan "${stageProductionPlan.signature}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    `Czy na pewno chcesz usunąć plan ${stageProductionPlan.signature} ${stageProductionPlan.name &&
      `(${stageProductionPlan.name})`}?`,
  )();
};

export const stageProductionPlansActions = {
  useRemoveStageProductionPlan,
};
