import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery, useToggle } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import { Button } from "components/common";
import historyIcon from "assets/images/history.svg";
import { MarkRouteReadyBtn } from "./markRouteReady/MarkRouteReadyBtn";
import { MarkRouteReadyCheckbox } from "./markRouteReady/MarkRouteReadyCheckbox";
import { Link } from "react-router-dom";
import { Button as ButtonPlaceholder } from "components/miloDesignSystem/atoms/button";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMapAlt } from "components/miloDesignSystem/atoms/icons/MdiMapAlt";
import { MdiRequestQuote } from "components/miloDesignSystem/atoms/icons/MdiRequestQuote";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "../../../../../components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { MdiEdit } from "components/miloDesignSystem/atoms/icons/MdiEdit";
import { DEFAULT_OPTION_MENU_ICON_SIZE } from "CONSTANTS";
import { useNavigate } from "hooks/useNavigate";
import requestIcon from "assets/images/request.svg";
import { IndexFulfillmentModal } from "components/common/indexFulfillmentModal/IndexFulfillmentModal";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useDeleteRoute } from "../../hooks/useDeleteRoute";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";

interface Props {
  close: () => void;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanelHeader = ({ close, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: route } = useRoute(panelId);
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();
  const indexFulfillmentModal = useToggle();
  assertIsDefined(route);
  const deleteRoute = useDeleteRoute({ route });

  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2">
            <MarkRouteReadyCheckbox route={route} setShowLoader={setShowLoader} />
            <div>{route.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <MarkRouteReadyBtn route={route} setShowLoader={setShowLoader} />
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 position-relative">
          <ButtonPlaceholder size="small" variant="gray">
            Zatwierdź faktury
          </ButtonPlaceholder>
          <Tooltip title="Podgląd PDF faktur">
            <IconButton icon={MdiRequestQuote} variant="transparent" />
          </Tooltip>
          <Tooltip title="Dokumenty do pobrania">
            <IconButton icon={MdiDownloadFile} variant="transparent" />
          </Tooltip>
          <Tooltip title="Historia trasy">
            <Button
              as={Link}
              kind="transparent-black"
              size="square-s"
              to={{
                pathname: `/logistics/routes/${panelId}/history`,
                state: { route },
              }}
            >
              <div className="btnBase btnBase16 btnBaseSmall">
                <img alt="Historia" src={historyIcon} />
              </div>
            </Button>
          </Tooltip>
          <span className="divider line-divider" />
          <Tooltip title="Edytuj trasę">
            <IconButton icon={MdiMapAlt} variant="transparent" />
          </Tooltip>
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.ICON,
                icon: <MdiEdit size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />,
                onClick: () => {
                  navigate(
                    route.status === "SETTLED"
                      ? `/routes/creator/${route.id}/archive`
                      : `/routes/creator/${route.id}`,
                  );
                },
                text: "Edytuj",
              },
              {
                type: MenuItemType.ICON,
                icon: requestIcon,
                onClick: () => indexFulfillmentModal.open(),
                text: "Pokaż zapotrzebowanie",
              },
              {
                type: MenuItemType.ICON,
                icon: deleteRoute.isLoading ? (
                  <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
                ) : (
                  <MdiDelete color="danger600" size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
                ),
                onClick: () => deleteRoute.mutate(route.id),
                options: {
                  color: "danger600",
                  disabled:
                    deleteRoute.isLoading ||
                    route.status === "DEPARTED" ||
                    route.status === "FINISHED" ||
                    route.status === "SETTLED",
                },
                text: "Usuń",
              },
            ]}
          />
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {indexFulfillmentModal.isOpen && (
        <IndexFulfillmentModal close={indexFulfillmentModal.close} id={route.id} source="route" />
      )}
    </>
  );
};
