import { InventoryCheckItem } from "api/wh-inventory-check/models";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { CommonError } from "components/utils";
import { ErrorType } from "hooks/createApiQuery";
import { dateUtils, formatSubUnitsToDecimal } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { InventoryCheckStatus } from "../../components/InventoryCheckStatus";

interface Props {
  error: ErrorType | null;
  inventoryCheck: InventoryCheckItem | null;
  isLoading: boolean;
}

export const GeneralInfoSection = ({ error, inventoryCheck, isLoading }: Props) => {
  if (error)
    return (
      <div className="p-3 d-flex align-items-center gap-3">
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (isLoading)
    return (
      <div className="p-3 d-flex align-items-center gap-3">
        <Spinner size={30} />
      </div>
    );

  if (!inventoryCheck) return null;

  return (
    <div className="p-3 d-flex align-items-center gap-3">
      <div className="d-flex align-items-center gap-2">
        <Typography fontSize="14" fontWeight="400">
          Zmiana wartości magazynu:
        </Typography>
        {inventoryCheck.difference ? (
          <AmountDisplay
            amount={formatSubUnitsToDecimal(inventoryCheck.difference)}
            currency="PLN"
            integerTypographyProps={{
              fontSize: "18",
              fontWeight: "700",
            }}
          />
        ) : (
          <EmptyValue fontSize="18" />
        )}
      </div>
      <span className="divider line-divider" />
      <InventoryCheckStatus finished={inventoryCheck.finished} />
      <div className="d-flex align-items-center gap-1">
        <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
          rozpoczęto:
        </Typography>
        <Typography fontSize="12" fontWeight="600">
          {dateUtils.formatDateToDisplay(inventoryCheck.created)}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-1">
        <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
          zakończono:
        </Typography>
        <Typography fontSize="12" fontWeight="600">
          {inventoryCheck.finished
            ? dateUtils.formatDateToDisplay(inventoryCheck.finished)
            : EMPTY_VALUE}
        </Typography>
      </div>
    </div>
  );
};
