import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel } from "components/utils/drawer";
import { useQuery } from "hooks";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { TitleSection } from "./components/titleSection/TitleSection";
import { productsActions } from "api/products/actions";
import { queryString } from "utilities";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const search = queryString.stringify({ showDeleted: true });
  const { data: product, error, isLoading } = productsActions.useProduct(
    {
      id: panelId,
      search,
    },
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!product) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={product.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <TitleSection product={product} />
          <GeneralInfoSection product={product} />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
