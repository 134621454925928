import { QueryParams } from "api/types";
import { queryString } from "utilities";
import { ProductsTab } from "../constants/productsConstants";

const getProductsListSearch = ({
  query,
  tab,
}: {
  query: QueryParams;
  tab: ProductsTab;
}): string => {
  return queryString.stringify({
    ...query,
    isDeleted: tab === ProductsTab.CANCELLED ? "true" : "",
    isSelfProduced:
      tab === ProductsTab.SELF_PRODUCED
        ? "true"
        : tab === ProductsTab.EXTERNAL_PRODUCED
        ? "false"
        : "",
  });
};

export const productsUtils = {
  getProductsListSearch,
};
