import { bankAccountsActions } from "api/bank-accounts/actions";
import { BankAccount } from "api/bank-accounts/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { useState } from "react";
import { dateUtils } from "utilities";

interface Props {
  bankAccount: BankAccount;
  close: () => void;
}

export const DownloadTransactionsModal = ({ bankAccount, close }: Props) => {
  const [lastTransactionDate, setLastTransactionDate] = useState<string | null>(null);
  const transactionsMutation = bankAccountsActions.useGetTransactions();

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Pobierz transakcje
        </Typography>
      }
      width={480}
    >
      <div className="px-3 d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-1">
          <Typography
            className="text-uppercase"
            color="neutralBlack48"
            fontSize="10"
            fontWeight="700"
          >
            Od dnia (opcjonalne):
          </Typography>
          <DatePicker
            isNullable
            onChange={date => {
              if (!date) return setLastTransactionDate(null);
              return setLastTransactionDate(dateUtils.formatDateToIso(date));
            }}
            value={lastTransactionDate}
          />
        </div>
        <div className="d-flex align-items-center gap-2 pt-2 pb-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            isLoading={transactionsMutation.isLoading}
            onClick={() =>
              lastTransactionDate
                ? transactionsMutation.mutate({
                    bankAccountId: bankAccount.id,
                    lastTransactionDate,
                  })
                : transactionsMutation.mutate({
                    bankAccountId: bankAccount.id,
                  })
            }
            size="medium"
            variant="deepPurple"
          >
            Pobierz {!lastTransactionDate && "od daty ostatniej transakcji"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
