import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { DocumentType, Filters } from "../LineItemSection";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { MdiArrowOutward } from "components/miloDesignSystem/atoms/icons/MdiArrowOutward";
import { Link } from "components/miloDesignSystem/atoms/link";
import { formatSubUnitsToDecimal, queryString } from "utilities";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { financialPackagesFileFactory } from "api/wms/financial-packages/fileFactory";
import { LineItem } from "api/wh-entries/models";
import { LineItemKind } from "api/wh-entries/enums";

export interface NormalizedLineItem
  extends Pick<LineItem, "id" | "kind" | "name" | "code" | "numberOfPackages" | "amount"> {
  order: LineItem["order"] | null;
  numberOfScannedPackagesGroups: LineItem["numberOfScannedPackagesGroups"] | null;
  connectedWithAnotherWhEntry: LineItem["packages"][number]["connectedWithAnotherWhEntry"];
  subRows: NormalizedLineItem[];
}

export const useLineItemColumns = (filters: Filters, documentType: DocumentType) => {
  return useCreateTableColumns<NormalizedLineItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "produkty / paczka",
        size: 174,
        cell: info => {
          const value = info.getValue();
          const isNested = info.row.depth;

          return (
            <div className="d-flex flex-1 align-items-center gap-2 overflow-hidden">
              {value.kind === LineItemKind.PACKAGE || isNested ? (
                <MdiPackage2 color="yellow400" size="12" />
              ) : (
                <MdiChair color="magenta300" size="12" />
              )}
              <Typography fontSize="12" fontWeight={isNested ? "400" : "600"} noWrap>
                {value.name}
              </Typography>
            </div>
          );
        },
      }),

      columnHelper.expandable(),

      columnHelper.text(row => row.code, {
        header: "kod (EAN / kod paczki)",
        size: 140,
        typographyProps: {
          fontSize: "10",
          fontWeight: "600",
        },
      }),

      columnHelper.accessor(row => row, {
        id: "numberOfScannedPackagesGroups",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            l. mebli
          </Typography>
        ),
        size: 60,
        cell: info => {
          const row = info.getValue();
          if (!row.numberOfScannedPackagesGroups) {
            return (
              <div className="d-flex align-items-center justify-content-end flex-1">
                <EmptyValue fontWeight="600" color="neutralBlack48" fontSize="12" />
              </div>
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-end flex-1">
              <Typography fontSize="12" fontWeight="700" color="success500">
                {row.numberOfScannedPackagesGroups.received}
              </Typography>
              <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                /{row.numberOfScannedPackagesGroups.total}
              </Typography>
              <Typography fontSize="10" fontWeight="500" className="ml-1" color="neutralBlack48">
                szt.
              </Typography>
            </div>
          );
        },
      }),

      columnHelper.accessor(row => row, {
        id: "numberOfPackages",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            l. paczek
          </Typography>
        ),
        size: 60,
        cell: info => {
          const row = info.getValue();
          if (!row.numberOfPackages) {
            return (
              <div className="d-flex align-items-center justify-content-end flex-1">
                <EmptyValue fontWeight="600" color="neutralBlack48" fontSize="12" />
              </div>
            );
          }
          return (
            <div className="d-flex align-items-center justify-content-end flex-1">
              <Typography fontSize="12" fontWeight="700" color="success500">
                {row.numberOfPackages.received}
              </Typography>
              <Typography fontSize="12" fontWeight="600" color="neutralBlack48">
                /{row.numberOfPackages.total}
              </Typography>
              <Typography fontSize="10" fontWeight="500" className="ml-1" color="neutralBlack48">
                szt.
              </Typography>
            </div>
          );
        },
      }),

      columnHelper.amount(row => (row.amount ? formatSubUnitsToDecimal(row.amount) : 0), {
        header: "wartość zakupu",
        size: 90,
        amountDisplayProps: {
          currency: () => "PLN",
          emptyValueRenderer: value => value === null && EMPTY_VALUE,
        },
      }),

      columnHelper.accessor(row => row, {
        header: documentType === "RECEPTION" ? "zamówienie" : "w innym WZ",
        size: 90,
        cell: info => {
          const lineItem = info.getValue();

          if (
            filters.showConnectedWithAnotherWhEntry &&
            lineItem.subRows.some(subRow => subRow.connectedWithAnotherWhEntry.length > 0)
          ) {
            const connectedWithAnotherWhEntry = lineItem.subRows.flatMap(subRow =>
              subRow.connectedWithAnotherWhEntry.map(
                receivedInAnotherEntry => receivedInAnotherEntry,
              ),
            );

            return (
              <div className="d-flex align-items-center gap-1">
                <IconRenderer color="neutralBlack100" icon={MdiArrowOutward} size={14} />
                <Link
                  fontSize="10"
                  fontWeight="500"
                  to={`/finances/${
                    documentType === "RECEPTION" ? "receptions" : "releases"
                  }/list/all?panelId=${connectedWithAnotherWhEntry[0].id}`}
                >
                  {connectedWithAnotherWhEntry[0].signature}
                </Link>
              </div>
            );
          }

          if (lineItem.order)
            return (
              <Link
                fontSize="10"
                fontWeight="500"
                to={`/orders/list/active/all?panelId=${lineItem.order.id}`}
              >
                {lineItem.order.signature}
              </Link>
            );

          return (
            <Typography color="neutralBlack48" fontSize="10" fontWeight="500">
              (na magazyn)
            </Typography>
          );
        },
      }),

      columnHelper.accessor(row => row, {
        id: "LABEL_PREVIEW",
        header: "",
        size: 30,
        cell: info => {
          const lineItem = info.getValue();
          const isNested = info.row.depth;

          if (isNested) {
            return (
              <FileDownloadHandler
                factoryFn={() =>
                  financialPackagesFileFactory.whEntryPackagesLabel(
                    queryString.stringify({
                      packageId: lineItem.code,
                    }),
                  )
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <IconButton
                    icon={MdiQrCode}
                    isLoading={isLoading}
                    onClick={download}
                    variant="transparent"
                  />
                )}
              </FileDownloadHandler>
            );
          }

          return (
            <FileDownloadHandler
              factoryFn={() =>
                financialPackagesFileFactory.whEntryPackagesLabel(
                  queryString.stringify({
                    packageGroupId: lineItem.id,
                  }),
                )
              }
              type="pdf"
            >
              {({ download, isLoading }) => (
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={download}
                  variant="transparent"
                />
              )}
            </FileDownloadHandler>
          );
        },
      }),
    ];
  });
};
