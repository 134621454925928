import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useTradingDocumentsColumns } from "./useTradingDocumentsColumns";
import { RightPanelSection } from "components/utils/drawer";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { ReleaseDetails } from "api/wh-entries/models";

interface Props {
  title: string;
  tradingDocuments: ReleaseDetails["tradingDocuments"];
}

export const TradingDocumentSection = ({ title, tradingDocuments }: Props) => {
  const tableProps = useTableFeatureConnector({
    rows: tradingDocuments,
  });
  const columns = useTradingDocumentsColumns();

  return (
    <RightPanelSection title={title}>
      <Table<ReleaseDetails["tradingDocuments"][number]>
        columns={columns}
        error={null}
        isLoading={false}
        uiSchema={{
          cell: { height: "28" },
          header: { backgroundColor: "neutralWhite100" },
        }}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          hideHeader: !tradingDocuments.length,
          noResultComponent: <EmptySection label="Brak faktur" />,
        })}
      />
    </RightPanelSection>
  );
};
