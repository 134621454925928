import Chance from "chance";
import { dateUtils } from "./utilities";
import { getYear } from "date-fns";
import { UserWithInitials } from "api/users/models";

type CollectionType =
  | "ROUTE"
  | "ORDER_GROUP"
  | "ORDER"
  | "RELEASES"
  | "SALES_INVOICE"
  | "RETURNS"
  | "EXPENDITURES"
  | "RECEPTIONS"
  | "EXTERNAL_MANUFACTURING_ORDER"
  | "INVENTORY_CHECK"
  | "CORRECTION";

const chance = new Chance();

const getRandomSignature = ({ collectionType }: { collectionType?: CollectionType }) => {
  return `${
    collectionType
      ? getCollectionPrefix(collectionType)
      : `${chance.letter({ casing: "upper" })}${chance.letter({ casing: "upper" })}`
  } ${chance.natural({
    min: 1,
    max: 9999,
  })}/${chance.natural({ min: 1, max: 12 })}/${getYear(new Date())}/${chance.letter({
    casing: "upper",
  })}`;
};

const getNumberFromRange = ({ max, min }: { min: number; max: number }) => {
  return chance.natural({ min, max });
};

const getRandomStringOfGivenLength = ({ length }: { length: number }) => {
  return chance.string({ length });
};

const getCollectionPrefix = (collectionType: CollectionType): string => {
  switch (collectionType) {
    case "CORRECTION":
      return "FK";
    case "EXPENDITURES":
      return "RW";
    case "EXTERNAL_MANUFACTURING_ORDER":
      return "ZDP";
    case "ORDER":
      return "ZM";
    case "ORDER_GROUP":
      return "GR";
    case "RECEPTIONS":
      return "PZ";
    case "RELEASES":
      return "WZ";
    case "RETURNS":
      return "ZWR";
    case "ROUTE":
      return "TR";
    case "INVENTORY_CHECK":
      return "IN";
    case "SALES_INVOICE":
      return "FS";
    default:
      const exhaustiveCheck: never = collectionType;
      console.error(`Unhandled collection type: ${exhaustiveCheck}`);
      return `${chance.letter({ casing: "upper" })}${chance.letter({ casing: "upper" })}`;
  }
};

export const MG = {
  selectRandomFromArray: <T,>(array: T[]): T =>
    array[chance.natural({ min: 0, max: array.length - 1 })],
  isoDateTime: () => chance.date({ year: getYear(new Date()) }).toString(),
  isoDate: () => dateUtils.formatDateToIso(chance.date({ year: getYear(new Date()) })),
  isoTime: () => dateUtils.formatDateToTime(chance.date({ year: getYear(new Date()) })),
  companyName: () => chance.company(),
  currency: () => chance.currency().code,
  /**
   * Probability of returning value
   * @param {number} probability
   * @default 70
   */
  optionalField: <T,>(value: T, probability?: number) =>
    chance.bool({ likelihood: probability ?? 70 }) ? value : null,
  decimal: () => chance.floating({ fixed: 2, min: 0, max: 100000000 }).toString(),
  id: () => chance.natural({ min: 1, max: 100000 }),
  number: getNumberFromRange,
  string: getRandomStringOfGivenLength,
  uuid: () => chance.guid(),
  userWithInitials: () =>
    (({
      id: (type?: "uuid" | "number") =>
        type === "uuid" ? chance.guid() : chance.natural({ min: 1 }),
      avatar: chance.avatar(),
      firstName: chance.first(),
      lastName: chance.last(),
      initialsBackgroundColor: "neutralBlack100",
      initialsTextColor: "neutralWhite100",
    } as unknown) as UserWithInitials),
  phone: () => chance.phone(),
  signature: getRandomSignature,
};
