import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery } from "hooks";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { dateFns, formatSubUnitsToDecimal } from "utilities";
import { pl } from "date-fns/locale";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { WarehouseStateTab } from "../../constants/financesConstants";
import { financesUtils } from "../../utils/financesUtils";

interface Props {
  tab: WarehouseStateTab;
  warehouse?: string;
}

export const WarehouseCurrentState = ({ tab, warehouse }: Props) => {
  const { query } = useQuery();
  const search = financesUtils.getWarehouseStateSearch({
    query,
    tab,
    filters: {
      warehouseId: warehouse ? warehouse.toString() : "",
    },
  });
  const {
    data: latestState,
    error,
    isLoading,
  } = financialWarehouseStateActions.useFinancialWarehouseStateForToday({
    search,
  });

  if (isLoading)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <Spinner size={20} />
        </div>
      </div>
    );

  if (error)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );

  if (!latestState) return null;

  return (
    <div className="pt-2 pb-3">
      <div className="pt-1 pr-2 pl-3 d-flex flex-column">
        <div className="d-flex align-items-center gap-1">
          <Typography fontSize="14" fontWeight="400">
            Stan na {dateFns.format(new Date(), "d MMM yyyy", { locale: pl })}
          </Typography>
          <Typography className="italic" color="deepPurple400" fontSize="10" fontWeight="600">
            (dzisiaj)
          </Typography>
        </div>
        <AmountDisplay
          amount={formatSubUnitsToDecimal(latestState.baselineValue + latestState.totalValue)}
          currency="PLN"
          decimalTypographyProps={{
            fontSize: "14",
            fontWeight: "500",
          }}
          integerTypographyProps={{
            fontSize: "32",
            fontWeight: "700",
          }}
          noSeparator
        />
        <div className="d-flex align-items-center gap-2">
          <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
            w tym kwota prognozowana:
          </Typography>
          <AmountDisplay
            amount={formatSubUnitsToDecimal(latestState.estimatedValue)}
            currency="PLN"
            decimalTypographyProps={{
              fontSize: "10",
              fontWeight: "500",
            }}
            integerTypographyProps={{
              fontSize: "12",
              fontWeight: "600",
            }}
            noSeparator
          />
        </div>
        {/* TODO: Link do paczek bez wyceny */}
      </div>
    </div>
  );
};
