import { bankAccountsActions } from "api/bank-accounts/actions";
import { postBankAccount } from "api/bank-accounts/calls";
import { Header } from "components/common";
import { Button } from "components/common/buttonLegacy";
import { CommonError, Spinner } from "components/utils";
import { useQuery } from "hooks";
import { useMutation } from "hooks/useMutation";
import { useNavigate } from "hooks/useNavigate";
import { useEffect } from "react";
import { getAnyErrorKey } from "utilities";

export const Create = () => {
  const { query } = useQuery();
  const { ref, error, bankAccountId } = query;
  const navigate = useNavigate();
  const refreshBankAccountMutation = bankAccountsActions.useRefreshBankAccount();

  const postBankAccountMutation = useMutation(postBankAccount, {
    onSuccess: () => navigate("/bank-accounts/list"),
  });

  useEffect(() => {
    if (bankAccountId) {
      return refreshBankAccountMutation.mutate({
        bankAccountId,
        referenceId: ref,
      });
    }
    postBankAccountMutation.mutate({ referenceId: ref });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (postBankAccountMutation.error || error) {
    return (
      <div className="h-100">
        <Header title={bankAccountId ? "Odnów zgody" : "Dodaj konto bankowe"} />
        <CommonError
          text={
            <div className="d-flex justify-content-center align-items-center flex-column ">
              {error && <div>Wystąpił błąd</div>}
              {postBankAccountMutation.error && (
                <div>{getAnyErrorKey(postBankAccountMutation.error)}</div>
              )}
              <Button
                kind="primary"
                className="mt-2"
                onClick={() => navigate("/bank-accounts/list")}
              >
                Wróć do listy kont bankowych
              </Button>
            </div>
          }
        />
      </div>
    );
  }

  return (
    <div>
      <Header title={bankAccountId ? "Odnów zgody" : "Dodaj konto bankowe"} />
      <Spinner color="blue" size="big" text="trwa wczytywanie" position="absolute" />
    </div>
  );
};
