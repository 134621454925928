import { Pagination, UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { whEntriesKeys } from "./keys";
import {
  WhEntriesSummary,
  ExpenditureDetails,
  ExpenditureListItem,
  ReceptionDetails,
  ReceptionListItem,
  ReleaseDetails,
  ReleaseListItem,
  ReturnDetails,
  ReturnListItem,
  LineItem,
} from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { WhEntryStatus } from "./enums";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const getReceptions = (search: string = ""): ApiFetcher<Pagination<ReceptionListItem>> => ({
  key: whEntriesKeys.receptions.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/receptions" + search,
    }),
});

const getReception = (id: ReceptionDetails["id"]): ApiFetcher<ReceptionDetails> => ({
  key: whEntriesKeys.receptions.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/receptions/" + id,
    }),
});

const patchReception = (data: Assign<Partial<ReceptionDetails>, { id: ReceptionDetails["id"] }>) =>
  queryFetch<ReceptionDetails>({
    method: "PATCH",
    url: "/wh-entries/receptions/" + data.id,
    data: parsePatchData(data),
  });

const getReleases = (search: string = ""): ApiFetcher<Pagination<ReleaseListItem>> => ({
  key: whEntriesKeys.releases.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/releases" + search,
    }),
});

const getRelease = (id: ReleaseDetails["id"]): ApiFetcher<ReleaseDetails> => ({
  key: whEntriesKeys.releases.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/releases/" + id,
    }),
});

const getReturns = (search: string = ""): ApiFetcher<Pagination<ReturnListItem>> => ({
  key: whEntriesKeys.returns.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/returns" + search,
    }),
});

const getReturn = (id: ReturnDetails["id"]): ApiFetcher<ReturnDetails> => ({
  key: whEntriesKeys.returns.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/returns/" + id,
    }),
});

const patchReturn = (data: Assign<Partial<ReturnDetails>, { id: ReturnDetails["id"] }>) =>
  queryFetch<ReturnDetails>({
    method: "PATCH",
    url: "/wh-entries/returns/" + data.id,
    data: parsePatchData(data),
  });

const getExpenditures = (search: string = ""): ApiFetcher<Pagination<ExpenditureListItem>> => ({
  key: whEntriesKeys.expenditures.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/expenditures" + search,
    }),
});

const getExpenditure = (id: ExpenditureDetails["id"]): ApiFetcher<ExpenditureDetails> => ({
  key: whEntriesKeys.expenditures.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/expenditures/" + id,
    }),
});

const patchExpenditure = (
  data: Assign<Partial<ExpenditureDetails>, { id: ExpenditureDetails["id"] }>,
) =>
  queryFetch<ExpenditureDetails>({
    method: "PATCH",
    url: "/wh-entries/expenditures/" + data.id,
    data: parsePatchData(data),
  });

const getDocumentsSummary = (search: string = ""): ApiFetcher<Pagination<WhEntriesSummary>> => ({
  key: whEntriesKeys.warehouseDocumentsSummary.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/summary" + search,
    }),
});

const postConfirmEntry = (id: UUID | number) =>
  queryFetch<{ message: string; status: WhEntryStatus }>({
    method: "POST",
    url: `/wh-entries/items/${id}/confirm`,
  });

const getLineItems = (search: string = ""): ApiFetcher<Pagination<LineItem>> => ({
  key: whEntriesKeys.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/line-items" + search,
    }),
});

export const whEntriesApi = {
  getDocumentsSummary,
  getExpenditures,
  getExpenditure,
  getReceptions,
  getReception,
  getReleases,
  getRelease,
  getReturns,
  getReturn,
  patchExpenditure,
  patchReception,
  patchReturn,
  postConfirmEntry,
  getLineItems,
};
