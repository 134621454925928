import { CURRENCY_TYPE } from "CONSTANTS";
import { ProductEntity } from "api/products/models";
import { AddLineItemToUnloadingPayload } from "api/wms/models";
import cuid from "cuid";
import { AdaptedProduct } from "pages/orders/shared/rightPanel/productsSection/productForm";
import { getAttributesState } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/getAttributesState";
import { initialProductElement } from "pages/orders/shared/rightPanel/productsSection/productForm/utils/initialState";

export function getInitialStateForCreate({
  products,
  productSetCode,
}: {
  products: ProductEntity["products"];
  productSetCode: ProductEntity["productSetCode"];
}): AddLineItemToUnloadingPayload["productElements"] {
  const productElements = products.map(productElement => {
    const index = getProductIndex(productElement) || productElement.setMetaData?.index;
    const currency = "PLN" as CURRENCY_TYPE;
    if (index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        productElement.indexes,
        [],
        index,
      );
      const amount = String(productElement.indexData[index].amount);

      return {
        ...initialProductElement,
        index: index,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount,
        currency,
        product: productElement.id,
      };
    }

    if (productElement.setMetaData?.index) {
      const attributesState = getAttributesState(
        productElement.attributes,
        productElement.indexes,
        [],
        productElement.setMetaData.index,
      );

      return {
        ...initialProductElement,
        quantity: productElement.setMetaData?.quantity || 1,
        cuid: cuid(),
        attributesState,
        name: productElement.name,
        productSetCode: productSetCode,
        id: productElement.id,
        amount: "0",
        currency,
        product: productElement.id,
      };
    }

    return {
      ...initialProductElement,
      quantity: productElement.setMetaData?.quantity || 1,
      name: productElement.name,
      productSetCode: productSetCode,
      attributesState: getAttributesState(productElement.attributes, productElement.indexes),
      id: productElement.id,
      cuid: cuid(),
      amount: "0",
      currency,
      product: productElement.id,
    };
  });

  return productElements;
}

const getProductIndex = (product: AdaptedProduct | null, preselectedAttributes?: any) => {
  const attributes = getAttributesState(
    product?.attributes,
    product?.indexes,
    preselectedAttributes,
  );

  const attributesValues = Object.values(attributes).map(attr => attr.valueId);
  const allSelected = attributesValues.length > 0 && attributesValues.every(val => Boolean(val));
  if (allSelected) {
    const values = [...(attributesValues as number[])].sort((a, b) => (a > b ? 1 : -1));
    const index = values.join("-");
    if (product) {
      return product.indexes[index] || null;
    }
  }

  return null;
};
