import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const whEntriesKeys = {
  receptions: {
    list: createPaginatedKey("whEntriesReceptions"),
    details: (id: number) => ["whEntriesReception", id],
  },
  releases: {
    list: createPaginatedKey("whEntriesReleases"),
    details: (id: UUID) => ["whEntriesRelease", id],
  },
  returns: {
    list: createPaginatedKey("whEntriesReturns"),
    details: (id: number) => ["whEntriesReturn", id],
  },
  expenditures: {
    list: createPaginatedKey("whEntriesExpenditures"),
    details: (id: number) => ["whEntriesExpenditure", id],
  },
  warehouseDocumentsSummary: {
    list: createPaginatedKey("whEntriesDocumentsSummary"),
  },
  lineItems: createPaginatedKey("whEntriesLineItems"),
};
