import { countryCodes, currenciesToPick } from "CONSTANTS";
import { getOrderGroupQuery, getSimplifiedOrderGroups } from "api/order-groups/calls";
import { getRoute, getRoutesSimplifiedItems } from "api/routes/calls";
import { TradingDocument } from "api/trading-documents/models";
import { Pagination } from "api/types";
import { ApiMiddlewareResult } from "apiConnectors/fetchConnector";
import { AvailableFilters, FilterType } from "components/common/filters/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { ApiFetcher } from "hooks/createApiQuery";

export const tradingDocumentFilterFactory = <
  FF extends (
    arg: string,
    abortToken?: string,
  ) => ApiFetcher<Pagination<TradingDocument>> | ApiMiddlewareResult<Pagination<TradingDocument>>
>(
  Filter: typeof FilterType,
) => {
  return {
    invoiceDeliveryDateRange: {
      type: Filter.DateRange,
      label: "data sprzedaży",
      name: ["soldAfter", "soldBefore"],
      showMonths: true,
    } as AvailableFilters<FF, string>,
    invoiceIssueDateRange: {
      type: FilterType.DateRange,
      label: "data wystawienia",
      name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
      showMonths: true,
    } as AvailableFilters<FF, string>,
    countryCode: {
      type: Filter.Select,
      label: "państwo",
      name: "countryCode",
      multiple: true,
      kind: "label",
      options: countryCodes.map(({ icon, value }) => ({
        label: value,
        value,
        icon,
      })),
    } as AvailableFilters<FF, string>,
    salesAccount: {
      type: FilterType.Search,
      label: "konto sprzedażowe",
      name: "salesAccount",
      multiple: true,
      searchBy: "salesAccounts",
    } as AvailableFilters<FF, string>,
    routeSignature: {
      type: FilterType.AsyncSearch,
      fetchListFrom: getRoutesSimplifiedItems,
      fetchDetailsFrom: getRoute,
      label: "Numer trasy",
      name: "routes",
      value: "id",
      display: "signature",
      placeholder: "Szukaj trasy",
    } as AvailableFilters<FF, string>,
    orderGroupSignature: {
      type: FilterType.AsyncSearch,
      fetchListFrom: getSimplifiedOrderGroups,
      fetchDetailsFrom: getOrderGroupQuery,
      label: "Numer grupy zamówień",
      name: "orderGroups",
      value: "id",
      display: "signature",
      placeholder: "Szukaj grupy",
    } as AvailableFilters<FF, string>,
    internalBusinessEntities: {
      type: FilterType.Search,
      label: "sprzedawcy",
      name: "sellers",
      multiple: true,
      searchBy: "businessEntities",
      overrides: {
        businessEntitiesKind: "INTERNAL",
      },
    } as AvailableFilters<FF, string>,
    hasAllOrdersDelivered: {
      type: FilterType.Select,
      label: "Czy wszystkie zamówienia są dostarczone?",
      name: "hasAllOrdersDelivered",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    hasRecipientTaxID: {
      type: FilterType.Select,
      label: "Czy odbiorca faktury ma NIP?",
      name: "hasRecipientTaxId",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    hasBuyerTaxID: {
      type: FilterType.Select,
      label: "Czy nabywca faktury ma NIP?",
      name: "hasBuyerTaxId",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    isFiscalized: {
      type: FilterType.Select,
      label: "Czy jest sfiskalizowane?",
      name: "isFiscalized",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    canBeFiscalized: {
      type: FilterType.Select,
      label: "Czy potrzebny paragon?",
      name: "canBeFiscalized",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    status: {
      type: FilterType.Select,
      label: "status faktury",
      name: "status",
      options: [
        { label: "Zatwierdzone", value: "CONFIRMED" },
        { label: "Niezatwierdzone", value: "NEW" },
      ],
    } as AvailableFilters<FF, string>,
    customerKind: {
      type: FilterType.Select,
      kind: "label",
      label: "typ klienta",
      multiple: true,
      name: "kind",
      options: tradingDocumentConstants.tradingDocumentKindOptions,
    } as AvailableFilters<FF, string>,
    correspondingReceiptPrintingStatus: {
      type: FilterType.Select,
      kind: "label",
      label: "status drukowania",
      multiple: true,
      name: "correspondingReceiptPrintingStatus",
      options: [
        { label: "niewydrukowane", value: "NOT_STARTED" },
        { label: "w trakcie fiskalizacji", value: "IN_PROGRESS" },
        { label: "wydrukowane", value: "FINISHED" },
        { label: "niepowodzenie", value: "FAILED" },
      ],
    } as AvailableFilters<FF, string>,
    taxProcedure: {
      type: FilterType.Select,
      kind: "label",
      label: "procedura podatkowa",
      name: "taxProcedure",
      options: tradingDocumentConstants.taxProcedureFilterOptions,
    } as AvailableFilters<FF, string>,
    isPaid: {
      type: FilterType.Select,
      label: "status płatności",
      name: "isPaid",
      options: [
        { label: "Opłacono", value: "true" },
        { label: "Nie opłacono", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    currency: {
      type: FilterType.Select,
      label: "waluta",
      name: "currency",
      kind: "label",
      options: currenciesToPick,
      multiple: true,
    } as AvailableFilters<FF, string>,
    pickupID: {
      type: FilterType.Text,
      label: "nr zlecenia kurierskiego",
      placeholder: "Szukaj nr zlecenia kurierskiego",
      name: "pickupId",
    } as AvailableFilters<FF, string>,
    shipmentTrackingNumber: {
      type: FilterType.Text,
      label: "nr przesyłki",
      placeholder: "Szukaj nr przesyłki",
      name: "trackingNumber",
    } as AvailableFilters<FF, string>,
    paymentType: {
      type: FilterType.Select,
      label: "sposób płatności",
      name: "paymentType",
      options: tradingDocumentConstants.paymentMethodsOptions,
    } as AvailableFilters<FF, string>,
    paymentProvider: {
      type: FilterType.Select,
      kind: "label",
      label: "forma płatności",
      multiple: true,
      name: "paymentProvider",
      options: tradingDocumentConstants.paymentProviderOptions,
    } as AvailableFilters<FF, string>,
    isAssigned: {
      type: FilterType.SelectUser,
      label: "kto się zajmuje?",
      multiple: true,
      name: "isAssigned",
      kind: "user",
      searchBy: "standardUsers",
    } as AvailableFilters<FF, string>,
    customers: {
      type: FilterType.Search,
      label: "kontrahent",
      name: "customers",
      multiple: true,
      searchBy: "customers",
    } as AvailableFilters<FF, string>,
    transferDate: {
      type: FilterType.Date,
      label: "data przelewu",
      name: "transferDate",
    } as AvailableFilters<FF, string>,
    isXMLOptimaDownloaded: {
      type: FilterType.Select,
      label: "czy pobrano XML?",
      name: "isXmlOptimaDownloaded",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    isPaymentOverdue: {
      type: FilterType.Select,
      label: "zaległe faktury",
      name: "isPaymentOverdue",
      options: [
        { label: "Tak", value: "true" },
        { label: "Nie", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
    transferNumber: {
      type: FilterType.Text,
      label: "nr przelewu",
      placeholder: "Szukaj nr przelewu",
      name: "transferNumber",
    } as AvailableFilters<FF, string>,
    isSelfShipped: {
      type: FilterType.Select,
      label: "rodzaj transportu",
      name: "isSelfShipped",
      options: [
        { label: "Własny", value: "true" },
        { label: "Zewnętrzny", value: "false" },
      ],
    } as AvailableFilters<FF, string>,
  };
};
