import { fileFactoryUtils } from "utilities/fileFactory";

export const financialPackagesFileFactory = (() => {
  return {
    whEntryPackagesLabel: (search: string, signature?: string) => ({
      url: `/wms/view-modules/financial-packages/get-labels${search}`,
      name: `${
        signature ? fileFactoryUtils.formatSignature(signature) : "Etykieta"
      }-${fileFactoryUtils.getDate()}`,
    }),
  };
})();
