import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Chart, SingleLineChartData } from "components/miloDesignSystem/molecules/chart";
import { CommonError } from "components/utils";
import { dateFns, formatSubUnitsToDecimal } from "utilities";
import styles from "../../Finances.module.css";
import { WarehouseStateTab } from "pages/finances/shared/constants/financesConstants";
import { financesUtils } from "pages/finances/shared/utils/financesUtils";
import { FinancialWarehouseStateSummaryFilters } from "../../../warehousesFinancialState/components/warehousesFinancialStateSummary/WarehousesFinancialStateSummary";

export const WarehouseStateChartContainer = ({
  tab,
  filters,
}: {
  tab: WarehouseStateTab;
  filters: FinancialWarehouseStateSummaryFilters;
}) => {
  const search = financesUtils.getWarehouseStateSearch({ query: {}, tab, filters });
  const {
    data: summaryPeriods,
    error,
    isLoading,
  } = financialWarehouseStateActions.useFinancialWarehouseStateGeneral(search);

  if (isLoading)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <Spinner size={20} />
        </div>
      </div>
    );

  if (error)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );

  const formattedData: SingleLineChartData[] = [
    {
      data:
        summaryPeriods
          ?.map(period => {
            const parsedDate = dateFns.parse(period.periodId, "yyyy-M", new Date());
            const formattedAmount = Number(
              formatSubUnitsToDecimal(period.totalValue + period.baselineValue),
            ).toLocaleString("de-DE", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });

            return {
              xAxisLabel: dateFns.format(parsedDate, "LLL"),
              tooltipBodyRenderer: (
                <div>
                  <Typography
                    fontSize="12"
                    fontWeight="700"
                    color="neutralWhite100"
                    className="text-capitalize"
                  >
                    {dateFns.format(parsedDate, "LLLL")}:
                  </Typography>
                  <Typography fontSize="12" fontWeight="600" color="neutralWhite100">
                    {formattedAmount} PLN
                  </Typography>
                </div>
              ),
              value: Number(formatSubUnitsToDecimal(period.totalValue + period.baselineValue)),
            };
          })
          .reverse() || [],
      config: {
        legendText: "wartość magazynu",
      },
    },
  ];

  return (
    <div className="d-flex flex-column flex-1">
      <div className="d-flex align-items-center gap-2 mb-2">
        <Typography fontSize="14" fontWeight="600">
          Wartość magazynu w czasie
        </Typography>
        <Typography fontSize="10" fontWeight="600" color="neutralBlack48">
          (ostatnie 6 mies.)
        </Typography>
      </div>
      <div className="flex-1">
        <Chart.LineChart className={styles.chart} data={formattedData} />
      </div>
    </div>
  );
};
