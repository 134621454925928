import { useState } from "react";
import rowStyles from "components/miloDesignSystem/molecules/table/components/body/components/row/Row.module.css";
import { TableProps } from "../../types";
import { UUID } from "api/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { cx } from "utilities";
import { useTableKeyboard } from "./useTableKeyboard";

export type UseTableMultiSelectReturnType<T extends { id: UUID | number }> = {
  selectedRows: T[];
  onRowClick: (
    row: T,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => "selection" | "click";
  overrides: TableProps<T>["overrides"];
};

export const useTableMultiSelect = <T extends { id: UUID | number }>({
  rows,
}: Pick<TableProps<T>, "rows">) => {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  useTableKeyboard({ rows, setSelectedRows });

  const onRowClick = (
    row: T,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): "selection" | "click" => {
    if (ctrlOrMetaKey(event)) {
      event.preventDefault();
      window.getSelection()?.empty();
      handleSelection({ rowId: row.id.toString(), rows, setSelectedRows });
      return "selection";
    }

    if (event.shiftKey) {
      event.preventDefault();
      window.getSelection()?.empty();

      handleSelectionFromTo({ rowId: row.id, rows, selectedRows, setSelectedRows });
      return "selection";
    }

    const rowToSelect = rows.find(_row => String(_row.id) === String(row.id));
    assertIsDefined(rowToSelect);
    setSelectedRows([rowToSelect]);
    return "click";
  };

  const overrides: TableProps<T>["overrides"] = () => {
    return {
      row: row => {
        const isRowSelected = selectedRows.find(r => r.id === row.id);

        return {
          className: cx({
            [rowStyles.selected]: isRowSelected,
          }),
        };
      },
    };
  };
  return {
    selectedRows,
    clear: () => setSelectedRows([]),
    onRowClick,
    overrides,
  };
};

const handleSelection = <T extends { id: UUID | number }>({
  rowId,
  rows,
  setSelectedRows,
}: {
  rowId: string;
  setSelectedRows: (value: React.SetStateAction<T[]>) => void;
  rows: T[];
}) => {
  setSelectedRows(selectedRows => {
    const rowToSelect = rows.find(row => String(row.id) === rowId);
    assertIsDefined(rowToSelect);
    const isAlreadyAdded = Boolean(selectedRows.find(row => String(row.id) === rowId));
    if (isAlreadyAdded) {
      return selectedRows.filter(row => row.id !== rowId);
    }
    return [...selectedRows, rowToSelect];
  });
};

const handleSelectionFromTo = <T extends { id: UUID | number }>({
  rowId,
  rows,
  selectedRows,
  setSelectedRows,
}: {
  rows: T[];
  selectedRows: T[];
  rowId: T["id"];
  setSelectedRows: (value: React.SetStateAction<T[]>) => void;
}) => {
  if (!selectedRows.length) {
    const rowToSelect = rows.find(row => String(row.id) === rowId);
    assertIsDefined(rowToSelect);
    setSelectedRows([rowToSelect]);
    return;
  }

  const lastSelectedRowIndex = rows.findIndex(
    row => String(row.id) === String(selectedRows[selectedRows.length - 1].id),
  );
  const rowToSelectIndex = rows.findIndex(row => String(row.id) === rowId);

  const [start, end] = [lastSelectedRowIndex, rowToSelectIndex].sort((a, b) => a - b);

  const inBetween = rows.slice(start, end + 1);

  // if already selected: then no need to select it again
  const rowsToAdd = inBetween.filter(
    (el): boolean => !selectedRows.find(selectedItem => selectedItem.id === el.id),
  );

  return setSelectedRows([...selectedRows, ...rowsToAdd]);
};

export const ctrlOrMetaKey = (event: React.MouseEvent | KeyboardEvent) => {
  const isUsingMacPlatform = navigator.userAgent.includes("Mac");
  return isUsingMacPlatform ? event.metaKey : event.ctrlKey;
};
