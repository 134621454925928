import { Product, ProductValue } from "api/products/models";
import { PreselectedValues } from "..";

export function getAttributesState(
  productAttributes?: Product["attributes"] | null,
  productIndexes?: Record<string, number>,
  preselectedAttributes?: PreselectedValues,
  selectedIndex?: number,
) {
  if (!productIndexes || !productAttributes) return [];
  const values = productIndexes
    ? Object.keys(productIndexes).find(key => productIndexes[key] === selectedIndex) || ""
    : "";

  const valuesArr = values
    .split("-")
    .filter(Boolean)
    .map(Number);

  return productAttributes?.map(attr => {
    const value = (() => {
      if (attr.kind === "FABRIC") {
        return attr.categories.reduce((acc: ProductValue | null, cat) => {
          const value = cat.values.find(
            val =>
              preselectedAttributes?.some(el => el.value.id === val.id) ||
              valuesArr.includes(val.id),
          );
          if (value) {
            acc = value;
          }
          return acc;
        }, null);
      }

      // select add value Id when there is only one assignable to index value
      const assignableToIndexValues = attr.values.filter(el => el.isAssignableToIndex);
      if (assignableToIndexValues.length === 1) {
        return assignableToIndexValues[0];
      }

      return attr.values.find(
        (val: any) =>
          preselectedAttributes?.some(el => el.value.id === val.id) || valuesArr.includes(val.id),
      );
    })();

    return {
      attributeId: attr.id,
      valueId: value?.id || null,
      picture: value?.picture ?? null,
    };
  });
}
