import { ListOrder } from "api/orders/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { orderConstants } from "constants/orders";

interface Props {
  order: ListOrder;
}

export const PaymentLabel = ({ order }: Props) => {
  if (order.payment.status === "UNPAID")
    return (
      <Tag.WithCustomColor backgroundColor="red32" label="Brak płatności" textColor="red500" />
    );

  return orderConstants.getPaymentSourceTag(order.payment.source);
};
