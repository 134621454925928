import { whEntriesActions } from "api/wh-entries/actions";
import { ReceptionDetails } from "api/wh-entries/models";
import { InfoLabel } from "components/common/infoLabel";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { generalInfoSectionFactory } from "pages/finances/shared/components/whEntryRightPanel/generalInfoSectionFactory/generalInfoSectionFactory";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  reception: ReceptionDetails;
}

export const GeneralInfoSection = ({ reception }: Props) => {
  const generalInfoFactory = generalInfoSectionFactory(reception);

  return (
    <RightPanelSection>
      <InfoLabel title="nr obcy">
        <TextField.Async
          mutationHook={whEntriesActions.usePatchReception}
          size="small"
          transformQueryData={externalNumber => ({
            id: reception.id,
            externalNumber,
          })}
          value={reception.externalNumber}
        />
      </InfoLabel>
      {generalInfoFactory.date({ date: reception.created, title: "data przyjęcia" })}
      {generalInfoFactory.warehouse()}
      <InfoLabel title="dostawca">
        <Typography fontSize="14" fontWeight="500">
          {reception.manufacturer?.name || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      {generalInfoFactory.closedBy()}
      {generalInfoFactory.date({ date: reception.closed, title: "data zamknięcia" })}
    </RightPanelSection>
  );
};
