import { financialPackagesActions } from "api/wms/financial-packages/actions";
import { FinancialWarehouseStateMonthStatus } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import {
  WarehouseStateTab,
  financesConstants,
} from "pages/finances/shared/constants/financesConstants";
import { financesUtils } from "pages/finances/shared/utils/financesUtils";
import { dateUtils, formatSubUnitsToDecimal } from "utilities";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useSingleFinancialWarehouseStateSummaryColumns = (
  tab: WarehouseStateTab,
  open: (stateToSet: FinancialWarehouseStateSummaryPerPeriod) => void,
) => {
  const closePeriodMutation = financialPackagesActions.useClosePeriod();

  return useCreateTableColumns<FinancialWarehouseStateSummaryPerPeriod>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.periodId, {
        header: tab === WarehouseStateTab.MONTHLY ? "miesiąc" : "kwartał",
        size: 70,
        cell: info => {
          const period = info.getValue();
          return (
            <Typography fontSize="12" fontWeight="700">
              {financesUtils.formatMonthAbbreviation(period)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status",
        size: 130,
        cell: info => {
          const warehouseState = info.getValue();

          if (
            financesUtils.isCurrentPeriod(warehouseState.periodId) &&
            warehouseState.status === FinancialWarehouseStateMonthStatus.OPENED
          )
            return (
              <Tag.WithCustomColor
                backgroundColor="deepPurple75"
                label="Aktualny"
                textColor="deepPurple600"
              />
            );
          return financesConstants.getWarehousePeriodSummaryStatusTag(warehouseState.status);
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zamknięto",
        size: 100,
        cell: info => {
          const warehouseState = info.getValue();
          if (warehouseState.status === FinancialWarehouseStateMonthStatus.CLOSED) {
            return (
              <Typography fontSize="14" fontWeight="500">
                {warehouseState.closedAt
                  ? dateUtils.formatDateToDisplay(warehouseState.closedAt)
                  : EMPTY_VALUE}
              </Typography>
            );
          }
          if (warehouseState.status === FinancialWarehouseStateMonthStatus.FROZEN)
            return (
              <Button
                className="text-uppercase"
                isLoading={closePeriodMutation.isLoading}
                onClick={() =>
                  closePeriodMutation.mutate({ closedWarehouseStateId: warehouseState.id })
                }
                size="small"
                variant="gray"
              >
                Zamknij miesiąc
              </Button>
            );
          if (
            financesUtils.isCurrentPeriod(warehouseState.periodId) ||
            warehouseState.status === FinancialWarehouseStateMonthStatus.OPENED
          ) {
            return (
              <Button
                className="text-uppercase"
                disabled={!warehouseState.isEarliestOpenedState}
                onClick={() => open(warehouseState)}
                size="small"
                variant="gray"
              >
                Przelicz
              </Button>
            );
          }
          return null;
        },
      }),
      columnHelper.amount(row => formatSubUnitsToDecimal(row.baselineValue + row.totalValue), {
        header: "wartość [PLN]",
        size: 185,
        amountDisplayProps: {
          noSeparator: true,
        },
      }),
      columnHelper.amount(row => formatSubUnitsToDecimal(row.estimatedValue), {
        header: "kwota wycen prognozowanych [PLN]",
        size: 195,
        amountDisplayProps: {
          integer: {
            fontSize: "14",
            fontWeight: "500",
          },
          noSeparator: true,
        },
      }),
      columnHelper.accessor(row => Number(row.estimatedPercent.toFixed(2)) * 100, {
        id: "percentageEstimation",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            % wyceny
          </Typography>
        ),
        size: 65,
        cell: info => {
          const percentageEstimation = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="14" fontWeight="400">
              {percentageEstimation}%
            </Typography>
          );
        },
      }),
    ];
  });
};
