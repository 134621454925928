import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiDamage = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      d="M5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V16C3 15.45 3.19583 14.9792 3.5875 14.5875C3.97917 14.1958 4.45 14 5 14H19C19.55 14 20.0208 14.1958 20.4125 14.5875C20.8042 14.9792 21 15.45 21 16V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V16H5V20ZM6.525 12.5L1.5 9.65L7.5 8.65L5.875 2.775L10.85 6.3L13.85 1L14.85 7L20.725 5.375L17.2 10.35L21 12.5H16.95L14.3 11L15.85 8.8L13.25 9.525L12.8 6.875L11.5 9.2L9.3 7.65L10.025 10.25L7.375 10.7L10.375 12.5H6.525Z"
      fillOpacity="0.88"
    />
  </SvgWrapper>
);
