import { WhEntriesSearchProps } from "api/wh-entries/models";
import { dateFns, dictToList, queryString } from "utilities";
import { WarehouseStateTab, WhEntryTab, financesConstants } from "../constants/financesConstants";
import { TabProps } from "api/types";
import { SearchProps } from "api/wms/financial-packages/financial-warehouse-state/models";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { pl } from "date-fns/locale";
import { isSameMonth } from "date-fns";

const getWhEntriesSearch = ({ query, tab }: WhEntriesSearchProps) => {
  const tabsQuery = {
    status: tab === "opened" ? WhEntryTab.OPENED : tab === "closed" ? WhEntryTab.CLOSED : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

const getWhEntriesTabs = (): TabProps => {
  return dictToList(financesConstants.whEntriesTabDict).map(({ key, value }) => ({
    label: value as string,
    name: key,
  }));
};

const getWarehouseStateTabs = (): TabProps =>
  dictToList(financesConstants.warehouseStateTabsDict).map(({ key, value }) => ({
    label: value,
    name: key,
  }));

const getWarehouseStateSearch = <T,>({ query, tab, filters }: SearchProps<T>): string => {
  const tabsQuery = {
    groupBy: tab === "monthly" ? WarehouseStateTab.MONTHLY : WarehouseStateTab.QUARTERLY,
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
    ...filters,
  });
};

/**
 *
 * @param date "2024-12"
 * @returns "sty. 2024"
 */
const formatMonthAbbreviation = (date: string): string => {
  const monthPattern = /^\d{4}-(\d{2})$/;

  if (monthPattern.test(date)) {
    return dateFns.format(dateFns.parse(date, "yyyy-MM", new Date()), "MMM. yyyy", {
      locale: pl,
    });
  }
  return EMPTY_VALUE;
};

/**
 * @param date "2024-12"
 * @returns boolean
 */
const isCurrentPeriod = (date: string): boolean => {
  const monthPattern = /^\d{4}-(\d{2})$/;

  if (monthPattern.test(date)) {
    const parsedDate = dateFns.parse(date, "yyyy-MM", new Date());
    return isSameMonth(parsedDate, new Date());
  }
  return false;
};

export const financesUtils = {
  formatMonthAbbreviation,
  getWarehouseStateSearch,
  getWarehouseStateTabs,
  getWhEntriesSearch,
  getWhEntriesTabs,
  isCurrentPeriod,
};
