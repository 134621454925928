import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { financialWarehouseStateKeys } from "./keys";
import {
  FinancialWarehouseStateSummaryPerPeriod,
  FinancialWarehouseStateDetails,
  FinancialWarehouseLatestState,
  ChartWarehouseValue,
} from "./models";

const getFinancialWarehouseStateGeneralSummary = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseStateSummaryPerPeriod>> => ({
  key: financialWarehouseStateKeys.general.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/warehouse-financial-states" + search,
    }),
});

const getFinancialWarehouseStateDetails = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseStateDetails>> => ({
  key: financialWarehouseStateKeys.details.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/documents-financial-states" + search,
    }),
});

const getFinancialWarehouseStateForToday = ({
  search = "",
}: {
  search: string;
}): ApiFetcher<FinancialWarehouseLatestState> => ({
  key: financialWarehouseStateKeys.forToday(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/view-modules/financial-packages/warehouse-financial-states/latest" + search,
    }),
});

const getChartWarehouseValue = ({
  search = "",
}: {
  search: string;
}): ApiFetcher<ChartWarehouseValue[]> => ({
  key: financialWarehouseStateKeys.chartWarehouseValue(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url:
        "/wms/view-modules/financial-packages/warehouse-financial-states/charts/warehouse-value" +
        search,
    }),
});

export const financialWarehouseStateApi = {
  getFinancialWarehouseStateDetails,
  getFinancialWarehouseStateForToday,
  getFinancialWarehouseStateGeneralSummary,
  getChartWarehouseValue,
};
