import { TradingDocument } from "api/trading-documents/models";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { orderActions } from "api/orders/actions";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { useFilters } from "hooks/useFilters";
import { AddOrdersToDraftContent } from "./AddOrdersToDraftContent";
import { OrderPaymentTypeChoices } from "api/orders/enums";

interface Props {
  close: () => void;
  draftInvoice: TradingDocument;
}

export interface DraftInvoiceOrdersFilters {
  channels: string;
  createdMinDate: string;
  createdMaxDate: string;
  page: number;
  pageSize: number;
  status: string;
  forceStrategy: string;
  hasOwnEntityTradingDocument: string;
}

export const AddOrdersToDraft = ({ close, draftInvoice }: Props) => {
  const { searchParams, filters, setFilter } = useFilters<DraftInvoiceOrdersFilters>({
    channels: "",
    createdMaxDate: "",
    createdMinDate: "",
    page: 1,
    pageSize: 15,
    status: "",
    forceStrategy: OrderPaymentTypeChoices.DROP_SHIPPING,
    hasOwnEntityTradingDocument: "",
  });
  const {
    data: orders,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = orderActions.useOrdersForInvoicing(searchParams);

  if (error)
    return (
      <Modal
        close={close}
        isOpen
        width={1365}
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj zamówienia do faktury na podmiot własny
          </Typography>
        }
      >
        <div>
          <CommonError status={error._httpStatus_} />
        </div>
      </Modal>
    );

  if (isLoading)
    return (
      <Modal
        close={close}
        isOpen
        width={1365}
        title={
          <Typography fontSize="20" fontWeight="700">
            Dodaj zamówienia do faktury na podmiot własny
          </Typography>
        }
      >
        <div className="pt-3 pb-5 px-3">
          <Spinner size={26} />
        </div>
      </Modal>
    );

  if (!orders) return null;

  return (
    <Modal
      close={close}
      isOpen
      width={1365}
      title={
        <Typography fontSize="20" fontWeight="700">
          Dodaj zamówienia do faktury na podmiot własny
        </Typography>
      }
    >
      <AddOrdersToDraftContent
        close={close}
        draftInvoice={draftInvoice}
        filters={filters}
        isPreviousData={isPreviousData}
        isLoading={isLoading}
        orders={orders}
        pagination={pagination}
        setFilter={setFilter}
      />
    </Modal>
  );
};
