import { FinancialWarehouseStateMonthStatus } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export enum WhEntryTab {
  ALL = "all",
  CLOSED = "closed",
  OPENED = "opened",
}

export enum WarehouseStateTab {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
}

const whEntriesTabDict: Record<WhEntryTab, string> = {
  all: "Wszystkie",
  closed: "Zamknięte",
  opened: "Otwarte",
};

const warehouseStateTabsDict: Record<WarehouseStateTab, string> = {
  monthly: "Miesięcznie",
  quarterly: "Kwartalnie",
};

const getWarehousePeriodSummaryStatusTag = (
  status: FinancialWarehouseStateSummaryPerPeriod["status"],
) => {
  switch (status) {
    case FinancialWarehouseStateMonthStatus.FROZEN:
      return (
        <Tag.WithCustomColor backgroundColor="cyan100" label="Przeliczony" textColor="cyan600" />
      );
    case FinancialWarehouseStateMonthStatus.CALCULATING:
      return (
        <Tag.WithCustomColor
          backgroundColor="orange100"
          label="Trwa przeliczanie"
          startIcon={MdiPoint}
          textColor="orange600"
        />
      );
    case FinancialWarehouseStateMonthStatus.CLOSED:
      return (
        <Tag.WithCustomColor backgroundColor="green75" label="Zamknięty" textColor="green600" />
      );
    case FinancialWarehouseStateMonthStatus.OPENED:
      return (
        <Tag.WithCustomColor backgroundColor="indigo75" label="Otwarty" textColor="indigo600" />
      );
    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled summary status: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
  }
};

export const financesConstants = {
  getWarehousePeriodSummaryStatusTag,
  warehouseStateTabsDict,
  whEntriesTabDict,
};
