import { useQuery } from "hooks";
import { withFilters } from "components/utils/withFilters";
import { RouteComponentProps } from "react-router";
import { productsUtils } from "./utils/productsUtils";
import { productsActions } from "api/products/actions";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { ProductLightWithDeleted } from "api/products/models";
import { useProductsColumns } from "./useProductsColumns";
import { PageHeader } from "components/common";
import { products } from "components/common/moduleNavigation/moduleConfig/inventory/routes/products";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { ProductsTab, productsConstants } from "./constants/productsConstants";
import { RightPanel } from "./rightPanel/RightPanel";

export const ProductsList = withFilters(
  [],
  ({ match }: RouteComponentProps<{ tab: ProductsTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = productsUtils.getProductsListSearch({ query, tab });
    const {
      data: products,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = productsActions.useProductsLightWithDeleted(search);
    const columns = useProductsColumns();
    const tableProps = useTableFeatureConnector({
      rows: products,
      withDrawer: "products",
      withPagination: { pagination, defaultPaginationVisibility: true },
    });

    return (
      <PageWrapper>
        <Header />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<ProductLightWithDeleted>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "28",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
        </div>
      </PageWrapper>
    );
  },
);

const Header = () => {
  return (
    <>
      <PageHeader
        createButton={{
          alt: "utwórz",
          disabled: true,
          img: MdiAdd,
          label: "Utwórz produkt",
          onClick: () => {},
        }}
        searchInput={{
          label: "Szukaj...",
        }}
        tabs={{
          list: productsConstants.getProductsTabs(),
          routesRoot: `inventory/${products.url}`,
          urlSpan: "list",
        }}
        viewLabel="PRODUCTS"
      />
    </>
  );
};
