import { createPaginatedKey } from "api/keys";

export const financialPackagesKeys = {
  packageState: {
    summary: (internalId: string) => ["financialPackagesStateSummary", internalId],
    perPeriods: createPaginatedKey("financialPackagesStatePerPeriods"),
    supplies: createPaginatedKey("financialPackageSupplies"),
  },
  inventoryChecks: {
    list: createPaginatedKey("financialPackageInventoryChecks"),
  },
};
