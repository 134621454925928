import { InventoryCheckType as InventoryCheckTypeEnum } from "api/wh-inventory-check/enums";
import { InventoryCheckItem } from "api/wh-inventory-check/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  type: InventoryCheckItem["type"];
}

export const InventoryCheckType = ({ type }: Props) => {
  switch (type) {
    case InventoryCheckTypeEnum.FULL:
      return (
        <Tag.WithCustomColor backgroundColor="purple50" label="Całościowa" textColor="purple500" />
      );
    case InventoryCheckTypeEnum.PARTIAL:
      return <Tag.WithCustomColor backgroundColor="lime50" label="Częściowa" textColor="lime600" />;
    default:
      const exhaustiveCheck: never = type;
      console.error(`Unhandled inventory check type: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
  }
};
