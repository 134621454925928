import { TabProps } from "api/types";
import { InventoryChecksSearchProps } from "api/wh-inventory-check/models";
import { dictToList, queryString } from "utilities";
import { InventoryCheckTab, inventoryChecksConstants } from "../constants/inventoryChecksConstants";

const getInventoryChecksSearch = ({ query, tab }: InventoryChecksSearchProps) => {
  const tabsQuery = {
    isFinished:
      tab === InventoryCheckTab.CLOSED
        ? "true"
        : tab === InventoryCheckTab.IN_PROGRESS
        ? "false"
        : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

const getInventoryChecksTabs = (): TabProps => {
  return dictToList(inventoryChecksConstants.inventoryChecksTabDict).map(({ key, value }) => ({
    label: value as string,
    name: key,
  }));
};

export const inventoryChecksUtils = {
  getInventoryChecksSearch,
  getInventoryChecksTabs,
};
