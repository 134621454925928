import { usePickingLineItems } from "api/wms/hooks";
import { PickingDetails, PickingLineItem, PickingStatus } from "api/wms/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { RightPanelSection } from "components/utils/drawer";
import { useFilters } from "hooks/useFilters";
import { useLineItemsColumns } from "../hooks/useLineItemsColumns";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import styles from "pages/wms/shared/Wms.module.css";
import { cx } from "utilities";
import { useStateModal } from "hooks";
import { UUID } from "api/types";
import { LineItemsDetailsModal } from "./lineItemsDetailsModal/LineItemsDetailsModal";

interface Props {
  picking: PickingDetails;
}

export const LineItemsSection = ({ picking }: Props) => {
  const { searchParams, setFilter } = useFilters({ page: 1, picking: picking.id });
  const { data: orders, error, isLoading, isPreviousData, pagination } = usePickingLineItems(
    searchParams,
  );
  const columns = useLineItemsColumns();
  const lineItemDetailsModal = useStateModal<UUID>();

  return (
    <>
      <RightPanelSection title="Zamówienia">
        <div className="pb-3">
          <Table<PickingLineItem>
            rows={orders}
            columns={columns}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={comfortableListUiSchema}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            onRowClick={picking => lineItemDetailsModal.open(picking.id)}
            overrides={() => {
              return {
                row: row => {
                  return {
                    className: cx({
                      dashedTableRow: row.isCancelled,
                      [styles.finishedItem]:
                        row.status === PickingStatus.FINISHED && !row.isCancelled,
                      [styles.inProgressItem]:
                        row.status === PickingStatus.IN_PROGRESS && !row.isCancelled,
                    }),
                  };
                },
              };
            }}
          />
        </div>
      </RightPanelSection>
      {lineItemDetailsModal.isOpen && lineItemDetailsModal.state && (
        <LineItemsDetailsModal
          close={lineItemDetailsModal.close}
          lineItemId={lineItemDetailsModal.state}
        />
      )}
    </>
  );
};
