import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { inventoryChecksApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { inventoryCheckKeys } from "./keys";
import { getAnyErrorKey } from "utilities";
import { createApiQuery } from "hooks/createApiQuery";

const useInventoryChecks = createPaginatedApiQuery(inventoryChecksApi.getInventoryChecks);
const useInventoryCheck = createApiQuery(inventoryChecksApi.getInventoryCheck);
const useInventoryChecksSummary = createPaginatedApiQuery(
  inventoryChecksApi.getInventoryCheckSummary,
);

const useCreateInventoryCheck = () => {
  return useMutation(inventoryChecksApi.postInventoryCheck, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(inventoryCheckKeys.inventoryChecks()),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

export const inventoryChecksActions = {
  useCreateInventoryCheck,
  useInventoryChecks,
  useInventoryCheck,
  useInventoryChecksSummary,
};
