import { useParams } from "react-router";
import { useQuery } from "hooks";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanel } from "./rightPanel/RightPanel";
import { whEntriesActions } from "api/wh-entries/actions";
import { ReceptionListItem } from "api/wh-entries/models";
import { financesUtils } from "../shared/utils/financesUtils";
import { useReceptionsColumns } from "./useReceptionsColumns";
import { receptions } from "components/common/moduleNavigation/moduleConfig/finances/routes/receptions";
import { WhEntryTab } from "../shared/constants/financesConstants";

export const Receptions = () => {
  const { tab } = useParams<{ tab: WhEntryTab }>();
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = financesUtils.getWhEntriesSearch({
    query,
    tab,
  });
  const {
    data: receptions,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = whEntriesActions.useReceptions(search);
  const columns = useReceptionsColumns();
  const tableProps = useTableFeatureConnector({
    rows: receptions,
    withDrawer: "financialWarehouseReceptions",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ReceptionListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  return (
    <PageHeader
      searchInput={{
        label: "Szukaj...",
        tags: [],
      }}
      tabs={{
        list: financesUtils.getWhEntriesTabs(),
        routesRoot: `finances/${receptions.url}`,
        urlSpan: "list",
      }}
      viewLabel="FINANCIAL_WAREHOUSE_RECEPTIONS"
    />
  );
};
