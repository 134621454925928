import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react";
import { cx } from "utilities";
import { TypographyProps } from "./types";
import styles from "./Typography.module.css";
import { colorPalette } from "../colorsPalette";
import { Assign } from "utility-types";
import { Tooltip } from "../tooltip/Tooltip";

export const Typography = (props: PropsWithChildren<TypographyProps>) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color:
          props.color === "inherit" ? "inherit" : colorPalette[props.color || "neutralBlack100"],
      }}
      className={cx(styles.typography, fontSizeToStyleDict[props.fontSize], props.className, {
        [styles.noWrap]: props.noWrap,
        [styles.oldFont]: props.fontFamily === "cabin",
      })}
    >
      {props.children}
    </div>
  );
};

const fontSizeToStyleDict: Record<TypographyProps["fontSize"], string> = {
  "10": styles.fs10,
  "12": styles.fs12,
  "14": styles.fs14,
  "16": styles.fs16,
  "18": styles.fs18,
  "20": styles.fs20,
  "26": styles.fs26,
  "32": styles.fs32,
  "64": styles.fs64,
  "100": styles.fs100,
};

Typography.WithCustomColor = (
  props: PropsWithChildren<Assign<TypographyProps, { color: string }>>,
) => {
  return (
    <div
      style={{
        fontWeight: Number(props.fontWeight),
        color: props.color,
      }}
      className={cx(styles.typography, styles[`fs${props.fontSize}`], props.className, {
        [styles.noWrap]: props.noWrap,
      })}
    >
      {props.children}
    </div>
  );
};

const OverflowTextWithTooltip = (props: PropsWithChildren<TypographyProps>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [shouldTextBeWrapped, setShouldTextBeWrapped] = useState(false);

  useLayoutEffect(() => {
    const parentWidth = ref.current?.clientWidth || 0;
    let childrenWidth = 0;
    ref.current?.childNodes.forEach(node => {
      childrenWidth += (node as HTMLDivElement).clientWidth;
    });
    const shouldBeWrapped = parentWidth <= childrenWidth;
    if (shouldBeWrapped) {
      setShouldTextBeWrapped(true);
    }
  }, []);

  return (
    <div className="d-flex w-100 align-items-center" ref={ref}>
      {shouldTextBeWrapped ? (
        <Tooltip title={props.children}>
          <div className="d-flex align-items-center gap-1 w-100">
            <Typography
              className={props.className}
              color={props.color || "neutralBlack100"}
              fontSize={props.fontSize}
              fontWeight={props.fontWeight}
              noWrap
            >
              {props.children}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <Typography
          className={props.className}
          color={props.color || "neutralBlack100"}
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}
          noWrap
        >
          {props.children}
        </Typography>
      )}
    </div>
  );
};

Typography.OverflowTextWithTooltip = OverflowTextWithTooltip;
