import { UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";

const deleteManufacturingPlan = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/stage-production-plans/entries/" + id,
  });

export const stageProductionPlansApi = {
  deleteManufacturingPlan,
};
