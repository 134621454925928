import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { productsApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { productsKeys } from "./keys";

const useGetProductIndexesPrice = createApiQuery(productsApi.getProductIndexesPrice);
const useGetProductIndexes = createApiQuery(productsApi.getProductIndexes);
const useGetProductEntity = createApiQuery(productsApi.getProductEntity);
const useProductsLightWithDeleted = createPaginatedApiQuery(
  productsApi.getProductsLightWithDeleted,
);
const useProduct = createApiQuery(productsApi.getProduct);

const useProductAttributes = createPaginatedApiQuery(productsApi.getProductAttributes);

const usePatchProduct = () => {
  return useMutation(productsApi.patchProduct, ({ queryClient, queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const previousList = queryUtils.handlePaginatedListUpdate(
        productsKeys.productsWithDeleted.list(),
        id,
        toUpdate,
      );
      const previousPanel = queryUtils.handleMutate(
        productsKeys.products.details(id.toString()),
        toUpdate,
      );

      return { previousList, previousPanel };
    },
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(
        productsKeys.productsWithDeleted.list(),
        onMutationReturn.previousList,
        id,
      );
      queryUtils.rollback(
        productsKeys.products.details(id.toString()),
        onMutationReturn.previousPanel,
        error,
      );
    },
  }));
};

const usePatchProductAttribute = () => {
  return useMutation(productsApi.patchProductAttribute, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const previousList = queryUtils.handlePaginatedListUpdate(
        productsKeys.productAttributes.list(),
        id,
        toUpdate,
      );

      return { previousList };
    },
    onError: (_, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(
        productsKeys.productAttributes.list(),
        onMutationReturn.previousList,
        id,
      );
    },
  }));
};

export const productsActions = {
  useGetProductIndexesPrice,
  useGetProductIndexes,
  useGetProductEntity,
  usePatchProduct,
  usePatchProductAttribute,
  useProduct,
  useProductAttributes,
  useProductsLightWithDeleted,
};
