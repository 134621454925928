import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { DEFAULT_OPTION_MENU_ICON_SIZE } from "CONSTANTS";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";

interface Props {
  close: () => void;
  manufacturingSchema: ManufacturingSchema;
}

export const PanelHeader = ({ close, manufacturingSchema }: Props) => {
  const deleteMutation = manufacturingSchemaActions.useRemoveManufacturingSchema({
    close,
    manufacturingSchema,
  });
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="nowrap">{manufacturingSchema.name}</SlidingHeaderWrapper>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.ICON,
                icon: deleteMutation.isLoading ? (
                  <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
                ) : (
                  <MdiDelete color="danger600" size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
                ),
                onClick: () => deleteMutation.mutate(manufacturingSchema.id),
                options: {
                  color: "danger600",
                  disabled: deleteMutation.isLoading,
                },
                text: "Usuń",
              },
            ]}
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
