import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Button } from "components/miloDesignSystem/atoms/button";
import hourGlassIcon from "assets/images/hourglass.svg";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  close: () => void;
  closeParent: () => void;
}

export const ResponseModal = ({ close, closeParent }: Props) => {
  return (
    <Modal close={close} isOpen removeHeader width={600}>
      <div>
        <div className="py-4 px-3 d-flex flex-column gap-4 align-items-center justify-content-center">
          <img alt="Czekaj" src={hourGlassIcon} />
          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            <Typography fontSize="20" fontWeight="600">
              Trwa przeliczanie stanu finansowego magazynu
            </Typography>
            <Typography color="neutralBlack48" fontSize="16" fontWeight="500">
              Możesz teraz zamknąć to okno i sprawdzić wynik póżniej / za około 10 min
            </Typography>
          </div>
        </div>
        <div className="p-3">
          <Button
            className="text-uppercase"
            onClick={() => {
              close();
              closeParent();
            }}
            size="medium"
            variant="transparent"
          >
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
