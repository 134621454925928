import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReceptions } from "components/miloDesignSystem/atoms/icons/MdiReceptions";
import { ReceptionsRouting } from "routes/finances/ReceptionsRouting";

export const receptions: ModuleLink = {
  url: "receptions",
  label: "Przyjęcia",
  icon: MdiReceptions,
  routing: ReceptionsRouting,
};
