import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useThresholdToggle } from "hooks";
import { PropsWithChildren } from "react";

export const NavigationTitle = ({ children }: PropsWithChildren<{}>) => {
  const { isOpen } = useThresholdToggle();
  if (!isOpen) return null;

  return (
    <Typography
      fontSize="12"
      fontWeight="700"
      color="neutralWhite100"
      className="ml-3 mt-2 text-uppercase"
    >
      {children}
    </Typography>
  );
};
