import { createPaginatedKey } from "api/keys";

export const indexesKeys = {
  indexes: createPaginatedKey("indexes"),
  indexesFulfillment: createPaginatedKey("indexesFulfillment"),
  indexesManufacturers: createPaginatedKey("indexesManufacturers"),
  indexGreatestWeight: () => ["indexGreatestWeight"],
  index: (id: string) => ["index", id],
  indexExist: createPaginatedKey("indexExist"),
  productSetIndexes: createPaginatedKey("productSetIndexes"),
  priceListIndexes: createPaginatedKey("priceListIndexes"),
};
