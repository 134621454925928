import { NormalizedInventoryCheckSummaryItem } from "api/wh-inventory-check/models";
// import { Button } from "components/miloDesignSystem/atoms/button";
// import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useInventoryCheckSummaryColumns = () => {
  // const navigate = useNavigate();

  return useCreateTableColumns<NormalizedInventoryCheckSummaryItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.package.name, {
        header: "nazwa",
        size: 300,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.package.sku, {
        header: "SKU",
        size: 100,
        typographyProps: {
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row.inventoryCheck, {
        id: "inventoryCheck",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            spis z natury
          </Typography>
        ),
        size: 110,
        cell: info => {
          const inventoryCheckRealQuantity = info.getValue();
          return (
            <div className="w-100 d-flex align-items-baseline justify-content-end gap-1">
              <Typography fontSize="14" fontWeight="700">
                {inventoryCheckRealQuantity}
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.inWarehouse, {
        id: "inWarehouse",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            stan magazynowy
          </Typography>
        ),
        size: 110,
        cell: info => {
          const inWarehouse = info.getValue();
          return (
            <div className="w-100 d-flex align-items-baseline justify-content-end gap-1">
              <Typography fontSize="14" fontWeight="400">
                {inWarehouse}
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.difference, {
        id: "difference",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            różnica
          </Typography>
        ),
        size: 110,
        cell: info => {
          const difference = info.getValue();
          return (
            <div className="w-100 d-flex align-items-baseline justify-content-end gap-1">
              <Typography color="warning600" fontSize="14" fontWeight="700">
                {difference}
              </Typography>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      // columnHelper.accessor(row => row.package.internalId, {
      //   id: "packageDetails",
      //   header: " ",
      //   size: 200,
      //   cell: info => {
      //     const internalId = info.getValue();
      //     return (
      //       <Button
      //         className="text-uppercase"
      //         endIcon={MdiArrowForward}
      //         onClick={() => navigate(`/finances/packages/${internalId}`)}
      //         size="small"
      //         variant="transparent"
      //       >
      //         Szczegóły paczki
      //       </Button>
      //     );
      //   },
      // }),
    ];
  });
};
