import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReleases } from "components/miloDesignSystem/atoms/icons/MdiReleases";
import { ReleasesRouting } from "routes/finances/ReleasesRouting";

export const releases: ModuleLink = {
  url: "releases",
  label: "Wydania",
  icon: MdiReleases,
  routing: ReleasesRouting,
};
