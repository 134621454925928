import styled from "@emotion/styled";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Chart, SingleLineChartData } from "components/miloDesignSystem/molecules/chart";
import { CommonError } from "components/utils";
import { dateFns, formatSubUnitsToDecimal } from "utilities";
import styles from "../../Finances.module.css";
import { financialPackagesChartsActions } from "api/wms/financial-packages/charts/actions";

interface Props {
  search?: string;
}

export const WarehouseDetailedStateChart = ({ search }: Props) => {
  const {
    data: expenditures,
    error: expendituresError,
    isLoading: expendituresLoading,
  } = financialPackagesChartsActions.useExpenditures({
    search: search ?? "",
  });

  const {
    data: receptions,
    error: receptionsError,
    isLoading: receptionsLoading,
  } = financialPackagesChartsActions.useReceptions({
    search: search ?? "",
  });

  if (expendituresLoading || receptionsLoading)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <Spinner size={20} />
        </div>
      </div>
    );

  if (expendituresError || receptionsError)
    return (
      <div className="pt-2 pb-3">
        <div className="pt-1 pr-2 pl-3">
          <CommonError status={expendituresError?._httpStatus_ || receptionsError?._httpStatus_} />
        </div>
      </div>
    );

  const receptionsData: SingleLineChartData[] = [
    {
      data:
        receptions
          ?.map(reception => {
            const parsedDate = dateFns.parse(reception.periodId, "yyyy-M", new Date());
            const totalValue = Number(
              formatSubUnitsToDecimal(reception.receptions.totalValue),
            ).toLocaleString("de-DE", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
            const baseValue = Number(
              formatSubUnitsToDecimal(reception.returns.totalValue),
            ).toLocaleString("de-DE", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });

            return {
              xAxisLabel: dateFns.format(parsedDate, "LLL"),
              tooltipBodyRenderer: (
                <div>
                  <Typography
                    fontSize="12"
                    fontWeight="700"
                    color="neutralWhite100"
                    className="text-capitalize"
                  >
                    {dateFns.format(parsedDate, "LLLL")}:
                  </Typography>
                  <div className="d-flex align-items-center gap-1">
                    <StyledLegendBar color="deepPurple400" />
                    <Typography fontSize="12" fontWeight="400" color="neutralWhite100">
                      Przychody:
                    </Typography>
                    <Typography fontSize="12" fontWeight="600" color="neutralWhite100">
                      {totalValue} PLN
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <StyledLegendBar color="neutralBlack4" />
                    <Typography fontSize="12" fontWeight="400" color="neutralWhite100">
                      W tym Zwroty:
                    </Typography>
                    <Typography fontSize="12" fontWeight="600" color="neutralWhite100">
                      {baseValue} PLN
                    </Typography>
                  </div>
                </div>
              ),
              value: Number(formatSubUnitsToDecimal(reception.receptions.totalValue)),
            };
          })
          .reverse() || [],
      config: {
        legendText: "przychody",
      },
    },
  ];

  const expandituresData: SingleLineChartData[] = [
    {
      data:
        expenditures
          ?.map(expenditure => {
            const parsedDate = dateFns.parse(expenditure.periodId, "yyyy-M", new Date());
            const totalValue = Number(
              formatSubUnitsToDecimal(expenditure.expenditures.totalValue),
            ).toLocaleString("de-DE", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });

            return {
              xAxisLabel: dateFns.format(parsedDate, "LLL"),
              tooltipBodyRenderer: (
                <div>
                  <Typography
                    fontSize="12"
                    fontWeight="700"
                    color="neutralWhite100"
                    className="text-capitalize"
                  >
                    {dateFns.format(parsedDate, "LLLL")}:
                  </Typography>
                  <div className="d-flex align-items-center gap-1">
                    <StyledLegendBar color="orange200" />
                    <Typography fontSize="12" fontWeight="400" color="neutralWhite100">
                      Rozchody:
                    </Typography>
                    <Typography fontSize="12" fontWeight="600" color="neutralWhite100">
                      {totalValue} PLN
                    </Typography>
                  </div>
                </div>
              ),
              value: Number(formatSubUnitsToDecimal(expenditure.expenditures.totalValue)),
            };
          })
          .reverse() || [],
      config: {
        legendText: "rozchody",
        color: "orange200",
      },
    },
  ];

  return (
    <div className="d-flex flex-1 gap-1">
      <div className="d-flex flex-column flex-1">
        <div className="d-flex align-items-center gap-2 mb-2">
          <Typography fontSize="14" fontWeight="600">
            Przychody w czasie
          </Typography>
          <Typography fontSize="10" fontWeight="600" color="neutralBlack48">
            (ostatnie 6 mies.)
          </Typography>
        </div>

        <Chart.BarChart data={receptionsData} className={styles.chart} />
      </div>
      <div className="d-flex flex-column flex-1">
        <div className="d-flex align-items-center gap-2 mb-2">
          <Typography fontSize="14" fontWeight="600">
            Rozchody w czasie
          </Typography>
          <Typography fontSize="10" fontWeight="600" color="neutralBlack48">
            (ostatnie 6 mies.)
          </Typography>
        </div>
        <Chart.BarChart data={expandituresData} className={styles.chart} />
      </div>
    </div>
  );
};

const StyledLegendBar = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ color }: { color: ColorPalette }) => colorPalette[color]};
  border-radius: 2px;
`;
