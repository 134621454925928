import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { whEntriesApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { whEntriesKeys } from "./keys";
import { MG } from "utilities/mockGenerator";
import { OrderTypeChoices } from "api/orders/enums";
import { WhEntryStatus, WhEntryExpenditureReason, WhEntryReturnReason } from "./enums";
import {
  InvoiceType,
  TradingDocumentPaymentStatus,
  TradingDocumentType,
} from "api/trading-documents/models";
import { WarehouseIconKind } from "api/wms/models";

const useReceptions = createPaginatedApiQuery(whEntriesApi.getReceptions);
const useReception = createApiQuery(whEntriesApi.getReception);

const useExpenditures = createPaginatedApiQuery(whEntriesApi.getExpenditures, () => ({
  id: MG.number({ min: 1, max: 10000 }),
  signature: MG.signature({ collectionType: "EXPENDITURES" }),
  status: WhEntryStatus.CLOSED,
  warehouse: {
    code: MG.string({ length: 6 }),
    icon: "ROUND" as WarehouseIconKind,
    id: MG.id(),
    name: MG.companyName(),
  },
  totalAmount: MG.number({ min: 10000, max: 1000000 }),
  closed: MG.isoDateTime(),
  created: MG.isoDateTime(),
  numberOfPackages: MG.number({ min: 1, max: 50 }),
  expenditureReason: WhEntryExpenditureReason.LOSS,
  createdBy: MG.userWithInitials(),
}));
const useExpenditure = createApiQuery(whEntriesApi.getExpenditure, () => ({
  id: MG.number({ min: 1, max: 10000 }),
  signature: MG.signature({ collectionType: "EXPENDITURES" }),
  status: WhEntryStatus.CLOSED,
  warehouse: {
    code: MG.string({ length: 6 }),
    icon: "ROUND" as WarehouseIconKind,
    id: MG.id(),
    name: MG.companyName(),
  },
  totalAmount: MG.number({ min: 10000, max: 1000000 }),
  closed: MG.isoDateTime(),
  created: MG.isoDateTime(),
  numberOfPackages: MG.number({ min: 1, max: 50 }),
  expenditureReason: WhEntryExpenditureReason.LOSS,
  createdBy: MG.userWithInitials(),
  closedBy: MG.userWithInitials(),
}));

const usePatchExpenditure = () => {
  return useMutation(whEntriesApi.patchExpenditure, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.expenditures.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.expenditures.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.expenditures.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(
        whEntriesKeys.expenditures.details(id),
        onMutationReturn.prevPanel,
        error,
      );
    },
  }));
};

const useReturns = createPaginatedApiQuery(whEntriesApi.getReturns, () => ({
  id: MG.number({ min: 1, max: 10000 }),
  signature: MG.signature({ collectionType: "RETURNS" }),
  status: WhEntryStatus.CLOSED,
  closed: MG.isoDateTime(),
  created: MG.isoDateTime(),
  receiver: {
    id: MG.uuid(),
    name: MG.companyName(),
  },
  returnReason: WhEntryReturnReason.CANCELATION,
  totalAmount: MG.number({ min: 10000, max: 1000000 }),
  tradingDocument: {
    id: MG.uuid(),
    invoiceType: "CORRECTION" as InvoiceType,
    signature: MG.signature({ collectionType: "CORRECTION" }),
    type: "INVOICE" as TradingDocumentType,
  },
  warehouse: {
    code: MG.string({ length: 6 }),
    icon: "ROUND" as WarehouseIconKind,
    id: MG.id(),
    name: MG.companyName(),
  },
  order: {
    id: MG.id(),
    type: OrderTypeChoices.STANDARD,
    signature: MG.signature({ collectionType: "ORDER" }),
  },
  route: MG.optionalField({
    id: MG.id(),
    signature: MG.signature({ collectionType: "ROUTE" }),
  }),
  shippingService: {
    id: MG.id(),
    name: MG.companyName(),
    provider: MG.companyName(),
  },
}));
const useReturn = createApiQuery(whEntriesApi.getReturn, () => ({
  id: MG.number({ min: 1, max: 10000 }),
  signature: MG.signature({ collectionType: "RETURNS" }),
  status: WhEntryStatus.CLOSED,
  closed: MG.isoDateTime(),
  created: MG.isoDateTime(),
  receiver: {
    id: MG.uuid(),
    name: MG.companyName(),
  },
  returnReason: WhEntryReturnReason.CANCELATION,
  totalAmount: MG.number({ min: 10000, max: 1000000 }),
  warehouse: {
    code: MG.string({ length: 6 }),
    icon: "ROUND" as WarehouseIconKind,
    id: MG.id(),
    name: MG.companyName(),
  },
  order: {
    id: MG.id(),
    type: OrderTypeChoices.STANDARD,
    signature: MG.signature({ collectionType: "ORDER" }),
  },
  route: MG.optionalField({
    id: MG.id(),
    signature: MG.signature({ collectionType: "ROUTE" }),
  }),
  shippingService: {
    id: MG.id(),
    name: MG.companyName(),
    provider: MG.companyName(),
  },
  tradingDocuments: [
    {
      id: MG.uuid(),
      signature: MG.signature({ collectionType: "CORRECTION" }),
      currency: "PLN",
      amountSummary: {
        totalTax: MG.number({ min: 1, max: 10000 }),
        totalWithoutTax: MG.number({ min: 1, max: 10000 }),
        totalWithTax: MG.number({ min: 1, max: 10000 }),
        totalWithTaxInWords: "",
      },
      paymentDeadline: MG.isoDateTime(),
      paymentStatus: "PARTIALLY_PAID" as TradingDocumentPaymentStatus,
      invoiceType: "CORRECTION" as InvoiceType,
      type: "INVOICE" as TradingDocumentType,
    },
  ],
  closedBy: MG.userWithInitials(),
}));

const usePatchReturn = () => {
  return useMutation(whEntriesApi.patchReturn, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.returns.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.returns.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.returns.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(whEntriesKeys.returns.details(id), onMutationReturn.prevPanel, error);
    },
  }));
};

const useReleases = createPaginatedApiQuery(whEntriesApi.getReleases);
const useRelease = createApiQuery(whEntriesApi.getRelease);

const useLineItems = createPaginatedApiQuery(whEntriesApi.getLineItems);

const useCloseDocument = () => {
  return useMutation(whEntriesApi.postConfirmEntry, ({ queryUtils, toastr }) => ({
    ...queryUtils.invalidateOnSuccessAndHandleError,
    onSuccess: () => {
      toastr.open({
        text: "Pomyślnie zamknięto dokument",
        title: "Udało się!",
        type: "success",
      });
      queryUtils.invalidateOnSuccessAndHandleError.onSuccess();
    },
  }));
};

const usePatchReception = () => {
  return useMutation(whEntriesApi.patchReception, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.receptions.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.receptions.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.receptions.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(whEntriesKeys.receptions.details(id), onMutationReturn.prevPanel, error);
    },
  }));
};

export const whEntriesActions = {
  useCloseDocument,
  useExpenditures,
  useExpenditure,
  useReceptions,
  useReception,
  useReleases,
  useRelease,
  useReturns,
  useReturn,
  usePatchExpenditure,
  usePatchReception,
  usePatchReturn,
  useLineItems,
};
