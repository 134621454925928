import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiInventory } from "components/miloDesignSystem/atoms/icons/MdiInventory";
import { InventoryChecksRouting } from "routes/finances/InventoryChecksRouting";

export const inventoryChecks: ModuleLink = {
  url: "inventory-checks",
  label: "Inwentaryzacje",
  icon: MdiInventory,
  routing: InventoryChecksRouting,
};
