import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { Button } from "components/common";
import { ManufacturingPlan } from "api/manufacturingNew/models";
import checkIcon from "assets/images/check_small.svg";
import whiteCheckIcon from "assets/images/checkSmallWhite.svg";
import { useManufacturingPlanOnListPatchMutation } from "api/manufacturingNew/hooks";
import { CheckboxGreen } from "components/utils/checkboxGreen";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Button as MdsButton } from "components/miloDesignSystem/atoms/button/Button";
import { MdiPlanDetails } from "components/miloDesignSystem/atoms/icons/MdiPlanDetails";
import { useNavigate } from "hooks/useNavigate";
import { Menu } from "components/miloDesignSystem/atoms/menu";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { DEFAULT_OPTION_MENU_ICON_SIZE } from "CONSTANTS";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { stageProductionPlansActions } from "api/manufacturing/stage-production-plans/actions";

interface Props {
  close: () => void;
  manufacturingPlan: ManufacturingPlan;
}

export const PanelHeader = ({ close, manufacturingPlan }: Props) => {
  const patchMutation = useManufacturingPlanOnListPatchMutation();
  const deleteMutation = stageProductionPlansActions.useRemoveStageProductionPlan({
    close,
    stageProductionPlan: manufacturingPlan,
  });
  const { isTriggerElementHidden } = useScrollableContext();
  const navigate = useNavigate();

  return (
    <RightPanelHeader>
      <div className="d-flex w-100 align-items-center">
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper className="gap-2 nowrap">
            <CheckboxGreen
              checked={manufacturingPlan.isConfirmed}
              disabled={patchMutation.isLoading}
              name="isRequired"
              onChange={() => {
                patchMutation.mutate({
                  id: manufacturingPlan.id,
                  toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
                });
              }}
            />
            <div>{manufacturingPlan.signature}</div>
          </SlidingHeaderWrapper>
        ) : (
          <Button
            disabled={patchMutation.isLoading}
            kind={manufacturingPlan.isConfirmed ? "tealGreen" : "confirm"}
            onClick={() => {
              patchMutation.mutate({
                id: manufacturingPlan.id,
                toUpdate: { isConfirmed: !manufacturingPlan.isConfirmed },
              });
            }}
            size="btnSizeSmall"
          >
            <div className="btnBase btnBaseSmall">
              {!manufacturingPlan.isConfirmed ? (
                <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
              ) : (
                <img alt="Gotowe" src={whiteCheckIcon} style={{ height: "16px", width: "16px" }} />
              )}
              <div className="fs-10">
                {!manufacturingPlan.isConfirmed ? "Zatwierdź" : "Zatwierdzono"}
              </div>
            </div>
          </Button>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <MdsButton
            className="text-uppercase"
            onClick={() => navigate(`/production-plans/details/${manufacturingPlan.id}`)}
            size="small"
            startIcon={MdiPlanDetails}
            variant="gray"
          >
            Szczegóły planu
          </MdsButton>
          <Menu
            disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
            menuItems={[
              {
                type: MenuItemType.ICON,
                icon: deleteMutation.isLoading ? (
                  <Spinner size={DEFAULT_OPTION_MENU_ICON_SIZE} />
                ) : (
                  <MdiDelete color="danger600" size={String(DEFAULT_OPTION_MENU_ICON_SIZE)} />
                ),
                onClick: () => deleteMutation.mutate(manufacturingPlan.id),
                options: {
                  color: "danger600",
                  disabled: deleteMutation.isLoading,
                },
                text: "Usuń",
              },
            ]}
          />
          <span className="divider line-divider" />
          <Tooltip title="Zamknij panel">
            <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
          </Tooltip>
        </div>
      </div>
    </RightPanelHeader>
  );
};
