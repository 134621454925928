export enum InventoryCheckTab {
  ALL = "all",
  CLOSED = "closed",
  IN_PROGRESS = "in-progress",
}

const inventoryChecksTabDict: Record<InventoryCheckTab, string> = {
  all: "Wszystkie",
  "in-progress": "W trakcie",
  closed: "Zamknięte",
};

export const inventoryChecksConstants = {
  inventoryChecksTabDict,
};
