import { ReceptionDetails } from "api/wh-entries/models";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useTradingDocumentsColumns = () => {
  return useCreateTableColumns<ReceptionDetails["tradingDocuments"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.signature, {
          header: "numer",
          size: 200,
          typographyProps: {
            fontWeight: "600",
          },
        }),
        columnHelper.date(row => row.invoiceIssueDate, {
          header: "data wystawienia",
          size: 100,
          typographyProps: {
            fontSize: "12",
            fontWeight: "400",
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
