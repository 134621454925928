import { Product } from "api/products/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  product: Product;
}

export const TitleSection = ({ product }: Props) => {
  const context = useScrollableContext();

  return (
    <RightPanelSection padding="px-3 py-0">
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {product.name}
        </Typography>
      </div>
    </RightPanelSection>
  );
};
