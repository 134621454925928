import { filterFactory } from "components/utils/withFilters";

export const filtersList = filterFactory(({ FilterType }) => {
  return [
    {
      type: FilterType.Date,
      label: "planowany przyjazd",
      name: "estimatedDateOfArrivalDate",
    },
  ];
});
