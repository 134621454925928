import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiFinance = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      d="M11.0252 21V18.85C10.1419 18.65 9.37936 18.2667 8.7377 17.7C8.09603 17.1333 7.6252 16.3333 7.3252 15.3L9.1752 14.55C9.4252 15.35 9.79603 15.9583 10.2877 16.375C10.7794 16.7917 11.4252 17 12.2252 17C12.9085 17 13.4877 16.8458 13.9627 16.5375C14.4377 16.2292 14.6752 15.75 14.6752 15.1C14.6752 14.5167 14.4919 14.0542 14.1252 13.7125C13.7585 13.3708 12.9085 12.9833 11.5752 12.55C10.1419 12.1 9.15853 11.5625 8.6252 10.9375C8.09186 10.3125 7.8252 9.55 7.8252 8.65C7.8252 7.56667 8.1752 6.725 8.8752 6.125C9.5752 5.525 10.2919 5.18333 11.0252 5.1V3H13.0252V5.1C13.8585 5.23333 14.546 5.5375 15.0877 6.0125C15.6294 6.4875 16.0252 7.06667 16.2752 7.75L14.4252 8.55C14.2252 8.01667 13.9419 7.61667 13.5752 7.35C13.2085 7.08333 12.7085 6.95 12.0752 6.95C11.3419 6.95 10.7835 7.1125 10.4002 7.4375C10.0169 7.7625 9.8252 8.16667 9.8252 8.65C9.8252 9.2 10.0752 9.63333 10.5752 9.95C11.0752 10.2667 11.9419 10.6 13.1752 10.95C14.3252 11.2833 15.196 11.8125 15.7877 12.5375C16.3794 13.2625 16.6752 14.1 16.6752 15.05C16.6752 16.2333 16.3252 17.1333 15.6252 17.75C14.9252 18.3667 14.0585 18.75 13.0252 18.9V21H11.0252Z"
      fillOpacity="0.88"
    />
  </SvgWrapper>
);
