import apiFetch, { tokenRefreshMiddleware } from "../../apiConnectors/fetchConnector";
import { ProductEntity } from "../products/models";
import {
  OfferLight,
  ProductSet,
  ProductSetElement,
  ProductSetListItem,
  ProductSetProductIndex,
} from "./models";
import { Pagination } from "../types";
import { productSetsKeys } from "api/keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";

const api = tokenRefreshMiddleware(apiFetch);

export const getProductSets = (search: string = "", abortToken?: string) =>
  api<Pagination<ProductSetListItem>>({
    method: "GET",
    url: "/product-sets/items" + search,
    abortToken,
  });

export const getProductSetsQuery = (search: string = "") => ({
  key: productSetsKeys.productSets(search),
  fetcher: () =>
    queryFetch<Pagination<ProductSetListItem>>({
      method: "GET",
      url: "/product-sets/items" + search,
    }),
});

export const getProductSet = (id: number | string): ApiFetcher<ProductSet> => ({
  key: productSetsKeys.productSet(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/product-sets/items/" + id,
    }),
});

export const deleteProductSet = (id: number | string) =>
  queryFetch<ProductSet>({
    method: "DELETE",
    url: "/product-sets/items/" + id,
  });

export const postProductSet = () =>
  queryFetch<ProductSet>({
    method: "POST",
    url: "/product-sets/items",
  });

export const patchProductSet = (id: string | number, data: Partial<ProductSet>) =>
  queryFetch<ProductSet>({
    method: "PATCH",
    url: "/product-sets/items/" + id,
    data,
  });

export const postProductSetCopy = (data: { productSet: number | string }) =>
  queryFetch<{ id: number; name: string }>({
    method: "POST",
    url: `/product-sets/items/${data.productSet}/copy`,
  });

export const getProductSetsElements = (search: string = "") => ({
  key: productSetsKeys.productSetElements(search),
  fetcher: () =>
    queryFetch<Pagination<ProductSetElement>>({
      method: "GET",
      url: "/product-sets/elements" + search,
    }),
});

export const patchProductSetElement = (id: number, data: Partial<ProductSetElement>) =>
  queryFetch<ProductSet>({
    method: "PATCH",
    url: "/product-sets/elements/" + id,
    data,
  });

export const deleteProductSetElement = (id: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/product-sets/elements/" + id,
  });

export const postProductSetElement = (data: {
  name: string;
  productSet: number | string;
  product: number;
}) =>
  queryFetch<ProductSetElement>({
    method: "POST",
    url: "/product-sets/elements",
    data,
  });

export const postProductSetElementQuery = (data: {
  productSet: number | string;
  products: number[];
}) =>
  queryFetch<ProductSetElement[]>({
    method: "POST",
    url: "/product-sets/elements",
    data,
  });

export const getProductSetElements = (
  search: string = "",
): ApiFetcher<Pagination<ProductSetProductIndex>> => ({
  key: productSetsKeys.productSetIndexes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/product-sets/indexes` + search,
    }),
});

export const postProductSetProductIndexes = (
  productSet: number | string,
  data: { indexes: number[] },
) =>
  queryFetch<ProductSetProductIndex>({
    method: "POST",
    url: `/product-sets/items/${productSet}/indexes`,
    data,
  });

export const deleteProductSetProductIndexes = (productSet: number | string, index: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: `/product-sets/items/${productSet}/indexes/${index}`,
  });

export const getCustomerOffers = (search: string = "", abortToken?: string) =>
  api<Pagination<OfferLight>>({
    method: "GET",
    url: "/product-sets/elements-b2b" + search,
    abortToken,
  });

export const getCustomerOffer = (hackId: string | number) => {
  if (typeof hackId === "number") {
    return api<ProductEntity>({
      method: "GET",
      url: `/product-sets/elements-customer/${hackId}`,
    });
  } else {
    // createPrimitiveHook accepts only string | number | null so we need to hack two ids into a string
    const [customer, product] = hackId.split("-");
    return api<ProductEntity>({
      method: "GET",
      url: `/product-sets/elements-customer/${customer}/${product}`,
    });
  }
};

export const getCustomerOfferB2b = (hackId: string | number) => {
  if (typeof hackId === "number") {
    return api<ProductEntity>({
      method: "GET",
      url: `/product-sets/elements-b2b/${hackId}`,
    });
  } else {
    // createPrimitiveHook accepts only string | number | null so we need to hack two ids into a string
    const [customer, product] = hackId.split("-");
    return api<ProductEntity>({
      method: "GET",
      url: `/product-sets/elements-b2b/${customer}/${product}`,
    });
  }
};
