import { financialPackagesChartsApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";

const useReceptions = createApiQuery(financialPackagesChartsApi.getReceptions);

const useExpenditures = createApiQuery(financialPackagesChartsApi.getExpenditures);

export const financialPackagesChartsActions = {
  useReceptions,
  useExpenditures,
};
