import { ReleaseDetails } from "api/wh-entries/models";
import { RightPanelSection } from "components/utils/drawer";
import { generalInfoSectionFactory } from "pages/finances/shared/components/whEntryRightPanel/generalInfoSectionFactory/generalInfoSectionFactory";

interface Props {
  release: ReleaseDetails;
}

export const GeneralInfoSection = ({ release }: Props) => {
  const generalInfoFactory = generalInfoSectionFactory(release);

  return (
    <RightPanelSection>
      {generalInfoFactory.date({ date: release.created, title: "data wydania" })}
      {generalInfoFactory.warehouse()}
      {/* {generalInfoFactory.receiver({ receiver: release.receiver })} */}
      {generalInfoFactory.closedBy()}
      {generalInfoFactory.date({ date: release.closed, title: "data zamknięcia" })}
      {generalInfoFactory.route({ route: release.source })}
      {generalInfoFactory.order({ order: release.order })}
      {generalInfoFactory.totalAmount({
        title: "łączna wartość wydania",
        totalAmount: release.totalAmount,
      })}
    </RightPanelSection>
  );
};
