import { SvgWrapper } from "./SvgWrapper";
import { BaseIcon } from "./types";

export const MdiAddVariant = (props: BaseIcon) => (
  <SvgWrapper {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 14V21H7V18H12.3414C12.1203 17.3744 12 16.7013 12 16H7V14H12.3414C12.6048 13.2548 13.0113 12.5771 13.5278 12H10V10H14V11.5278C14.5771 11.0113 15.2548 10.6048 16 10.3414V10H17C17.55 10 18.0208 9.80417 18.4125 9.4125C18.8042 9.02083 19 8.55 19 8V5C19 4.45 18.8042 3.97917 18.4125 3.5875C18.0208 3.19583 17.55 3 17 3H7C6.45 3 5.97917 3.19583 5.5875 3.5875C5.19583 3.97917 5 4.45 5 5V8C5 8.55 5.19583 9.02083 5.5875 9.4125C5.97917 9.80417 6.45 10 7 10H8V12H7C6.45 12 5.97917 12.1958 5.5875 12.5875C5.19583 12.9792 5 13.45 5 14ZM17 8H7V5H17V8Z"
    />
    <path d="M17 20V17H14V15H17V12H19V15H22V17H19V20H17Z" />
  </SvgWrapper>
);
