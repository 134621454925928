import { queryFetch } from "apiConnectors/queryFetch";
import {
  AddManufacturerStagePayload,
  ManufacturerStage,
  ManufacturingStage,
  PostManufacturingSchema,
  PostManufacturingStage,
} from "./models";
import {
  ManufacturingSchema,
  ModifyStageSchemaAssignment,
  StageSchemaAssignment,
} from "api/manufacturingNew/models";
import { Pagination, UUID } from "api/types";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import { ApiFetcher } from "hooks/createApiQuery";
import { manufacturingSchemasKeys } from "./keys";

const postManufacturingStage = (data: PostManufacturingStage) =>
  queryFetch<ManufacturingStage>({
    method: "POST",
    url: "/manufacturing/schemas/stages",
    data,
  });

const postManufacturingSchema = (data: PostManufacturingSchema) =>
  queryFetch<ManufacturingSchema>({
    method: "POST",
    url: "/manufacturing/schemas/entries",
    data,
  });

const patchManufacturingSchemaStages = (
  data: Assign<Partial<ModifyStageSchemaAssignment>, { id: number }>,
) =>
  queryFetch<StageSchemaAssignment>({
    method: "PATCH",
    url: "/manufacturing/schemas/schema-and-stages/" + data.id,
    data: parsePatchData(data),
  });

const patchManufacturingStage = (data: Assign<Partial<ManufacturingStage>, { id: UUID }>) =>
  queryFetch<ManufacturingStage>({
    method: "PATCH",
    url: "/manufacturing/schemas/stages/" + data.id,
    data: parsePatchData(data),
  });

const getManufacturerStages = (search: string = ""): ApiFetcher<Pagination<ManufacturerStage>> => ({
  key: manufacturingSchemasKeys.manufacturerStage.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/manufacturer-stage" + search,
    }),
});

const postManufacturerStage = (data: AddManufacturerStagePayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/schemas/manufacturer-stage",
    data,
  });

const deleteManufacturerStage = (relationId: number) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/schemas/manufacturer-stage/" + relationId,
  });

const deleteManufacturingSchema = (schemaID: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/schemas/entries/" + schemaID,
  });

export const manufacturingSchemaApi = {
  deleteManufacturingSchema,
  deleteManufacturerStage,
  getManufacturerStages,
  postManufacturerStage,
  patchManufacturingSchemaStages,
  patchManufacturingStage,
  postManufacturingSchema,
  postManufacturingStage,
};
