import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Filters } from "../InventoryCheckSummary";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useState } from "react";
import { MdiRadioButtonChecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonChecked";
import { MdiRadioButtonUnchecked } from "components/miloDesignSystem/atoms/icons/MdiRadioButtonUnchecked";
import styles from "../../InventoryChecks.module.css";
import { cx } from "utilities";

interface Props {
  filters: Filters;
  setFilter: <T extends keyof Filters, U extends Filters[T]>(name: T, value: U) => void;
}

type DifferenceType = "LESS" | "GREATER";

export const FiltersBar = ({ filters, setFilter }: Props) => {
  const [differenceType, setDifferenceType] = useState<DifferenceType | null>(null);
  const resetPage = () => setFilter("page", 1);

  return (
    <div className="pt-3 px-3 d-flex align-items-center gap-3 w-100">
      <TextField
        containerClassName="w-30"
        endIcon={<MdiSearch color="neutralBlack48" size="18" />}
        onChange={event => {
          setFilter("search", event.target.value);
          resetPage();
        }}
        placeholder="Szukaj..."
        value={filters.search ?? ""}
      />
      <div className={cx("d-flex align-items-center gap-2", styles.filtersBar)}>
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Pokaż z różnicą:
        </Typography>
        <div className="d-flex align-items-center gap-1 pt-1">
          <div
            className="cursor-pointer"
            onClick={() => {
              if (differenceType === "GREATER") {
                setDifferenceType(null);
                setFilter("differenceGreaterThan", "0");
                return;
              }
              return setDifferenceType("GREATER");
            }}
          >
            <Tag
              label="większą"
              startIcon={
                differenceType === "GREATER" ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
              }
              type={differenceType === "GREATER" ? "filled" : "outlined"}
              variant={differenceType === "GREATER" ? "deepPurple50" : "quaternary"}
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (differenceType === "LESS") {
                setDifferenceType(null);
                setFilter("differenceLessThan", "0");
                return;
              }
              return setDifferenceType("LESS");
            }}
          >
            <Tag
              label="mniejszą"
              startIcon={
                differenceType === "LESS" ? MdiRadioButtonChecked : MdiRadioButtonUnchecked
              }
              type={differenceType === "LESS" ? "filled" : "outlined"}
              variant={differenceType === "LESS" ? "deepPurple50" : "quaternary"}
            />
          </div>
        </div>
        <Typography
          className="text-uppercase"
          color="neutralBlack48"
          fontSize="10"
          fontWeight="700"
        >
          Niż:
        </Typography>
        <TextField
          disabled={!differenceType}
          placeholder="0"
          size="small"
          onChange={event => {
            if (differenceType === "GREATER")
              setFilter("differenceGreaterThan", event.target.value);
            if (differenceType === "LESS") setFilter("differenceLessThan", event.target.value);
            resetPage();
          }}
          value={
            differenceType === "GREATER"
              ? filters.differenceGreaterThan
              : differenceType === "LESS"
              ? filters.differenceLessThan
              : ""
          }
        />
      </div>
    </div>
  );
};
