import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import styles from "../shared/Finances.module.css";
import { useParams } from "react-router";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { WarehouseStateChartContainer } from "../shared/components/warehouseStateChart/WarehouseStateChart";
import { WarehouseDetailedStateChart } from "../shared/components/warehouseDetailedStateChart/WarehouseDetailedStateChart";
import { useFilters } from "hooks/useFilters";
import { ReleasesSummary } from "../shared/components/whEntriesSummaries/releasesSummary/ReleasesSummary";
import { ExpendituresSummary } from "../shared/components/whEntriesSummaries/expendituresSummary/ExpendituresSummary";
import { ReturnsSummary } from "../shared/components/whEntriesSummaries/returnsSummary/ReturnsSummary";
import { ReceptionsSummary } from "../shared/components/whEntriesSummaries/receptionsSummary/ReceptionsSummary";
import { WarehouseStateTab } from "../shared/constants/financesConstants";
import {
  FinancialWarehouseStateSummaryFilters,
  WarehousesFinancialStateSummary,
} from "./components/warehousesFinancialStateSummary/WarehousesFinancialStateSummary";
import { WarehouseCurrentState } from "../shared/components/warehouseCurrentState/WarehouseCurrentState";
import { WarehouseFinancialStateDetails } from "../shared/components/warehouseFinancialStateDetails/WarehouseFinancialStateDetails";

export const WarehouseFinancialState = () => {
  const { tab } = useParams<{ tab: WarehouseStateTab }>();
  const { filters, setFilter } = useFilters<FinancialWarehouseStateSummaryFilters>({
    page: 1,
    pageSize: 6,
  });

  return (
    <PageWrapper>
      <PageHeader
        bigTitle="Finansowy stan magazynu"
        searchBar={false}
        viewLabel="FINANCIAL_WAREHOUSE_STATE"
      />
      <div className="d-flex flex-column overflow-auto">
        <div className="py-2 px-3">
          <div className={styles.financialWarehouseStatePage}>
            <div className="pt-3 pb-2 px-3">
              <Typography fontSize="26" fontWeight="600">
                Wartość magazynu
              </Typography>
            </div>

            <div className="d-flex flex-column gap-4">
              <div className="d-flex gap-3">
                <div className="d-flex flex-1 flex-column w-50">
                  <WarehouseCurrentState tab={tab} />
                  <WarehousesFinancialStateSummary
                    tab={tab}
                    filters={filters}
                    setFilter={setFilter}
                  />
                </div>

                <div className="d-flex flex-1 w-50">
                  <WarehouseStateChartContainer tab={tab} filters={filters} />
                </div>
              </div>

              <div className="d-flex flex-1 gap-3">
                <div className="d-flex flex-1 w-50">
                  <WarehouseFinancialStateDetails tab={tab} />
                </div>

                <div className="d-flex flex-1 w-50">
                  <WarehouseDetailedStateChart />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-2 px-3 gap-2">
          <ReceptionsSummary tab={tab} />
          <ReturnsSummary tab={tab} />
          <ReleasesSummary tab={tab} />
          <ExpendituresSummary tab={tab} />
        </div>
      </div>
    </PageWrapper>
  );
};
