import { AttributesState } from "../ProductForm";

export const getProductIndexBasedOnAttributesState = (
  indexes: Record<string, number> | null,
  attributesState: AttributesState,
) => {
  const attributesValues = Object.values(attributesState).map(attr => attr.valueId);
  const allSelected = attributesValues.length > 0 && attributesValues.every(val => Boolean(val));
  if (allSelected) {
    const values = [...(attributesValues as number[])].sort((a, b) => (a > b ? 1 : -1));
    const index = values.join("-");
    if (indexes) {
      return indexes[index] || null;
    }
  }

  return null;
};
