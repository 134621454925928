import { UUID } from "api/types";
import { whEntriesActions } from "api/wh-entries/actions";
import { WhEntryStatus } from "api/wh-entries/enums";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

interface Props {
  id: UUID | number;
  status: WhEntryStatus;
}

export const DocumentClosure = ({ id, status }: Props) => {
  const closeDocumentMutation = whEntriesActions.useCloseDocument();

  if (status === WhEntryStatus.CLOSED)
    return (
      <Button
        className="text-uppercase"
        disabled
        size="small"
        startIcon={MdiCheck}
        variant="success"
      >
        Zamknięto dokument
      </Button>
    );
  return (
    <Button
      className="text-uppercase"
      isLoading={closeDocumentMutation.isLoading}
      onClick={() => closeDocumentMutation.mutate(id)}
      size="small"
      startIcon={MdiCheck}
      variant="outline"
    >
      Zamknij dokument
    </Button>
  );
};
