import { buildBankAccountLink } from "api/bank-accounts/calls";
import { useBanks } from "api/bank-accounts/hooks";
import { Bank } from "api/bank-accounts/models";
import { Header } from "components/common";
import { Button } from "components/common/buttonLegacy";
import { SearchInput } from "components/common/searchInput";
import { useMutation } from "hooks/useMutation";
import { useState } from "react";
import styles from "./Create.module.css";
import cx from "classnames";
import { CommonError, Spinner } from "components/utils";
import { useFilters } from "hooks/useFilters";
import { CountryCode, countryCodes } from "CONSTANTS";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

interface Filters {
  countryCode: CountryCode;
}

export const RedirectLink = () => {
  const { filters, searchParams, setFilter } = useFilters<Filters>({
    countryCode: "PL",
  });
  const { data, error, isLoading } = useBanks(searchParams);
  const [selectedBank, setSelectedBank] = useState<null | Bank>(null);
  const [search, setSearch] = useState("");

  const addBankMutation = useMutation(buildBankAccountLink, {
    onSuccess: payload => {
      window.location.href = payload.redirectTo;
    },
  });

  const countriesOptions = countryCodes.map(code => ({
    icon: code.icon,
    text: code.name,
    type: MenuItemType.ICON,
    value: code.value,
  }));

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (!data || isLoading) {
    return (
      <div className="h-100 d-flex flex-column">
        <Header title="Dodaj konto bankowe" />
        <div
          className={cx(styles.section, "h-100 d-flex align-items-center justify-content-center")}
        >
          <Spinner color="blue" size="big" text="trwa wczytywanie" />
        </div>
      </div>
    );
  }

  const filteredBanks = data.filter(bank => bank.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="h-100 d-flex flex-column">
      <Header title="Dodaj konto bankowe" />
      <div className={styles.section}>
        <SearchInput className="w-50" value={search} onChange={value => setSearch(value)} />
        <Select
          items={countriesOptions}
          label="Kraj"
          className="w-50 mb-3"
          onChange={countryCode => setFilter("countryCode", countryCode as CountryCode)}
          selected={filters.countryCode}
          textFieldProps={{
            size: "default",
          }}
        />
        <div className={styles.container}>
          {filteredBanks.map(bank => (
            <div className={styles.bank} onClick={() => setSelectedBank(bank)} key={bank.id}>
              <div
                className={cx(styles.radio, {
                  [styles["radio-selected"]]: selectedBank?.id === bank.id,
                })}
              />
              <img src={bank.logo} className={styles.logo} alt={`${bank.name} logo`} />
              {bank.name}
            </div>
          ))}
        </div>
        <Button
          kind="primary"
          disabled={addBankMutation.isLoading || !selectedBank}
          onClick={() => {
            if (!selectedBank) return;
            const { id, name, logo } = selectedBank;
            addBankMutation.mutate({
              id,
              name,
              logo,
              redirectUri: `${window.location.origin}/bank-accounts/create/step-three`,
            });
          }}
        >
          Utwórz konto
        </Button>
      </div>
    </div>
  );
};
