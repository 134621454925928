import { Route } from "components/utils";
import { SingleWarehouseFinancialState } from "pages/finances/singleWarehouseFinancialState/SingleWarehouseFinancialState";
import { NotFound } from "pages/notFound";
import { RouteComponentProps, Switch } from "react-router-dom";

export const SingleWarehouseFinancialStateRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:id`}
        title="Milo - stan magazynu"
        component={SingleWarehouseFinancialState}
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
