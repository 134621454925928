import { ColumnDef, createColumnHelper } from "@tanstack/table-core";
import { TABLE_EXPANDABLE_ID } from "components/miloDesignSystem/molecules/table/constants";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";

export const expandableColumn = <T,>(): ColumnDef<T> => {
  const baseColumnHelper = createColumnHelper<T>();

  return baseColumnHelper.display({
    header: "",
    id: TABLE_EXPANDABLE_ID,
    size: 30,
    cell: info => {
      const isNested = info.row.depth;
      if (isNested || !info.row.subRows.length) {
        return null;
      }
      return (
        <div className="d-flex align-items-center justify-content-end flex-1 tableExpandableColumn">
          <IconButton
            icon={info.row.getIsExpanded() ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
            onClick={info.row.getToggleExpandedHandler()}
            variant="transparent"
          />
        </div>
      );
    },
  });
};
