import { queryFetch } from "apiConnectors/queryFetch";
import { inventoryCheckKeys } from "./keys";
import { Pagination } from "api/types";
import { InventoryCheckItem, InventoryCheckSummaryItem } from "./models";
import { Assign } from "utility-types";

const getInventoryChecks = (search: string = "") => ({
  key: inventoryCheckKeys.inventoryChecks(search),
  fetcher: () =>
    queryFetch<Pagination<InventoryCheckItem>>({
      method: "GET",
      url: "/wh-inventory-checks/items" + search,
    }),
});

const getInventoryCheck = (id: number) => ({
  key: inventoryCheckKeys.inventoryCheck(id),
  fetcher: () =>
    queryFetch<InventoryCheckItem>({
      method: "GET",
      url: "/wh-inventory-checks/items/" + id,
    }),
});

const postInventoryCheck = () =>
  queryFetch<InventoryCheckItem>({
    method: "POST",
    url: "/wh-inventory-checks/items",
  });

const getInventoryCheckSummary = (search: string = "") => ({
  key: inventoryCheckKeys.inventoryCheckSummary(search),
  fetcher: () =>
    queryFetch<Assign<Pagination<InventoryCheckSummaryItem>, { isActive: boolean }>>({
      method: "GET",
      url: "/wh-inventory-checks/summary" + search,
    }),
});

export const inventoryChecksApi = {
  getInventoryChecks,
  getInventoryCheck,
  getInventoryCheckSummary,
  postInventoryCheck,
};
